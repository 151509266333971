import React, { createContext, useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { UserContext } from '../realm/user.context';
import { fetchData } from '../screens/StudentData/FetchData';
import logger from '../utils/logger';

export const StudentDataContext = createContext();

export const StudentDataProvider = ({ children, user }) => {
  const { realmUser } = useContext(UserContext);
  const [isUpdating, setIsUpdating] = useState(false);
  const [state, setState] = useState({
    chosenSchool: "Choose School",
    chosenDistrict: "Choose District",
    chosenClass: "Choose Class",
    chosenStudent: "Choose Student",
    searchData: "",
    districts: {},
    chosenTime: "month",
    chosenTimeCompare: "",
    allWellnessPoints: [],
    counter: 0,
    studentData: {},
    threeImpactfulActions: {},
    filterAmountCurrent: moment.duration(30, "days"),
    filterAmountCompare: moment.duration(),
    feeling: "select",
    wellnessList: [],
    loading: true,
    counts: [
      { name: "sad", current: 0, compare: 0 },
      { name: "tired", current: 0, compare: 0 },
      { name: "lonely", current: 0, compare: 0 },
      { name: "happy", current: 0, compare: 0 },
      { name: "grateful", current: 0, compare: 0 },
      { name: "calm", current: 0, compare: 0 },
      { name: "stressed", current: 0, compare: 0 },
      { name: "scared", current: 0, compare: 0 },
      { name: "angry", current: 0, compare: 0 },
    ],
    allStudents: [],
    activeComponent: 0,
    studentEmailToNameMap: {},
    filteredDataPoints: [],
    chosenEmotion: "all emotions",
    chosenColor: "#C8FADD",
    studentEmotionData: {},
    chosenPercentage: "",
    bigFiveFilteredData: [],
    selectedTimes: {
      "All Day": true,
      "Early Morning": false,
      Morning: false,
      Afternoon: false,
      Evening: false,
    },
    selectedSubOptions: {},
    helpfulFilter: "All",
    wellnessPopupOpen: false,
  });

  const updateState = (updatedState) => {
    setState((prevState) => ({
      ...prevState,
      ...updatedState,
    }));
  };

  const loadStudentData = async (user, anonamizeData = false) => {
    updateState({ loading: true });
    try {
      const data = await fetchData(state, realmUser, user, anonamizeData);
      updateState(data);
    } catch (error) {
      logger.error("Error fetching data:", error);
      updateState({ loading: false, error: "Failed to fetch data" });
    }
  };

  // Load data when the provider mounts and user is available
  useEffect(() => {
    if (user) {
      loadStudentData(user);
    }
  }, [user, realmUser]);

  return (
    <StudentDataContext.Provider value={{ state, updateState, loadStudentData, isUpdating }}>
      {children}
    </StudentDataContext.Provider>
  );
}; 