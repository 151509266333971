import { COLLECTIONS, validateItemsForCollection } from "../types/utils";
import logger from "../utils/logger";
/*
    This function performs an update for one or many records in a 
    collection.

    Args: 
        -- realmUser:    Authenticated user instance that allows us to perform updates.
        -- collection:   The name of the collection you'd like to run an update query
                         on.
        -- filters:      This is a object containing mongoDB operator(s) that help filter
                         out the records that need to be updated. If no filter is provided,
                         we will udpate all records by default. For more information:
                         https://www.mongodb.com/docs/manual/reference/operator/query/#query-selectors
        -- itemToUpdate: An object containing all of the fields that you'd like to update
                         on the filtered records. If any fields to be updated are not in 
                         the schema, this function performs a no-op.
                         
    Returns: 
        -- Nothing
        -- TODO: could be cool to extend this. before we return we can run a `find` query
                 using the `filters` variable that returns the actual records we updated.
*/
export const batchUpdate = async (
  realmUser,
  collection,
  filters,
  itemToUpdate,
  unset = false
) => {
  if (!collection || !(collection in COLLECTIONS)) {
    logger.error(
      `Collection '${collection}' doesn't exist in the database. ` +
        "Please choose a valid collection to run your query on."
    );
    return null;
  }

  // Validate items before we insert them into the collection
  const validItems = validateItemsForCollection(collection, [itemToUpdate]);

  if (validItems.length === 0) {
    return validItems;
  }

  // If the user doesn't provide an operator or collector for the search stage
  // of the aggregation pipeline, return all records by default.
  let operation;
  if (filters === null) {
    operation = {
      exists: {
        path: "_id",
      },
    };
  } else {
    // TODO: perform some check on the filters to make sure they're valid?
    operation = filters;
  }

  // Format the itemToUpdate correctly. This would make much more sense
  // with TypeScript.
  let updateObject;
  if ("$set" in itemToUpdate || "$unset" in itemToUpdate) {
    // If user passes in $set / $unset in the update item itself,
    // don't format their update query for them.
    updateObject = itemToUpdate;
  } else if (unset) {
    updateObject = { $unset: itemToUpdate };
  } else {
    updateObject = { $set: itemToUpdate };
  }

  // Grab DB refrence
  const dbRef = realmUser.mongoClient("mongodb-atlas").db("123wellness");
  const collectionRef = dbRef.collection(collection);

  return await collectionRef.updateMany(filters, updateObject, {
    // TODO: let the user set this as an arg? probably unnecessary
    upsert: false,
  });
};
