import React, { useState, useEffect, useMemo } from "react";
import "./EmotionsLog.css";
import { NoData } from "./AlertComponents";
import PracticeCircle from "../../../components/PracticeCircle";
import { categorizedPractices, backgroundColors } from "../utils/practicesConfig";

const EmotionsLogDisplay = ({
  studentEmotionData,
  chosenColor,
  studentEmailToNameMap,
  adultActionImages
}) => {
  const isMobile = window.innerWidth <= 600;
  const circleSize = isMobile ? '100px' : '120px';
  const fontSize = isMobile ? '0.9rem' : '1rem'

  const [hoveredItem, setHoveredItem] = useState(null);
  const [displayedData, setDisplayedData] = useState([]);
  const [dataIndex, setDataIndex] = useState(50);
  const getTopActions = (studentEmail) => {
    const studentEntry = studentEmotionData.find(
      (student) => student.name === studentEmail
    );
  
    if (!studentEntry || !studentEntry.actions) return [];
  
    const actionCounts = studentEntry.actions;
  
    return Object.entries(actionCounts)
      .sort(([, a], [, b]) => b - a)
      .slice(0, 3);
  };
  
  const sortedStudentData = studentEmotionData.sort((a, b) => {
    if (b.count === a.count) {
      return b.percent - a.percent;
    }
    return b.count - a.count;
  });
  
  useEffect(() => {
    setDisplayedData(sortedStudentData.slice(0, 50));
    setDataIndex(50);
  }, [sortedStudentData]);
  const loadMoreData = () => {
    const newIndex = dataIndex + 50;
    setDisplayedData(sortedStudentData.slice(0, newIndex));
    setDataIndex(newIndex);
  };
  if (sortedStudentData.length === 0) {
    return (
      <NoData/>
    )
  }

  var imageSettingFilenameModifier = null
  if (adultActionImages){
      imageSettingFilenameModifier = 'AdultCircle_BIG_FONT'
  } else {
      imageSettingFilenameModifier = 'TeenCircle_BIG_FONT'
  }
  const getBackgroundColor = (action) => {
    if (action.toLowerCase().startsWith("other")) {
      return backgroundColors.green;
    }

    for (const [color, practices] of Object.entries(categorizedPractices)) {
      if (practices.includes(action)) {
        return backgroundColors[color];
      }
    }
    return null;
  };

  return (
    <div
      className="emotions-log-display"
      style={{ backgroundColor: chosenColor }}
    >
      <div className="student-grid">
        {displayedData.map((student, index) => {
          let name = studentEmailToNameMap[student.name] ? studentEmailToNameMap[student.name] : student.name;
          const topActions = getTopActions(student.name);
          return (
            <div key={index} className="student-item">
              <div className="student-name">{name}</div>
              <div className="student-data">
                {student.count} {student.count === 1 ? 'time' : 'times'} (
                {student.percent.toFixed(2)}%)
              </div>
              <div className="student-top-actions">
                {topActions.length > 0 ? (
                  <div className="top-actions-container">
                    {topActions.map(([action, count]) => {
                      const isOther = action.toLowerCase().startsWith("other");
                      const imageSrc = isOther
                        ? "../images/actions/Other_BIG_FONT.png"
                        : `../images/actions/${action} ${imageSettingFilenameModifier}.png`;

                      return (
                        <PracticeCircle
                          key={action}
                          imageSrc={imageSrc}
                          size={circleSize}
                          backgroundColor={getBackgroundColor(action)}
                          fontSize={fontSize}
                          text={isOther ? "Other:" : action}
                          countText={`${count} ${count > 1 ? "times" : "time"}`}
                          hoverText={action}
                          onHover={() => setHoveredItem(action)}
                          onLeave={() => setHoveredItem(null)}
                        />
                      );
                    })}
                  </div>
                ) : (
                  <div>No helpful actions yet</div>
                )}
              </div>
              
            </div>
          );
        })}
      </div>
      {displayedData.length < sortedStudentData.length && (
        <div className="load-more">
          <button onClick={loadMoreData}>Load More</button>
        </div>
      )}
    </div>
  );
};

export default EmotionsLogDisplay;
