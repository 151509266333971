import { useEffect, useState, useContext } from "react";
import { authentication, Signout } from "../../firebase";
import "./OptionsScreen.css";
import { Switch, ThemeProvider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { createTheme } from "@mui/material/styles";
import { UserContext } from "../../realm/user.context";
import { loadProfile, updateProfile } from "../../realm/graphqlQueries";
import Spacer from "../../components/Spacer";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import {
  FaClock,
  FaUser,
  FaTimes,
  FaVolumeUp,
  FaChartArea,
} from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShieldHalved } from "@fortawesome/free-solid-svg-icons";
import { faImage } from "@fortawesome/free-solid-svg-icons";

const DisplayNameButton = ({ email, setName }) => {
  const [onMobile, setMobile] = useState(false);
  const [hovering, setHovering] = useState(false);
  const [hoveringSubmit, setHoveringSubmit] = useState(false);
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [changingName, setChangingName] = useState(false);
  const [updating, setUpdating] = useState(false);
  const { realmUser } = useContext(UserContext);

  const canSubmit = firstName !== "" && lastName !== "";

  useEffect(() => {
    const handleResize = () => setMobile(window.innerWidth <= 600);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <div
        onClick={() => setChangingName(true)}
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
        className="name-change"
      >
        Change Display Name
      </div>
      <div 
      className={`name-change-overlay ${changingName ? "visible" : ""}`} 
      onClick={() => setChangingName(false)}
    >
      {changingName && (
        <div className="name-change-container" onClick={(e) => e.stopPropagation()}>
          <p className="name-change-title">Edit Display Name</p>

          <div className="name-change-form">
            <div className="name-container">
              <div className="name-change-field">
                <label className="name-change-label">First Name</label>
                <input 
                  type="text" 
                  className="name-change-input" 
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)} 
                />
              </div>
              <div className="name-change-field">
                <label className="name-change-label">Last Name</label>
                <input 
                  type="text" 
                  className="name-change-input" 
                  value={lastName} 
                  onChange={(e) => setLastName(e.target.value)} 
                />
            </div>
            </div>
            <div
              className={`name-change-submit ${canSubmit ? "" : "disabled"}`}
              onClick={async () => {
                if (!updating && canSubmit) {
                  setUpdating(true);
                  try {
                    await updateProfile(email, realmUser, { displayName: `${firstName} ${lastName}` });
                    setName(`${firstName} ${lastName}`);
                    setFirstName("");
                    setLastName("");
                  } finally {
                    setUpdating(false);
                    setChangingName(false);
                  }
                }
              }}
              onMouseEnter={() => setHoveringSubmit(true)}
              onMouseLeave={() => setHoveringSubmit(false)}
            >
              SAVE DISPLAY NAME
            </div>
          </div>
        </div>
      )}
    </div>
    </>
  );
};

const OptionsScreen = (props) => {
  const [name, setName] = useState("");
  const [img, setImg] = useState("");
  const [email, setEmail] = useState("");
  const [switchVal, setSwitchVal] = useState(true);
  const [wellnessTimer, setWellnessTimer] = useState("1 minute");
  const [adultImagesSetting, setAdultImagesSetting] = useState(props.user.user.adultActionImages ? true : false);
  const [usesCastle, setusesCastle] = useState(props.user.user.usesCastle ? true : false);
  const [voice, setVoice] = useState("english female");
  const [onMobile, setOnMobile] = useState(false);
  const { realmUser, logOutRealmUser } = useContext(UserContext);

  useEffect(() => {
    const savedPreference = localStorage.getItem(`dataPrivacy_${realmUser.profile.userId}`);
    if (savedPreference !== null) {
      setSwitchVal(savedPreference === "true");
    } else {
      setSwitchVal(props.user.user === "student");
    }
  }, [props.user.user, realmUser.profile.userId]);

  useEffect(() => {
    const handleResize = () => setOnMobile(window.innerWidth <= 600);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const unsubscribe = authentication.onAuthStateChanged((user) => {
      if (user) {
        setName(user.displayName ?? props.user.user.displayName);
        setEmail(user.email);
        setWellnessTimer(props.user.user.wellnesstimer);
        setSwitchVal(props.user.user.dataUse);
        if (props.user.user.voice) setVoice(props.user.user.voice);
  
        if (user.photoURL) {
          setImg(user.photoURL);
        } else {
          setImg("");
        }
      } else {
        setName(props.user.user.displayName);
        setEmail(props.user.user.email);
        setImg("");
      }
    });
  
    return () => unsubscribe();
  }, [props.user.user.wellnesstimer, props.user.user.dataUse]);

  const navigate = useNavigate();
  function logOutAndNav() {
    Signout();
    logOutRealmUser();
    navigate("/");
  }
  async function handleSwitch() {
    const email = authentication.currentUser?.email ?? props.user.user.email;
    const newSwitchValue = !switchVal
    await updateProfile(email, realmUser, {
      dataUse: newSwitchValue,
    });
    setSwitchVal(newSwitchValue);
    localStorage.setItem(`dataPrivacy_${realmUser.profile.userId}`, newSwitchValue);
    let tmpProps = props.user;
    tmpProps.user.dataUse = !switchVal; //ANTI PATTERN ALERT
    props.setUser(tmpProps);
    // logger.log(props.user.user, tmpProps.user);
  }
  async function handleWellnessTimerDropDown(value) {
    setWellnessTimer(value);
    await updateProfile(props.user.user.email, realmUser, {
      wellnesstimer: value,
    });
    let tmpProps = props.user;
    tmpProps.user.wellnesstimer = value;
    props.setUser(tmpProps);
  }

  async function handleAdultImagesDropDown(value) {
    const setValue = Boolean(value);
    setAdultImagesSetting(setValue);
    await updateProfile(props.user.user.email, realmUser, {
      adultActionImages: setValue,
    });
    let tmpProps = props.user;
    tmpProps.user.adultActionImages = setValue;
    props.setUser(tmpProps);
  }

  async function handleCastleDropdown(value) {
    const setValue = Boolean(value);
    setusesCastle(setValue);
  
    await updateProfile(props.user.user.email, realmUser, {
      usesCastle: setValue,
    });
    let tmpProps = props.user;
    tmpProps.user.usesCastle = setValue;
    props.setUser(tmpProps);
  }

  async function handleVoiceDropdown(value) {
    setVoice(value);
    const realmResp = await updateProfile(
      props.user.user.email,
      realmUser,
      { voice: value }
    );
    let tmpProps = props.user;
    tmpProps.user.voice = value;
    props.setUser(tmpProps);
  }

  return (
    <div className="optionsoutercolumn">
      <div className="optionsinnercolumn">
        <div className="title-container">
          <div className="options-title">SETTINGS</div>
        </div>
        <div className="settings-container">
          
          <div className="settings-item">
              <div className="settings-title-row">
                <div>
                  {img ? (
                    <img className="optionsimg" src={img}   alt="Profile"
                    onError={() => setImg("")}>
                    </img>
                  ) : (
                    <SentimentSatisfiedAltIcon
                      style={{
                        fontSize: onMobile ? "7.5vmax" : "5vmax",
                        color: "#4cc68d",
                        left: onMobile ? "-10vmin" : "-5vmin",
                      }}
                    />
                  )}
                </div>
                <div className="name-email-row">
                  <div className="display-name-row">
                    <div className="display-name-title">{name}</div>
                    {props.user.user.user !== "student" && (
                      <DisplayNameButton email={props.user.user.email} setName={setName} />
                    )}
                  </div>
                  <div className="optionsemail">{email}</div>
                </div>
              </div>
          </div>
          <div className="settings-item">
            <div className="settings-title-row">
              <FaUser className="settings-icon" alt="icon" />
              <div className="settings-title">Account Type</div>
            </div>
            <div className="settings-description">
              {props.user.user.user === "123wellness" && <div>1-2-3 Wellness™ Leader</div>}
              {props.user.user.user === "superadmin" && <div>Superadmin</div>}
              {props.user.user.user === "admin" && <div>Administrator</div>}
              {props.user.user.user === "teacher" && <div>Teacher</div>}
              {props.user.user.user === "student" && <div>Student</div>}
            </div>
          </div>
          <div className="settings-item">
            <div className="settings-title-row">
              <FaClock className="settings-icon" alt="icon" />
              <div className="settings-title">Wellness Timer</div>
            </div>
            <div className="settings-description">
              The amount of time that I practice wellness during step 3
            </div>
            <select
              className="optionselect"
              value={wellnessTimer}
              onChange={(e) => handleWellnessTimerDropDown(e.target.value)}
            >
              <option value={"10 seconds"}>10 seconds</option>
              <option value={"20 seconds"}>20 seconds</option>
              <option value={"30 seconds"}>30 seconds</option>
              <option value={"1 minute"}>1 minute</option>
              <option value={"2 minutes"}>2 minutes</option>
            </select>{" "}
          </div>
          <div className="settings-item">
            <div className="settings-title-row">
              <FaVolumeUp className="settings-icon" alt="icon" />
              <div className="settings-title">Voice</div>
            </div>
            <div className="settings-description">
              The voice that guides me through the wellness practice
            </div>
            <select
              className="optionselect"
              value={voice}
              onChange={(e) => handleVoiceDropdown(e.target.value)}
            >
              <option value={"english female"}>Lina (English)</option>
              <option value={"english male"}>Drew (English)</option>
              <option value={"spanish female"}>Lina (Spanish)</option>
            </select>{" "}
          </div>
          <div className="settings-item">
            <div className="settings-title-row">
              <FontAwesomeIcon icon={faShieldHalved} className="settings-icon" />
              <div className="settings-title">Data Privacy</div>
            </div>
            <div className="settings-checkbox-container">
              <input
                type="checkbox"
                id="data-privacy-checkbox"
                checked={switchVal}
                onChange={handleSwitch}
                className="settings-checkbox"
              />
              <label htmlFor="data-privacy-checkbox" className="settings-description">
                Make my data visible to my teacher(s) and administrator(s)
              </label>
            </div>
          </div>
          <div className="settings-item">
            <div className="settings-title-row">
              <FontAwesomeIcon icon={faImage} className="settings-icon" />
              <div className="settings-title">Illustrations</div>
            </div>
            <div className="settings-checkbox-container">
            <input
                type="checkbox"
                id="adult-wellness-checkbox"
                checked={adultImagesSetting}
                onChange={(e) => handleAdultImagesDropDown(e.target.checked)}
                className="settings-checkbox"
              />
              <label htmlFor="adult-wellness-checkbox" className="settings-description">
                Use illustrations of adults (not children) in the wellness practice 
              </label>
            </div>
          </div>

          <div className="settings-item">
            <div className="settings-title-row">
              <FaChartArea className="settings-icon" />
              <div className="settings-title">Kids Castle Theme</div>
            </div>
            <div className="settings-checkbox-container">
              <input
                type="checkbox"
                id="use-castle-checkbox"
                checked={usesCastle}
                onChange={(e) => handleCastleDropdown(e.target.checked)}
                className="settings-checkbox"
              />
              <label htmlFor="use-castle-checkbox" className="settings-description">
                Show a kids' castle theme for me
              </label>
            </div>
          </div>
          <div className="buttonrow">
            <button
              onClick={logOutAndNav}
              className="logout"
            >
              Log Out
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OptionsScreen;
