import { BSON } from "realm-web";
import {
  loadSchool,
  loadClasses,
  loadAllClassesFromSchools,
  loadProfilesFromIds,
  loadProfiles,
  loadWellnesses,
  loadDistrictFromCleverId,
  loadDistrict,
  loadClassesFromCleverSchools,
  fetchSchoolIDsByNames,
  fetchSchoolsInfoByID,
  fetchSchoolsInfoByCleverId,
  loadAllDistricts,
  loadAllSchools,
  loadProfiles2,
  loadAllClassesFromSchool,
  loadSchoolsFromCleverId,
  getClassAssociationsForStudents2,
} from "../../../realm/graphqlQueries";
import logger from "../../../utils/logger";

export async function loadUserDistrict(user, realmUser) {
  // return user.user.cleverId
  //   ? await loadDistrictFromCleverId(user.user.district, realmUser)
  //   : await loadDistrict(user.user.district, realmUser);


    if (user.user.user === "123wellness") {
      return await loadAllDistricts(realmUser)
    }
    let realmResp;
     realmResp = await loadDistrictFromCleverId(
      user.user.district,
      realmUser
    );
    if (realmResp.district == null){
      realmResp = await loadDistrict(user.user.district,realmUser)
    }
    //logger.log(realmResp)
    return realmResp 
}
// LOOpin through all schools instead of just the ones that the user is in need to fix this
export async function loadClassData(user, realmUser, userDistrict) {
  let classRef;
  const districts = {};
  let schools = (await loadSchoolsFromCleverId(userDistrict.district.cleverId,realmUser));  
  if (schools)
    schools = schools.schools
  else{
    schools = [{name:user.user.school}]
  }
  //logger.log(user.user.schools)
  if (user.user.cleverId) {
    //logger.log(schools);
    if (user.user.user === "admin") {
      classRef = await loadAllClassesFromSchools(user.user.schools, realmUser);
      logger.log(classRef);
      districts[userDistrict.district.name] = {};
      user.user.schools.forEach((curSchool) => {
      const matchingSchool = schools.find(school => school.cleverId === curSchool)
      districts[userDistrict.district.name][matchingSchool.name] = {};
    });
    } else {
      classRef = await loadClassesFromCleverSchools(
        user.user.schools,
        user.user.class,
        realmUser
      );
      districts[userDistrict.district.name] = {};
      user.user.schools.forEach((curSchool) => {
      const matchingSchool = schools.find(school => school.cleverId === curSchool)
      districts[userDistrict.district.name][matchingSchool.name] = {};
    });
    }
    
  } else {
    if (user.user.user === "admin") {
      //logger.log(user.user.school)

      classRef = await loadAllClassesFromSchool(user.user.school, realmUser);
      districts[userDistrict.district.name] = {};

        districts[userDistrict.district.name][user.user.school] = {};

      
    } else {
      classRef = await loadClasses(
        user.user.school,
        user.user.class,
        realmUser
      );
      districts[userDistrict.district.name] = {
        [user.user.school]: {},
      };
    }
  }

    // const school = cls.school || user.user.school;
    user.user.schools.forEach((curSchool) => {
      const matchingSchool = schools.find(school => school.cleverId === curSchool) || { name: curSchool };

      if (!districts[userDistrict.district.name][matchingSchool.name]) {
        districts[userDistrict.district.name][matchingSchool.name] = [];
      }
      classRef.classes.forEach((cls) => {
      // districts[userDistrict.district.name][school.name] = {};

      if (!districts[userDistrict.district.name][matchingSchool.name][cls.name]) {
        districts[userDistrict.district.name][matchingSchool.name][cls.name] = [];
      }
      districts[userDistrict.district.name][matchingSchool.name][cls.name] =
        cls.students;
    });
    
  });
  //logger.log(districts);

  return [classRef, districts];
}

export async function loadStudentData(user, allStudents, realmUser) {
  const studentProfiles = user.user.cleverId
    ? await loadProfilesFromIds(allStudents, realmUser)
    : await loadProfiles(allStudents, realmUser);

  const { idToEmail: studentIdToEmailMap, emailToName: studentEmailToNameMap } =
    createStudentMaps(studentProfiles.users);

  return { studentProfiles, studentIdToEmailMap, studentEmailToNameMap };
}

export async function getWellnessData(realmUser, user, anonamizeData = false) {
  try {
    // First, load district and students in parallel
    const [userDistrict, allStudents] = await Promise.all([
      loadUserDistrict(user, realmUser),
      loadAllStudents(user, realmUser)
    ]);

    const allStudentIDs = allStudents.map((student) => student._id);

    if (allStudentIDs.length === 0) {
      return { 
        wellnesses: [], 
        studentData: {},
        userDistrict,
        allStudents,
        allStudentIDs,
        studentEmailToNameMap: {}
      };
    }

    const collection = realmUser
      .mongoClient("mongodb-atlas")
      .db("123wellness")
      .collection("wellness");

    // Split into larger chunks for more efficient aggregation
    const chunkSize = 1000;
    const chunks = [];
    for (let i = 0; i < allStudentIDs.length; i += chunkSize) {
      chunks.push(allStudentIDs.slice(i, i + chunkSize));
    }

    const BATCH_SIZE = 10000;
    const processChunk = async (chunk) => {
      // Build the base pipeline with grouping by userID
      const pipeline = [
        {
          $match: {
            userID: { $in: chunk }
          }
        },
        {
          $project: {
            userID: 1,
            date: 1,
            emotion: 1,
            intensity: 1,
            practice: 1,
            helpful: 1,
            action: 1,
            studentId: 1,
            _id: 1,
            // Ensure all fields are included
            category: 1,
            subcategory: 1,
            description: 1,
            timeOfDay: 1
          }
        },
        {
          $sort: { date: -1 }
        },
        {
          $group: {
            _id: "$userID",
            records: { $push: "$$ROOT" }
          }
        }
      ];

      // Execute aggregation
      const results = await collection.aggregate(pipeline);
      
      // Transform results into the required format
      const wellnesses = [];
      const studentDataChunk = {};
      
      for (const result of results) {
        const userID = result._id;
        const records = result.records.map(record => ({
          ...record,
          // Ensure required fields have default values if missing
          emotion: record.emotion || '',
          intensity: record.intensity || 0,
          practice: record.practice || '',
          helpful: record.helpful || false,
          action: record.action || '',
          category: record.category || '',
          subcategory: record.subcategory || '',
          description: record.description || '',
          timeOfDay: record.timeOfDay || '',
          studentId: record.studentId || userID
        }));
        
        // Add to wellnesses array
        wellnesses.push(...records);
        
        // Add to student data
        if (records.length > 0) {
          studentDataChunk[userID] = records;
        }
      }

      return { wellnesses, studentDataChunk };
    };

    // Process all chunks in parallel with concurrency control
    const concurrencyLimit = 4;
    const allWellnesses = [];
    const rawStudentData = {};
    
    // Fetch schools info and process wellness data in parallel
    const [wellnessResults, schoolsInfo, { idToEmail: studentIdToEmailMap, emailToName: studentEmailToNameMap }] = await Promise.all([
      // Process wellness data chunks
      (async () => {
        const results = [];
        for (let i = 0; i < chunks.length; i += concurrencyLimit) {
          const chunkBatch = chunks.slice(i, i + concurrencyLimit);
          const batchResults = await Promise.all(chunkBatch.map(processChunk));
          results.push(...batchResults);
        }
        return results;
      })(),
      // Fetch schools info
      loadSchoolsInfo(user, userDistrict, realmUser),
      // Create student maps in parallel
      Promise.resolve(createStudentMaps(allStudents, anonamizeData))
    ]);

    // Process wellness results
    wellnessResults.forEach(result => {
      allWellnesses.push(...result.wellnesses);
      Object.assign(rawStudentData, result.studentDataChunk);
    });

    // Get class associations using the schools info
    const studentsClasses = await getClassAssociationsForStudents2(
      userDistrict.district,
      schoolsInfo,
      allStudents,
      realmUser
    );

    // Process student data with email mapping and dates
    const studentData = {};
    Object.entries(rawStudentData).forEach(([userID, data]) => {
      const email = allStudents.find(s => s._id === userID)?.email;
      if (email) {
        studentData[email] = data.map(w => ({
          ...w,
          date: new Date(w.date),
          // Ensure all required fields are present and properly formatted
          emotion: w.emotion || '',
          intensity: w.intensity || 0,
          practice: w.practice || '',
          helpful: w.helpful || false,
          action: w.action || '',
          category: w.category || '',
          subcategory: w.subcategory || '',
          description: w.description || '',
          timeOfDay: w.timeOfDay || '',
          studentId: w.studentId || userID
        }));
      }
    });

    // Convert dates in bulk and ensure all fields are present
    const processedWellnesses = allWellnesses.map(w => ({
      ...w,
      date: new Date(w.date),
      // Ensure all required fields are present and properly formatted
      emotion: w.emotion || '',
      intensity: w.intensity || 0,
      practice: w.practice || '',
      helpful: w.helpful || false,
      action: w.action || '',
      category: w.category || '',
      subcategory: w.subcategory || '',
      description: w.description || '',
      timeOfDay: w.timeOfDay || '',
      studentId: w.studentId || w.userID
    }));

    logger.log(`Retrieved ${processedWellnesses.length} wellness records for ${allStudentIDs.length} students`);
    return { 
      wellnesses: processedWellnesses,
      studentData,
      schoolsInfo,
      studentsClasses,
      userDistrict,
      allStudents,
      allStudentIDs,
      studentEmailToNameMap
    };
  } catch (error) {
    logger.error("Error in getWellnessData:", error);
    throw error;
  }
}

export async function loadSchoolsInfo(user, userDistrict, realmUser) {
  // Determine which schools to fetch based on user role
  let schoolsToFetch;

  if (user.user.user === "superadmin") {
    schoolsToFetch = userDistrict.district.schools;
  } else if (user.user.user === "admin" || user.user.user === "teacher" ) {
    schoolsToFetch = user.user.cleverId ? user.user.schools : [user.user.school];
  }

  // 123wellness pulls all schools by pulling data with no filter
  if (user.user.cleverId || user.user.user === "123wellness") {
    return fetchSchoolsInfoByCleverId(schoolsToFetch, realmUser);
  } else {
    const schoolIDs = await fetchSchoolIDsByNames(schoolsToFetch, realmUser);
    return fetchSchoolsInfoByID(schoolIDs, realmUser);
  }
}

export async function loadAllStudents(user, userDistrict, realmUser) {
  // 123wellness pulls all students
  const query = {
    user: "student",
    // Base query for all students
  };

  const userRole = user.user.user;
  logger.log("userDistrict", userDistrict)

  // Handle district filtering
  if (userRole !== "123wellness") {
    query.district_in = [userDistrict.district.cleverId ?? userDistrict.district.name];
  }

  // Handle school filtering for admin and teacher roles
  if (userRole === "admin" || userRole === "teacher") {
    // Use schools array if available, otherwise use single school
    if (Array.isArray(user.user.schools) && user.user.schools.length > 0) {
      query.schools_in = user.user.schools;
    } else if (user.user.school) {
      query.school = user.user.school;
    } else {
      logger.warn("No school information found for user:", user.user.email);
    }

    // Add class filtering for teachers
    if (userRole === "teacher" && user.user.class) {
      query.class_in = user.user.class;
    }
  }
  
  const allStudentsRef = await loadProfiles2(query, realmUser);
  
  if (!allStudentsRef || !allStudentsRef.users) {
    logger.error("No students found for the given query:", query);
    return [];
  }
  
  return allStudentsRef.users;
}

export async function load123WellnessData(realmUser) {
  return Promise.all([
    loadAllDistricts(realmUser),
    loadProfiles2({ user: "student" }, realmUser),
    realmUser.callFunction("getAllWellnessData"),
    loadAllSchools(realmUser),
  ]);
}

export function createStudentMaps(students) {
  return students.reduce(
    (maps, student) => {
      maps.idToEmail[student.cleverId] = student.email;
      maps.emailToName[student.email] = student.displayName ?? student.email;
      return maps;
    },
    { idToEmail: {}, emailToName: {} }
  );
}

export function getWellnessDataForDistrict(state, district) {
  // //logger.log("test", state.districts);
  if (!state.districts[district]) {
    logger.error(`District ${district} not found.`);
    return [];
  }
  let students = [];
  Object.values(state.districts[district]).forEach((school) => {
    Object.values(school).forEach((className) => {
      if (
        Array.isArray(className) &&
        className.length !== 0 &&
        typeof className[0] === "string"
      ) {
        // //logger.log(className.length);
        // //logger.log(typeof className[0]);
        students = students.concat(className);
      } else {
        Object.values(className).forEach((studentData) => {
          if (!students.includes(studentData.email)) {
            students.push(studentData.email);
          }
        });
      }
    });
  });
  // //logger.log("students", students);
  return students.map((email) => state.studentData[email] || []).flat();
}
