import React, { useState, useEffect, useRef } from "react";
import {
  FaClock,
  FaInfoCircle,
  FaThumbsUp,
  FaUser,
  FaTimes,
  FaCalendar,
} from "react-icons/fa";
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import moment from "moment";
import { NoData } from "../screens/StudentData/components/AlertComponents";
import "./BigFiveWellnessPractice.css";
import { bigFiveHandler } from "../screens/StudentData/handlers/bigFiveHandler";
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons';
import { categorizedPractices, backgroundColors } from "../screens/StudentData/utils/practicesConfig";
import './PracticeCircle.css';

const BigFiveWellnessDataWrapper = (props) => {
  if ("state" in props && "updateState" in props) {
    return <BigFiveWellnessDataStateVersion {...props} />;
  } else {
    return <BigFiveWellnessDataPropsVersion {...props} />;
  }
};

const BigFiveWellnessDataStateVersion = ({ state, updateState, adultActionImages }) => {
  useEffect(() => {
    if (!state.selectedTimes || !Object.values(state.selectedTimes).some(val => val)) {
      updateState({ 
        selectedTimes: { "All Day": true } 
      });
    }
  }, []);

  const handleBigFiveFilters = (changedFilter) => {
    const newState = bigFiveHandler(state, changedFilter);
    updateState(newState);
  };

  return (
    <BigFiveWellnessData
      bigFiveFilteredData={state.bigFiveFilteredData}
      selectedTimes={state.selectedTimes}
      selectedSubOptions={state.selectedSubOptions}
      helpfulFilter={state.helpfulFilter}
      handleBigFiveFilters={handleBigFiveFilters}
      studentEmailToNameMap={state.studentEmailToNameMap}
      studentUser={false}
      wellnessPopupOpen={state.wellnessPopupOpen}
      setWellnessPopupOpen={(value) => updateState({ wellnessPopupOpen: value })}
      adultActionImages={adultActionImages}
    />
  );
};

const BigFiveWellnessDataPropsVersion = (props) => {
  useEffect(() => {
    if (!props.selectedTimes || !Object.values(props.selectedTimes).some(val => val)) {
      props.setSelectedTimes({ "All Day": true });
    }
  }, []);

  return (
    <BigFiveWellnessData
      bigFiveFilteredData={props.bigFiveFilteredData}
      selectedTimes={props.selectedTimes}
      selectedSubOptions={props.selectedSubOptions}
      helpfulFilter={props.helpfulFilter}
      handleBigFiveFilters={(changedFilter) => {
        if (changedFilter.type === "selectedTimes") {
          props.setSelectedTimes(changedFilter.value);
        } else if (changedFilter.type === "selectedSubOptions") {
          props.setSelectedSubOptions(changedFilter.value);
        } else if (changedFilter.type === "helpfulFilter") {
          props.setHelpfulFilter(changedFilter.value);
        }
      }}
      studentEmailToNameMap={{}}
      studentUser={true}
      wellnessPopupOpen={props.wellnessPopupOpen}
      setWellnessPopupOpen={props.setWellnessPopupOpen}
      adultActionImages={props.adultActionImages}
    />
  );
};


const BigFiveWellnessData = ({
  bigFiveFilteredData,
  selectedTimes,
  selectedSubOptions,
  helpfulFilter,
  handleBigFiveFilters,
  studentEmailToNameMap,
  studentUser,
  wellnessPopupOpen,
  adultActionImages,
  setWellnessPopupOpen
}) => {
  // const [isTimePopupOpen, setIsTimePopupOpen] = useState(false);
  const popupRef = useRef(null);

  const toggleTimePopup = () => {
    setWellnessPopupOpen(!wellnessPopupOpen);
  };

  const closeTimePopup = () => {
    setWellnessPopupOpen(false);
  };

  const getSelectedTimeText = () => {
    if (selectedTimes["All Day"]) return "All Day";

    const selectedTimesList = Object.entries(selectedTimes)
      .filter(([key, value]) => value && key !== "All Day")
      .map(([key]) => key);

    if (selectedTimesList.length === 0) return "Choose a time of day";
    if (selectedTimesList.length === 1) return selectedTimesList[0];
    return selectedTimesList.join(", ");
  };

  const TimeframeCheckboxSelect = ({ isOpen, onClose }) => {
    const subOptions = {
      "All Day": [],
      "Early Morning": [
        "12am-1am",
        "1am-2am",
        "2am-3am",
        "3am-4am",
        "4am-5am",
        "5am-6am",
        "6am-7am",
        "7am-8am",
      ],
      Morning: ["8am-9am", "9am-10am", "10am-11am", "11am-12pm"],
      Afternoon: [
        "12pm-1pm",
        "1pm-2pm",
        "2pm-3pm",
        "3pm-4pm",
        "4pm-5pm",
        "5pm-6pm",
      ],
      Evening: [
        "6pm-7pm",
        "7pm-8pm",
        "8pm-9pm",
        "9pm-10pm",
        "10pm-11pm",
        "11pm-12am",
      ],
    };

    const handleMainOptionChange = (option) => {
      const newSelectedTimes = { ...selectedTimes };
    
      if (option === "All Day") {
        Object.keys(newSelectedTimes).forEach((key) => {
          newSelectedTimes[key] = key === "All Day";
        });
        handleBigFiveFilters({ type: "selectedSubOptions", value: {} });
      } else {
        Object.keys(newSelectedTimes).forEach((key) => {
          newSelectedTimes[key] = false;
        });
        newSelectedTimes[option] = true;
        handleBigFiveFilters({
          type: "selectedSubOptions",
          value: selectedSubOptions[option] || {},
        });
      }
      handleBigFiveFilters({ type: "selectedTimes", value: newSelectedTimes });
    };

    const handleSubOptionChange = (mainOption, subOption) => {
      const updatedSubOptions = {
        ...selectedSubOptions,
        [mainOption]: {
          ...Object.keys(subOptions[mainOption]).reduce((acc, opt) => {
            acc[opt] = false;
            return acc;
          }, {}),
          [subOption]: true,
        },
      };
    
      handleBigFiveFilters({
        type: "selectedSubOptions",
        value: updatedSubOptions,
      });
    };
    
    return (
      <div className={`time-selector-popup ${isOpen ? "open" : ""}`} >
        <div className="popup-content">
          <button className="close-button" onClick={onClose}>
            <FaTimes />
          </button>
          <div className="time-selector">
            {Object.entries(subOptions).map(([option, times]) => (
              <div key={option}>
                <label>
                  <input
                    type="radio"
                    checked={selectedTimes[option]}
                    onChange={() => handleMainOptionChange(option)}
                  />
                  <span className="main-option">
                    <strong>{option}</strong>
                  </span>
                </label>
                {selectedTimes[option] && option !== "All Day" && (
                  <div className="sub-options" style={{ display: "block" }}>
                    {times.map((subOption) => (
                      <label key={subOption}>
                        <input
                          type="radio"
                          checked={
                            selectedSubOptions[option]?.[subOption] ?? false
                          }
                          onChange={() =>
                            handleSubOptionChange(option, subOption)
                          }
                        />
                        {subOption}
                      </label>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const BigFiveDataDisplay = ({ bigFiveFilteredData, adultActionImages, onHover, onLeave }) => {
    const [hoveredItem, setHoveredItem] = useState(null);
    const [displayedData, setDisplayedData] = useState([]);
    const [dataIndex, setDataIndex] = useState(50);
    const tableRef = useRef(null);
    const emotionColors = {
      sad: "#C9E2FB",
      tired: "#C9E2FB",
      lonely: "#C9E2FB",
      happy: "#C8FADD",
      grateful: "#C8FADD",
      calm: "#C8FADD",
      stressed: "#D2BCE5",
      scared: "#D2BCE5",
      angry: "#D2BCE5",
    };

    useEffect(() => {
      setDisplayedData(bigFiveFilteredData.slice(0, 50));
      setDataIndex(50);
    }, [bigFiveFilteredData]);

    const loadMoreData = () => {
      const newIndex = dataIndex + 50;
      setDisplayedData(bigFiveFilteredData.slice(0, newIndex));
      setDataIndex(newIndex);
    };

    const handleScroll = () => {
      if (tableRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = tableRef.current;
        if (scrollTop + clientHeight === scrollHeight) {
          loadMoreData();
        }
      }
    };
    var imageSettingFilenameModifier = null
    if (adultActionImages){
        imageSettingFilenameModifier = 'AdultCircle_BIG_FONT'
    } else {
        imageSettingFilenameModifier = 'TeenCircle_BIG_FONT'
    }
    const getBackgroundColor = (item) => {
      if (item.toLowerCase().startsWith("other")) {
        return backgroundColors.green;
      }

      for (const [color, practices] of Object.entries(categorizedPractices)) {
        if (practices.includes(item)) {
          return backgroundColors[color];
        }
      }
      return null;
    };
    const [isHovered, setIsHovered] = useState(false);

    return (
      <div className="big-five-data-display">
        <div className="filter-controls">
          <div className="filter-column" ref={popupRef}>
            <div className="filter-label">
              <FaClock className="filter-icon" />
              <span>Time of day</span>
            </div>
            <button className="time-selector-button" onClick={toggleTimePopup}>
              {getSelectedTimeText()}
            </button>
            <TimeframeCheckboxSelect
              isOpen={wellnessPopupOpen}
              onClose={closeTimePopup}
            />
          </div>
          <div className="filter-column">
            <div className="filter-label">
              <FaThumbsUp className="filter-icon" />
              <span>Helpful?</span>
            </div>
            <select
              id="helpfulFilter"
              value={helpfulFilter}
              onChange={(e) =>
                handleBigFiveFilters({
                  type: "helpfulFilter",
                  value: e.target.value,
                })
              }
            >
              <option value="All">All</option>
              <option value="Helpful">Helpful</option>
              <option value="Not helpful">Not helpful</option>
            </select>
          </div>
        </div>
        {bigFiveFilteredData.length === 0 ? (
          <div style={{marginTop:"2vh"}}>

            <NoData />
          </div>
        ) : (
          <div
            className="table-container"
            ref={tableRef}
            onScroll={handleScroll}
          >
            <table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Time</th>
                  {!studentUser && <th>Who</th>}
                  <th>Emotion</th>
                  <th>Wellness Strategy</th>
                  <th>Helpful</th>
                </tr>
              </thead>
              <tbody>
                {displayedData
                  .sort((a, b) => new Date(b.date) - new Date(a.date))
                  .map((item) => {
                    const isOther = item.action.toLowerCase().startsWith("other");
                    const imageSrc = isOther
                      ? "../images/actions/Other_BIG_FONT.png"
                      : `../images/actions/${item.action} ${imageSettingFilenameModifier}.png`;
                    const backgroundColor = getBackgroundColor(item.action);
                      
                    return (
                      <tr
                        key={item._id}
                        style={{ backgroundColor: emotionColors[item.emotion] }}
                      >
                        <td>
                          <div className="cell-head"><span className="cell-header">Date</span></div>
                          <div className="cell-content">
                            <FontAwesomeIcon className="icon" icon={faCalendarDays}/>
                            {moment(item.date).format("M/D/YY")}
                          </div>
                        </td>
                        <td>
                          <div className="cell-head"><span className="cell-header">Time</span></div>
                          <div className="cell-content">
                            <FaClock className="icon" />
                            {moment(item.date).format("h:mm A")}
                          </div>
                        </td>
                        {!studentUser &&  <td className="name-cell">
                          <div className="cell-head"><span className="cell-header">Who</span></div>
                          <div className="cell-content">
                            <FaUser className="icon" />
                            {studentEmailToNameMap[item.email]
                              ? studentEmailToNameMap[item.email]
                              : item.email}
                          </div>
                        </td>}
                        <td className="emotion-cell">
                          <div className="cell-head"><span className="cell-header">Emotion</span></div>
                          <div className="cell-content">
                            <img
                              src={`/images/emotions/${item.emotion}.png`}
                              alt={item.emotion}
                              className="emotion-icon-big5"
                            />
                            {item.emotion}
                          </div>
                        </td>
                        <td className="action-cell">
                          <div className="cell-head"><span className="cell-header">Wellness Strategy</span></div>
                          <div className="cell-content">
                          <div className="history-circle-with-text">
                            <div
                              className="history-circle-container"
                              style={{ backgroundColor }}
                              onMouseEnter={() => {
                                setIsHovered(true);
                                onHover?.();
                              }}
                              onMouseLeave={() => {
                                setIsHovered(false);
                                onLeave?.();
                              }}
                            >
                              <img 
                                src={imageSrc} 
                                alt={item.action} 
                                className="history-circle-image" 
                                loading="lazy" 
                              />
                              {isHovered && item.action && (
                                <div className="history-hover-text">{item.action}</div>
                              )}
                            </div>
                          </div>
                          </div>
                        </td>
                        <td>
                          <div><span className="cell-header">Helpful</span></div>
                          <div>
                              {item.helpful ? (
                              <FaThumbsUp className="thumbs-up" />
                            ) : (
                              ""
                            )}
                          </div>
                        </td>
                      </tr>
                    );
                })}
              </tbody>
            </table>
            {displayedData.length < bigFiveFilteredData.length && (
              <div className="load-more">
                <button onClick={loadMoreData}>Load More</button>
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="BigFiveWellnessTab">
      <BigFiveDataDisplay bigFiveFilteredData={bigFiveFilteredData} adultActionImages={adultActionImages} />
    </div>
  );
};

export default BigFiveWellnessDataWrapper;
