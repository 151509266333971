import _ from "lodash";
import "./StudentsScreen.css";
import { UserContext } from "../../../realm/user.context";
import ReactDataSheet from "react-datasheet";
import React, { useState, useContext, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import "./toast-overwrites.css";
import {
  insertProfiles,
  updateProfile,
  insertDistrict,
  loadProfile,
  insertSchoolV2,
  loadDistricts,
  loadDistrict,
} from "../../../realm/graphqlQueries";
import Spacer from "../../../components/Spacer";
import { LoadingOverlay } from "../../../components/LoadingOverlay";
import CustomEditor from "../../../components/CustomEditor";
import { toast } from "react-toastify";
import UserGrid from "./UserGrid";
import { clearExistingProfiles } from '../utils/utils';
import logger from '../../../utils/logger';


const AddSchoolButton = ({ refresh, district, onMobile, highlightedColor, user }) => {
  const [hovering, setHovering] = useState(false);
  const [schoolName, setSchoolName] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [hoveringSubmit, setHoveringSubmit] = useState(false);
  const [creatingSchool, setCreatingSchool] = useState(false);
  const [adminGrid, setAdminGrid] = useState([]);
  const addingDistrict = district === undefined;
  let startingAdminGrid = []
  let i = 0
  while(i < 5){
    startingAdminGrid.push({email: ''})
    i++
  }

  const { realmUser } = useContext(UserContext);

  useEffect(() => {
    setAdminGrid(startingAdminGrid);
  }, []);
  
  const canSubmit = !submitting && schoolName !== "";

  const handleSaveChanges = async (updatedData) => {
    if (canSubmit) {
      setSubmitting(true);
      const admins = extractAdmins(updatedData);
      await submitSchool(admins);
      setSubmitting(false);
    }
  };
  
  const extractAdmins = (data) => data.map((row) => row.email.trim()).filter((email) => email !== "");
  
  const submitSchool = async (admins) => {
    await addAdminUsers(admins);
    addingDistrict ? await createDistrict(admins) : await createSchool(admins);
    await refresh();
    setCreatingSchool(false);
  };
  
  const addAdminUsers = async (admins) => {
    for (const email of admins) {
      if (email !== "") {
        await clearExistingProfiles(email, realmUser, "admin", null);
        
        const userDoc =
        {
          email,
          user: "admin",
          district, count: 0,
          dataUse: false,
          class: [],
          school: schoolName,
          schools: [schoolName],
          wellnesstimer: "30 seconds"
        };
        await addUser(userDoc);
      }
    }
  };
  
  const createSchool = async (admins) => {
      // Check if a school with the same name exists in the district
      const loadedDistrict = await loadDistrict(district, realmUser);
      const existingSchools = loadedDistrict.district.schools;

      logger.log("schools in district", existingSchools)
      const duplicateSchool = existingSchools.some(
        (school) => school.trim().toLowerCase() === schoolName.trim().toLowerCase()
      );
      logger.log("duplicate schools", duplicateSchool)
  
      if (duplicateSchool) {
        toast.error(`A school with the name "${schoolName}" already exists in the district.`);
        return;
      }
  
      await insertSchoolV2({ name: schoolName, classes: [], admins, district }, realmUser);
      toast.success(`School "${schoolName}" created successfully.`);
  };
  
  const createDistrict = async (admins) => await insertDistrict({ name: schoolName, superadmins: admins, schools: [] }, realmUser);
  
  const addUser = async (attributes) => {
    const profile = await loadProfile(attributes.email, realmUser);
    if (profile.user === null) await insertProfiles([attributes], realmUser);
    else if (attributes.classes) attributes.classes = [...new Set(profile.user.class.concat(attributes.classes))];
    await updateProfile(attributes.email, realmUser, attributes);
  };

  if (submitting) return <LoadingOverlay></LoadingOverlay>;

  return (
    <>
        <div
          onMouseEnter={() => setHovering(true)}
          onMouseLeave={() => setHovering(false)}
          onClick={() => setCreatingSchool(true)}
          className="create-school"
          style={{
            borderColor: highlightedColor,
            cursor: hovering ? "pointer" : "default",
            color: hovering ? "#fff" : highlightedColor,
            transition: "background-color 0.5s, color 0.5s",
            backgroundColor: hovering ? highlightedColor : "#fff",
          }}
        >
          Create {addingDistrict ? "District" : "School"}
        </div>
      <div
        onClick={() => setCreatingSchool(false)}
        className="fixed-overlay"
        style={{
          opacity: creatingSchool ? 1 : 0,
          width: creatingSchool ? "100vw" : 0,
          height: creatingSchool ? "100vh" : 0,
        }}
      >
        <div
          onClick={(e) => e.stopPropagation()}
          style={{
            width: "60vw",
            display: "flex",
            padding: "2.5vmin",
            borderRadius: "5px",
            alignItems: "center",
            flexDirection: "column",
            backgroundColor: "#fff",
            opacity: creatingSchool ? 1 : 0,
            marginLeft: onMobile ? 0 : "20vw",
          }}
        >
          <p
            style={{
              margin: 0,
              fontSize: "5vmin",
              fontWeight: "bold",
              color: highlightedColor,
            }}
          >
            Create a {addingDistrict ? "District" : "School"}
          </p>
          <Spacer height="2.5vmin" />
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <p
              style={{
                margin: 0,
                width: "12.5vw",
                fontSize: "3vmin",
                fontWeight: "bold",
                color: highlightedColor,
              }}
            >
              {addingDistrict ? "District" : "School"} Name:
            </p>
            <textarea
              onChange={(e) => setSchoolName(e.target.value)}
              style={{
                flex: 1,
                padding: "1vmin",
                fontSize: "3vmin",
                textAlign: "center",
                borderRadius: "5px",
                borderStyle: "solid",
                color: highlightedColor,
                borderColor: highlightedColor,
              }}
            />
          </div>
          <Spacer height="2.5vmin" />
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <p
              style={{
                margin: 0,
                width: "12.5vw",
                fontSize: "3vmin",
                fontWeight: "bold",
                color: highlightedColor,
              }}
            >
              Add {addingDistrict ? "Superadmins" : "Admins"}
            </p>
            {creatingSchool && (
              <>
                <UserGrid
                  initialData={adminGrid}
                  onDataChange={(updatedData) => setAdminGrid(updatedData)}
                />
                <Spacer height="2.5vmin" />
                <button
                  onClick={() => handleSaveChanges(adminGrid)}
                  className={`submit-button highlighted ${
                    canSubmit ? 'enabled' : 'disabled'
                  }`}
                  onMouseEnter={() => setHoveringSubmit(true)}
                  onMouseLeave={() => setHoveringSubmit(false)}
                  style={{ '--highlighted-color': highlightedColor }}
                >
                  Submit
                </button>
              </>
            )}
          </div>
          <Spacer height="2.5vmin" />
        </div>
      </div>
    </>
  );
};

export default AddSchoolButton;
