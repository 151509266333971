import "./Congratulations.css";
import { motion } from "framer-motion";
import { Context } from "../../components/Store";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  updateWellnessHelpfulMutation,
  updateProfile,
} from "../../realm/graphqlQueries";
import { UserContext } from "../../realm/user.context";
import { Context2 } from "../../components/Audio";
import ReactPlayer from "react-player/lazy";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLightbulb } from "@fortawesome/free-solid-svg-icons";
import { getWellnessDataByUserID } from "../../realm/graphqlQueries";
import { batchUpdate } from "../../realm/batchUpdate";
import { MyStoryContext } from "../../context/myStory.context";
import logger from "../../utils/logger";

const Congratulations = (props) => {
  const { realmUser } = useContext(UserContext);
  const { fetchAllData } = useContext(MyStoryContext);
  const [wellnessDataPoint, setWellnessDataPoint] = useContext(Context);
  const navigate = useNavigate();
  const [count, setCount] = useState(0);
  const audio_url_prefix = props.user.user.voice
    ? props.user.user.voice.replace(" ", "_")
    : "english_female";
  const [playing, setPlaying] = useContext(Context2);
  const [coinsEarned, setCoinsEarned] = useState(0);
  const [coinTotal, setCoinTotal] = useState(props.user.user.coins ?? 0);

  useEffect(() => {
    processWellnessDataPointsToAchievements(props.user.user, realmUser);
    // fetchCount();
  }, []);
  // change to actually fetch the count
  async function fetchCount() {
    let nCount = 0;
    if (props.user.user.count !== undefined && props.user.user.count !== null) {
      nCount = props.user.user.count + 1;
    }
    updateProfile(props.user.user.email, realmUser, { count: nCount });
    let tmpProps = props.user;
    tmpProps.user.count = nCount;
    props.setUser(tmpProps);
    setCount(nCount);
  }

  function updateDayWeekStreaks(
    currentDayStreak,
    currentWeekStreak,
    wellnessPractices
  ) {
    // check the current day streak
    var updatedDayStreak = currentDayStreak;
    var updatedWeekStreak = currentWeekStreak;
    const currentDate = new Date();
    // if the streak is 0 set it to 1
    if (currentDayStreak == 0) {
      updatedDayStreak = 1;
    }
    if (currentWeekStreak == 0) {
      updatedWeekStreak = 1;
    }
    if (wellnessPractices.length > 1) {
      const currentObject = wellnessPractices[1];
      const currentObjectDate = new Date(currentObject.date);
      // Set both dates to the start of their respective days for accurate comparison
      currentDate.setHours(0, 0, 0, 0);
      currentObjectDate.setHours(0, 0, 0, 0);

      // Calculate the difference in days
      var timeDifference = currentDate.getTime() - currentObjectDate.getTime();
      var dayDifference = timeDifference / (1000 * 3600 * 24);
      // if the practice was the day before increase the daystreak
      if (dayDifference == 1) {
        // timesPracticedToday should still be 1
        updatedDayStreak = currentDayStreak + 1;
        // if the practice was the same day then don't increase the day streak
      }
      // if the last practice before this was a week or more ago increment the week streak
      if (dayDifference >= 7) {
        updatedWeekStreak = currentWeekStreak + 1;
      }
    }

    return [updatedDayStreak, updatedWeekStreak];
  }

  async function processWellnessDataPointsToAchievements(user, realmUser) {
    const resp = await getWellnessDataByUserID(user._id, realmUser);
    const [checkedDayStreak, checkedWeekStreak] = checkStreaksDeprecation(
      user,
      resp
    );
    // set the users' day and week streaks to updatedDayStreak, updatedWeekStreak
    // (does not have to be saved to the database if at the end of this function we save the updated user object)
    const [updatedDayStreak, updatedWeekStreak] = updateDayWeekStreaks(
      checkedDayStreak,
      checkedWeekStreak,
      resp
    );
    // set the users' coins to newCoinTotal in the database
    const [coinsEarnedReturned, newCoinTotal] = updateCoinsTotal(
      user,
      resp,
      updatedDayStreak ? user.curDayStreak : 0
    );
    setCoinsEarned(coinsEarnedReturned);
    setCoinTotal(newCoinTotal);

    // update the user's info in the database
    const updatedBadges = updateBadges(resp);
    const updateObject = {
      badges: updatedBadges,
      curDayStreak: updatedDayStreak,
      curWeekStreak: updatedWeekStreak,
      coins: newCoinTotal,
      maxDayStreak:
        user.maxDayStreak > updatedDayStreak
          ? user.maxDayStreak
          : updatedDayStreak,
      maxWeekStreak:
        user.maxWeekStreak > updatedWeekStreak
          ? user.maxWeekStreak
          : updatedWeekStreak,
    };

    await batchUpdate(realmUser, "users", { email: user.email }, updateObject);
  }

  // use this function wherever we want to check if the users' streaks have ended
  function checkStreaksDeprecation(user, wellnessPractices) {
    var currentDayStreak = user.curDayStreak ? user.curDayStreak : 0;
    var currentWeekStreak = user.curWeekStreak ? user.curWeekStreak : 0;
    // Day streak should be deprecated when:
    // The last practice was before the start of yesterday (not just 24 hours ago, you should be able to do one at the start of yesterday and one at the end of today)
    // Week streak should be deprecated when:
    // The last practice was before the start of seven days ago
    var resetDayStreak = false;
    var resetWeekStreak = false;
    if (wellnessPractices.length > 0) {
      const lastPractice = wellnessPractices[0];
      const lastPracticeDate = new Date(lastPractice.date);
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      lastPracticeDate.setHours(0, 0, 0, 0);
      var timeDifference = currentDate.getTime() - lastPracticeDate.getTime();
      var dayDifference = timeDifference / (1000 * 3600 * 24);
      if (currentDayStreak >= 1 && dayDifference > 1) {
        resetDayStreak = true;
      }
      if (currentWeekStreak >= 1 && dayDifference > 7) {
        resetWeekStreak = true;
      }
    }
    return [
      resetDayStreak ? 0 : currentDayStreak,
      resetWeekStreak ? 0 : currentWeekStreak,
    ];
  }

  function updateCoinsTotal(user, wellnessPractices, daystreak) {
    const dayStreakCoinIncreases = {
      5: 2,
      7: 3,
      30: 4,
      100: 5,
      250: 6,
      500: 7,
      1095: 8,
      1460: 9,
      1825: 10,
    };
    var newCoinTotal = user.coins ? user.coins : 0;
    let coinIncrease = 1;
    for (const [threshold, increase] of Object.entries(
      dayStreakCoinIncreases
    )) {
      if (daystreak >= parseInt(threshold)) {
        coinIncrease = increase;
      } else {
        break;
      }
    }
    if (wellnessPractices.length <= 3) {
      newCoinTotal = newCoinTotal + coinIncrease;
    } else {
      var practicesToday = 0;
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      for (let i = 0; i < 3; i++) {
        const objectDate = new Date(wellnessPractices[i].date);
        objectDate.setHours(0, 0, 0, 0);
        if (currentDate.getTime() == objectDate.getTime()) {
          practicesToday = practicesToday + 1;
        }
      }
      if (practicesToday == 3) {
        coinIncrease = 0;
      }
      newCoinTotal = newCoinTotal + coinIncrease;
    }
    return [coinIncrease, newCoinTotal];
  }

  function updateBadges(wellnessPractices) {
    // ALL TIME BADGES
    // 'Active':'Earn this badge by choosing an active wellness practice like stretching or moving your body 20 times',
    const activePractices = ["Stretch", "Move my body"];
    // 'Creative':'Earn this badge for choosing "other" as your wellness practice 20 times and creating wellness in your own way',
    const creativePractices = ["Other"];
    // 'Kindness':'Earn this badge by choosing a wellness practice that is spreads kindness to others 10 times (Examples: Help others, give someone a compliment)',
    const kindnessPractices = ["Help Others", "Give someone a compliment"];
    // 'Learner':'Earn this badge by practicing a thoughtful wellness practice 15 times \
    //         (Examples: Write in my journal, Use my growth mindset, Remember that difficult times pass and I'm not alone, Remind myself of two good things)',
    const learnerPractices = [
      "Write in my journal",
      "Remember that difficult times pass",
      "Remind myself of two good things in my life",
    ];
    // 'Positive':"Earn this badge by choosing wellness practices that promote positivity with yourself 20 times(Examples: Smile, Imagine a peaceful place,
    // Imagine positie things in the future, Rememver that difficult times pass, Remind myself of two good things in my life, Tell myself something nice).",
    const positivePractices = [
      "Smile big",
      "Imagine a peaceful place",
      "Imagine positive things in the future",
      "Remember that difficult times pass",
      "Remind myself of two good things in my life",
      "Tell myself something nice",
    ];
    // 'Thankful':'Earn this badge for practicing being grateful as your wellness practice 10 times'
    const thankfulPractices = ["Think of 3 things that I am grateful for"];
    // 'Strengths':'Earn this badge for choosing the same wellness practice 20 times. This shows that you are learning what wellness practices help make you strong.',
    // 'Courage':'Earn this badge for having the courage to feel 5 different emotions in a month. After all, we all experience different emotions.',
    // 'Explorer':'Earn this badge for trying 5 different wellness practices in a month',
    // 'Determined':'Earn this badge for feeling your emotions 50 times in a month (practicing 50 times in a month)',

    var activeCount = 0;
    var creativeCount = 0;
    var kindessCount = 0;
    var learnerCount = 0;
    var positiveCount = 0;
    var strengthsCount = 0;
    var thankfulCount = 0;
    var courageCount = 0;
    var explorerCount = 0;
    var determinedCount = 0;
    var courageEmotionsArray = [];
    var explorerPracticesArray = [];
    var strengthsCountsDict = {};

    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    for (const practice of wellnessPractices) {
      // if(practice)
      if (practice.action in activePractices) {
        activeCount += 1;
      }
      if (practice.action in creativePractices) {
        creativeCount += 1;
      }
      if (practice.action in kindnessPractices) {
        kindessCount += 1;
      }
      if (practice.action in learnerPractices) {
        learnerCount += 1;
      }
      if (practice.action in positivePractices) {
        positiveCount += 1;
      }
      if (practice.action in thankfulPractices) {
        thankfulCount += 1;
      }
      // TIME FRAME BADGES
      // 'Courage':'Earn this badge for having the courage to feel 5 different emotions in a month. After all, we all experience different emotions.',
      // 'Explorer':'Earn this badge for trying 5 different wellness practices in a month',
      // 'Determined':'Earn this badge for feeling your emotions 50 times in a month (practicing 50 times in a month)',
      const practiceDate = new Date(practice.date);
      practiceDate.setHours(0, 0, 0, 0);
      const timeDifference = currentDate.getTime() - practiceDate.getTime();
      const dayDifference = timeDifference / (1000 * 3600 * 24);
      // if the practice is within the last 30 days it applies to the time-sensitive badges
      if (dayDifference < 30) {
        determinedCount += 1;
        if (!(practice.emotion in courageEmotionsArray)) {
          courageEmotionsArray.push(practice.emotion);
        }
        if (!(practice.action in explorerPracticesArray)) {
          explorerPracticesArray.push(practice.action);
        }
        if (practice.action in Object.keys(strengthsCountsDict)) {
          strengthsCountsDict[practice.action] += 1;
        } else {
          strengthsCountsDict[practice.action] = 1;
        }
      }
    }
    courageCount = courageEmotionsArray.length;
    explorerCount = explorerPracticesArray.length;
    strengthsCount = Math.max(...Object.values(strengthsCountsDict));

    return {
      active: Math.floor(activeCount / 20),
      creative: Math.floor(creativeCount / 20),
      thankful: Math.floor(thankfulCount / 20),
      explorer: Math.floor(explorerCount / 20),
      strengths: Math.floor(strengthsCount / 20),
      courage: Math.floor(courageCount / 20),
      learner: Math.floor(learnerCount / 20),
      kindness: Math.floor(kindessCount / 20),
      determined: Math.floor(determinedCount / 20),
      positive: Math.floor(positiveCount / 20),
    };
  }

  async function updateDatapoint(helpful) {
    try {
      // Call the GraphQL mutation to update the 'helpful' field
      const resp = await updateWellnessHelpfulMutation(
        props.lWellnessId,
        helpful,
        realmUser
      );
      if (resp && resp.updateOneWellness && resp.updateOneWellness._id) {
        logger.log("Success!");
        navigate("/profilescreen", { state: { activeTab: "achievement" } });
      } else {
        logger.error("Update failed!");
        navigate("/profilescreen", { state: { activeTab: "achievement" } });
      }
    } catch (error) {
      logger.error("Failed!", error);
      navigate("/profilescreen", { state: { activeTab: "achievement" } });
    }
  }

  return (
    <motion.div
      className="outercongrats"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      {/* <Sidebar /> */}
      <div className="reactplayer">
        <ReactPlayer
          playing={playing}
          url={"audio/" + audio_url_prefix + "/congratulations.wav"}
        />
      </div>
      {/* <div className="upperCongratsMessage">
        <p></p>
      </div> */}
      <div className="columncongrats">
        {/* {((props.user.user.count + 1) > 60) ?(<img
          className="congratulationscastle"
          src={"../images/Castle_Gifs/Castle(60).gif"}
          alt="loading larger..."
          loading="lazy"
        />):(<img
          className="congratulationscastle"
          src={"../images/Castle_Gifs/Castle(" + (props.user.user.count + 1) + ").gif"}
          alt="loading..."
          loading="lazy"
        />)} */}
        <div className="blueBackgroundSubtitleCongrats">
          <p>
            {coinsEarned === 0 ? (
              "You've reached today's coin limit! Keep practicing to earn more tomorrow!"
            ) : (
              `Congratulations! You just earned ${coinsEarned} ${coinsEarned === 1 ? 'coin' : 'coins'}.`
            )}
          </p>
          <p>Keep up the great work building your wellness!</p>
        </div>

        {/* 
          From a browser/CSS perspective, `autoplay` and `muted` both need
          to be 'true' so that the mp4 can automatically play. 
          For iOS/safari, `playsinline` also needs to be true. 
        */}
        <video className="coinsFallingSlowGif" autoPlay muted playsInline>
          <source src="../images/SlowDroppingCoins.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        {/*  */}

        <div className="totalCoinsEarned">
          Your Total: {coinTotal} Wellness Coins
        </div>
        <div className="rowhelpful">
          <FontAwesomeIcon
            className="fontawesomeLightBulb"
            icon={faLightbulb}
          ></FontAwesomeIcon>
          <div className="wasthishelpful">
            Was the wellness practice <i>"{wellnessDataPoint.action}"</i>,
            helpful?
          </div>
        </div>
        <div className="rowcongrats">
          <div className="buttonHelpfulnessGroup">
            <button
              className="helpfulyes"
              onClick={() => {
                updateDatapoint(true);
                fetchCount();
              }}
            >
              Yes
            </button>
            <button
              className="helpfulno"
              onClick={() => {
                updateDatapoint(false);
                fetchCount();
              }}
            >
              No
            </button>
          </div>
          {/* <FaHeart style={{ fontSize: "1.3vw", marginTop:'1vh', marginLeft:'2vh' }}></FaHeart> */}
        </div>
      </div>
    </motion.div>
  );
};

export default Congratulations;
