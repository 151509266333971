import "./DeepBreathe.css";
import BlueSidebar from "../../components/BlueSidebar";
import React, { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import ReactPlayer from "react-player/lazy";
import { Context2 } from "../../components/Audio";
import logger from "../../utils/logger";

const DeepBreathe = (props) => {
  const [playing,setPlaying]= useContext(Context2);
  const navigate = useNavigate();
  const audio_url_prefix = props.user.user.voice?props.user.user.voice.replace(" ","_"):"english_female"
  logger.log(audio_url_prefix)
  useEffect(() => {
    const img = document.querySelector('img')
    img.src = `${img.src.replace(/\?.*$/,"")}?x=${Math.random()}`;
        const timer = setTimeout(() => {
      navigate("/howdoifeel");
    }, 8000);
    return () => {const img = document.querySelector('img')
    img.src = `${img.src.replace(/\?.*$/,"")}?x=${Math.random()}`;
    clearTimeout(timer)};
  }, []);
  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }
  useEffect(() => {
    const audioPreference = getCookie('audio');
    if (audioPreference !== undefined) {
      setPlaying(audioPreference === 'true');
    }
  }, []);

  return (
    <motion.div
      class="outerbreath1">
      <div class='reactplayer'>
      <ReactPlayer playing={playing} url={"audio/"+audio_url_prefix+"/deepbreathe.wav"} muted={!playing} />
      </div>
      {/* <BlueSidebar></BlueSidebar> */}
      <div class="outercolumnbreathe">
        <div class="text">Please take a deep breath</div>
        <img
          class="gif"
          src="../images/GIF_FirstBreath_Crop.gif"
          alt="loading..."
          loading="lazy"
        />
      </div>
    </motion.div>
  );
};

export default DeepBreathe;