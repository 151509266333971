import React from "react";
import { CircularProgress } from "@mui/material";

export const LoadingOverlayExtended = ({ containerStyle = {} }) => {
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "white",
        zIndex: 2,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        animation: "backgroundAnimation 10s infinite alternate",
        ...containerStyle
      }}
    >
      <CircularProgress
        style={{
          height: "80px",
          width: "80px",
          color: "gray",
        }}
      />
      <p
        style={{
          color: "black",
          fontWeight: "bold",
          fontSize: "18px",
          marginTop: "20px",
          fontFamily: "Arial, sans-serif",
          textAlign: "center"
        }}
      >
        Pulling your data...
      </p>
      <p
        style={{
          color: "#89CFF0",
          fontSize: "14px",
          marginTop: "10px",
          fontFamily: "Arial, sans-serif",
          textAlign: "center"
        }}
      >
        Feel free to access other tabs while we pull your data
      </p>
    </div>
  );
};
