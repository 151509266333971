import moment from "moment";
import { initializeDictionaries, initializeEmotionDict } from "../utils/dictionaries";
import {
  processSpecificClass,
  processSpecificStudent,
} from "../utils/processClasses";
import {
  processAllDistricts,
  processAllSchoolsInDistrict,
  processSpecificSchool,
} from "../utils/processSchools";
import { createDataPointProcessor } from "../utils/dataPointProcessor";
import logger from "../../../utils/logger";

export function bigFiveHandler(state, changedFilter) {
  const { currentDict, compareDict } = initializeDictionaries();
  let impactfulActions = {action:{}, emotion:{}};
  let updateState = {};
  let filterDataPoints = [];
  let studentEmotionData = {};
  const bigFiveFilteredData = [];
  let tooltipCounts = initializeEmotionDict();

  // Update the state with the changed filter
  let updatedSelectedTimes = { ...state.selectedTimes };
  let updatedSelectedSubOptions = state.selectedSubOptions;
  let updatedHelpfulFilter = state.helpfulFilter;
  //logger.log(state.selectedSubOptions)
    // logger.log(updatedSelectedTimes)
  if (changedFilter.type === "selectedTimes") {
    updatedSelectedTimes = { ...updatedSelectedTimes, ...changedFilter.value };
    updatedSelectedSubOptions = {}
  } else if (changedFilter.type === "selectedSubOptions") {
    // logger.log(changedFilter.value)
    updatedSelectedSubOptions = changedFilter.value
  } else if (changedFilter.type === "helpfulFilter") {
    updatedHelpfulFilter = changedFilter.value;
  }
  logger.log(updatedSelectedSubOptions)
  // logger.log(updatedSelectedTimes)

  let currentDate = moment().subtract(state.filterAmountCurrent);
  let compareDate = moment().subtract(
    state.filterAmountCurrent + state.filterAmountCompare
  );
  const processDataPoint = createDataPointProcessor(
    currentDate,
    compareDate,
    state.chosenEmotion,
    currentDict,
    compareDict,
    impactfulActions,
    filterDataPoints,
    studentEmotionData,
    bigFiveFilteredData,
    updatedSelectedTimes,
    updatedSelectedSubOptions,
    updatedHelpfulFilter,
    tooltipCounts,
  );

  if (state.chosenDistrict === "All Districts") {
    processAllDistricts(state,processDataPoint)
  } else if (state.chosenSchool === "All Schools") {
    processAllSchoolsInDistrict(state, processDataPoint);
  } else if (state.chosenClass === "All Classes") {
    processSpecificSchool(state, state.chosenSchool, processDataPoint);
  } else if (state.chosenStudent === "All Students") {
    processSpecificClass(state, state.chosenClass, processDataPoint);
  } else {
    processSpecificStudent(state, state.chosenStudent, processDataPoint);
  }

  // logger.log(bigFiveFilteredData);

  updateState.bigFiveFilteredData = bigFiveFilteredData.sort((a, b) => new Date(b.date) - new Date(a.date))
  updateState.selectedTimes = updatedSelectedTimes;
  updateState.selectedSubOptions = updatedSelectedSubOptions;
  updateState.helpfulFilter = updatedHelpfulFilter;
  
  return updateState;
}

export function processBigFive(
  selectedTimes,
  selectedSubOptions,
  helpfulFilter,
  bigFiveFilteredData,
  datapoint,
  currentDate,
  student
) {
  datapoint.email = student;
  let datapointTimestamp = new Date(datapoint.date).valueOf();
  if (datapointTimestamp < currentDate.valueOf()) return;
  const hour = new Date(datapoint.date).getHours();
const timeOfDay = getTimeOfDay(hour);
  const timeSlot = getTimeSlot(hour);

  const isTimeSelected = selectedTimes["All Day"] || selectedTimes[timeOfDay];
  const isSubOptionSelected =
    selectedTimes["All Day"] ||
    (selectedSubOptions[timeOfDay] && selectedSubOptions[timeOfDay][timeSlot]) ||
    (selectedTimes[timeOfDay] && !selectedSubOptions[timeOfDay]); // Add this condition;
  const isHelpfulnessMatched =
    helpfulFilter === "All" ||
    (helpfulFilter === "Helpful" && datapoint.helpful) ||
    (helpfulFilter === "Not helpful" && !datapoint.helpful);

    // logger.log("time ",isTimeSelected, "suboption ", isSubOptionSelected, "ishelpful", isHelpfulnessMatched )
  if (isTimeSelected && isSubOptionSelected && isHelpfulnessMatched) {
    bigFiveFilteredData.push(datapoint);
  }
}

function getTimeOfDay(hour) {
  if (hour >= 0 && hour < 8) return "Early Morning";
  if (hour >= 8 && hour < 12) return "Morning";
  if (hour >= 12 && hour < 18) return "Afternoon";
  return "Evening";
}

function getTimeSlot(hour) {
  return `${hour % 12 || 12}${hour < 12 ? "am" : "pm"}-${
    (hour + 1) % 12 || 12
  }${hour < 11 ? "am" : "pm"}`;
}
