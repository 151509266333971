import "./DeepBreathe.css";
import BlueSidebar from "../../components/BlueSidebar";
import React, { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import ReactPlayer from "react-player/lazy";
import { Context2 } from "../../components/Audio";
import logger from '../../utils/logger';

const DeepBreatheCopy = (props) => {
  const [playing,setPlaying]= useContext(Context2);
  const navigate = useNavigate();

  const audio_url_prefix = props.user.user.voice?props.user.user.voice.replace(" ","_"):"english_female"
  logger.log(audio_url_prefix)

  useEffect(() => {
    const img = document.querySelector('img')
    img.src = `${img.src.replace(/\?.*$/,"")}?x=${Math.random()}`;
        const timer = setTimeout(() => {
      navigate("/howdoifeel");
    }, 8000);
    return () => {const img = document.querySelector('img')
    img.src = `${img.src.replace(/\?.*$/,"")}?x=${Math.random()}`;
    clearTimeout(timer)};
  }, []);

  return (
    <motion.div
      class="outerbreath1"
      // initial={{ opacity: 0 }}
      // animate={{ opacity: 1 }}
      // exit={{ opacity: 0 }}
    >
      <div style={{ height: "0vh", width: "0vw" }}>
        <ReactPlayer playing={playing} url={"audio/"+audio_url_prefix+"/deepbreathe.wav"} />
      </div>
      {/* <BlueSidebar></BlueSidebar> */}
      <div class="outercolumnbreathe">
        <div class="text">Please take a deep breath</div>
        <img
          class="gif"
          src="../images/GIF_FirstBreath_Crop copy.gif"
          alt="loading..."
          loading="lazy"
        />
      </div>
    </motion.div>
  );
};

export default DeepBreatheCopy;
