import { Class } from "./class";
import { District } from "./district";
import { School } from "./school";
import { User } from "./user";
import { Wellness } from "./wellness";
import logger from "../utils/logger";
export const COLLECTIONS = {
  wellness: Wellness,
  classes: Class,
  districts: District,
  schools: School,
  users: User,
};

export const INDICES = {
  wellness: "wellnessIndex",
  classes: "classesIndex",
  schools: "schoolsIndex",
  users: "usersIndex",

  // TODO: change this not sure why it has this name lol
  districts: "default",
};

// lol RIP for not having TS 💀
export const isJsonObject = (variable) =>
  typeof variable === "object" && variable !== null && !Array.isArray(variable);

/* 
  TODOs:
  -- we check to make sure each item is a json object, but we can 
     also check `items` itself to also make sure it's a list of 
     json objects. this isn't super important b/c of how we query
     the arg itself but would help for clearer error handling.
  -- (eventually) extend type checks to also enforce required types.
     right now we can only prevent them from writing bad fields, but can't 
     enforce one that is necessary for the UI/UX (i.e. email)
*/
export const validateItemsForCollection = async (collection, items) => {
  // Make sure all of the items are valid.
  const collectionType = COLLECTIONS[collection];
  const validItems = [];
  for (const item of items) {
    // Make sure each item is a valid JSON object
    if (!isJsonObject(item)) {
      logger.error(
        `Could not insert ${JSON.stringify(item)}. ` +
          "`items` must be a list of JSON objects."
      );
      continue;
    }

    // Make sure the keys in each item to be inserted exist on the collection.
    const skipItem = false;
    for (const key in item) {
      if (!key in collectionType) {
        logger.error(
          `Could not insert ${JSON.stringify(item)}. ` +
            `'${key}' is not in the ${collection} collection.`
        );
        skipItem = true;
        break;
      }
    }

    if (!skipItem) {
      validItems.push(item);
    }
  }

  return validItems;
};
