// Logger utility that only logs in development and test environments
const production = true

const logger = {
  log: (...args) => {
    if (!production) {
      console.log(...args);
    }
  },
  warn: (...args) => {
    if (!production) {
      console.warn(...args);
    }
  },
  error: (...args) => {
    // We keep error logs even in production for critical issues
    console.error(...args);
  },
  info: (...args) => {
    if (!production) {
      console.info(...args);
    }
  },
  debug: (...args) => {
    if (!production) {
      console.debug(...args);
    }
  }
};

export default logger; 