export const backgroundColors = {
  green: '#4CC68D',
  blue: '#5B9BD5',
  purple: '#BD92E4',
};

export const categorizedPractices = {
  green: [
    "Move my body",
    "Smile big",
    "Stretch",
    "Be mindful. Be where my feet are",
    "Help Others",
    "Think of 3 things that I am grateful for",
    "Give someone a compliment",
    "Give someone a high five",
    "Write in my journal",
    "Create art",
  ],
  blue: [
    "Imagine positive things in the future",
    "Laugh at something funny",
    "Remember that difficult times pass",
    "Remind myself of two good things in my life",
    "Tell myself something nice",
    "Get some sun in my eyes",
  ],
  purple: [
    "Count to ten taking a deep breath each time",
    "Imagine a peaceful place",
    "Listen to music or read",
    "Politely move away from a distraction",
    "Squeeze my fist",
    "Use my growth mindset",
  ],
};