import "./NoAccountScreen.css";
import {
  GoogleAuthProvider,
  signInWithPopup,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  OAuthProvider,
  signInWithRedirect,
} from "firebase/auth";
import { authentication } from "../../firebase";
// import GoogleButton from "react-google-button";
import ButtonComponent from "../../components/ButtonComponent";
import EmailLogin from "../../components/EmailLogin";
// import EmailButton from "../../components/EmailButton";
// import MicrosoftButton from "../../components/MicrosoftButton";
import { useEffect, useState } from "react";
//import CleverButton from "../../components/CleverButton";
import GoogleImage from "../../../src/assets/images/googleLogo.png"
import MicrosoftImage from "../../../src/assets/images/microLogo.png"
import CleverImage from "../../../src/assets/images/cleverLogo.png"
import CarouselPage from "../../components/CarouselPage";
import logger from "../../utils/logger";

const NoAccountScreen = () => {
  var [emailClick, setEmailClicked] = useState(false);
  var [showEmailErr, setShowEmailErr] = useState(false);
  var [logErr, setLogErr] = useState(false);
  var [emailSent, setEmailSent] = useState(false);
  var [diffCredential, setDiffCredential] = useState(false);

  function signUserIn(email, password) {
    signInWithEmailAndPassword(authentication, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        logger.log(errorCode, ": ", errorMessage);
        if (errorCode == "auth/invalid-email") {
          setShowEmailErr(true);
        } else if (errorCode == "auth/wrong-password") {
          setLogErr(true);
        } else {
          createUser(email, password);
        }
      });
  }

  function createUser(email, password) {
    createUserWithEmailAndPassword(authentication, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        if (errorCode == "auth/invalid-email") {
          setShowEmailErr(true);
        } else {
          setLogErr(true);
        }
        // ..
      });
  }

  // for some reason this isn't working reliably
  function sendPasswordReset(email) {
    sendPasswordResetEmail(authentication, email)
      .then(function () {
        logger.log("email sent");
        setEmailSent(true);
      })
      .catch(function () {
        logger.log("failed to send email");
      });
  }

  const microProvider = new OAuthProvider("microsoft.com");
  microProvider.setCustomParameters({
    // Force re-consent.
    prompt: "select_account",
    // Target specific email with login hint.
    // login_hint: ''
  });

  function MicrosoftLogin() {
    signInWithPopup(authentication, microProvider)
      .then((result) => {
        // User is signed in.
        // IdP data available in result.additionalUserInfo.profile.

        // Get the OAuth access token and ID Token
        const credential = OAuthProvider.credentialFromResult(result);
        const accessToken = credential.accessToken;
        const idToken = credential.idToken;
        logger.log("this worked well");
      })
      .catch((error) => {
        logger.log(error.code, " is the error");

        if (error.code == "auth/account-exists-with-different-credential") {
          logger.log("this is a bust");
          setDiffCredential(true);
        }
        // Handle error.
      });
  }

  return (
    <div className="auth-container">
    <div className="row">
      <div className="container1">
        <div className="content">
          <div>
            <img className="logo-image-top" alt="logo" src="../images/123WellnessLogo green-02.png" />
          </div>
          <div className="top-text">
            <p className="text-wrapper">Welcome to the 1-2-3 Wellness™ app!</p>
          </div>
        {!emailClick ? (
          <div className="button-group">
            <button
            className="btn-parent" onClick={Signin}>
              <ButtonComponent image={GoogleImage} txt="Sign In With Google"/>
            </button>

            <button
            className="btn-parent" onClick={() => MicrosoftLogin()} >
              <ButtonComponent image={MicrosoftImage} txt="Sign In Microsoft"/>
            </button>

            <button
            className="btn-parent" onClick={() => MicrosoftLogin()}>
              <ButtonComponent image={CleverImage} txt="Sign In With Clever"/>
            </button>

            <div className="line">
              <p className="text-center login_input_field" id="line_with_or">OR</p>
            </div>

            <div className="input-group">
              <div id="input_label_div_email">
                <label className="field" htmlFor="email">Email</label>
              </div>
              <div class="input mb-3">
                  <input type="text" id="email" class="form-control login_input_field" placeholder="Email..." aria-label="Email" aria-describedby="basic-addon1"/>
              </div>
              <div class="input_label_div" id="input_label_div_email">
                <label className="field" htmlFor="password">Password</label>
              </div>
              <div class="input mb-3">
                  <input type="password" id="password" class="form-control" placeholder="Password..." aria-label="Password" aria-describedby="basic-addon2"/>
              </div>
            </div>
            
            <button className="btn btn-primary">
              LOG IN
            </button>

          </div>
        ) : (
          <EmailLogin
            hideLogin={setEmailClicked}
            authUser={signUserIn}
            passwordReset={sendPasswordReset}
            emailErr={showEmailErr}
            handleEmailErr={setShowEmailErr}
            isErr={logErr}
            handleErr={setLogErr}
            emailSuccess={emailSent}
            handleEmailSuccess={setEmailSent}
          />
        )}
        </div>

        <div className="bottom-text">
          <p>© Growthwell LLC 2025</p>
          <p class="footer-links">
            <a href="https://drewschwartz.com/privacy-policy" className="bottom-text"
                target="_blank">Privacy Policy
            </a> 
                {/* <div id ="separator_line_box" class="box"> */}
                  <img id="separator_line" class="line" src="../images/line_9.png" />
                {/* </div> */}
            <a  href="https://drewschwartz.com/terms-of-service"
              className="bottom-text " target="_blank">Terms of Service
            </a>            
          </p>
        </div>
      </div>

      <div className="container2">
        <CarouselPage/>
      </div>


    </div>
    </div>
  );
};

export const Signin = () => {
  const provider = new GoogleAuthProvider();
  provider.setCustomParameters({
    //   // Force re-consent.
    prompt: "select_account",
    //   // Target specific email with login hint.
    //   // login_hint: ''
  });
  signInWithPopup(authentication, provider)
    .then((result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      // logger.log("logged in" + token);
      // The signed-in user info.
      // const user = result.user
    })
    .catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      logger.log("error " + errorCode + " " + errorMessage);
      // The email of the user's account used.
      // const email = error.customData.email;
      // The AuthCredential type that was used.
      // const credential = GoogleAuthProvider.credentialFromError(error);
      // ...
    });
};

export default NoAccountScreen;
