import "./Actions.css";
import "./PerformAction.css";
import BlueSidebar from "../../components/BlueSidebar";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import Store, { Context } from "../../components/Store";
import { useContext, useEffect, useState } from "react";
import {Box, LinearProgress } from "@mui/material";
import { Context3 } from "../../components/Timer";
import { backgroundColors, categorizedPractices } from "../StudentData/utils/practicesConfig";

const PerformAction = (props) => {
  const navigate = useNavigate();
  const [wellnessDataPoint] = useContext(Context);
  const [progress, setProgress] = useState(2);
  const time = props.user.user.wellnesstimer;

  const imageSettingFilenameModifier = props.user.user.adultActionImages
    ? "AdultCircle_BIG_FONT"
    : "TeenCircle_BIG_FONT";

  const getBackgroundColor = (action) => {
    if (categorizedPractices.green.includes(action)) return backgroundColors.green;
    if (categorizedPractices.blue.includes(action)) return backgroundColors.blue;
    if (categorizedPractices.purple.includes(action)) return backgroundColors.purple;
    return "#4CC68D";
  };

  useEffect(() => {
    var timerlength = 0;
    if (time === "1 minute") {
      timerlength = 60000;
    } else if (time === "2 minutes") {
      timerlength = 120000;
    } else if (time === "30 seconds") {
      timerlength = 30000;
    }else if (time === "20 seconds") {
      timerlength = 20000;
    }else if (time === "10 seconds") {
      timerlength = 10000;
    }
    const progresstimer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 100 : prevProgress + 1
      );
    }, timerlength/100);
    const timer = setTimeout(() => {
      navigate("/congratulations");
    }, timerlength);

    return () => {
      clearTimeout(timer);
      clearInterval(progresstimer);
    };
  }, [time, navigate]);

  const isOther = wellnessDataPoint.action.toLowerCase().startsWith("other");
  const actionText = isOther
    ? "Other:"
    : wellnessDataPoint.action;
  const otherText = wellnessDataPoint.action.substring(6);
  const enlargedPractices = {
    "Help Others": "70%",
    "Imagine a peaceful place": "68%",
    "Imagine positive things in the future" : "65%",
    "Squeeze my fist" : "68%",
    "Give someone a high five": "68%",
    "Write in my journal": "68%",
    "Move my body": "68%",
    "Use my growth mindset": "65%",
    "Give someone a compliment": "68%",
    "Stretch": "65%",
    "Create art": "68%",
    "Be mindful. Be where my feet are": "59%",
    "Count to ten taking a deep breath each time": "52%",
  };
      
  const imageSize = Object.keys(enlargedPractices).includes(wellnessDataPoint.action) ? enlargedPractices[wellnessDataPoint.action] : '55%';

  return (
    <motion.div
      class="outerperformaction"
      // initial={{ opacity: 0 }}
      // animate={{ opacity: 1 }}
      // exit={{ opacity: 0 }}
    >
      {/* <div class='reactplayer'>
        <ReactPlayer playing={playing} url={"audio/"+audio_url_prefix+"/actions.wav"} />
      </div> */}
      <div class="classandactions">
        <div class="practicewellnesstext">
          Please take {time} to practice wellness{" "}
        </div>
        {isOther && (
          <div className="altwellness">
            You chose your own wellness practice: {otherText}
          </div>
        )}
        <div
          className="action-circle"
          style={{
            backgroundColor: getBackgroundColor(wellnessDataPoint.action),
          }}
        >
          <img
            className="perform-circle-image"
            style={{ maxWidth: "auto", maxHeight: imageSize }}
            src={
              isOther
                ? "../images/actions/Other_BIG_FONT.png"
                : `../images/actions/${wellnessDataPoint.action} ${imageSettingFilenameModifier}.png`
            }
            alt="loading..."
          />
          <div className="perform-circle-text">{actionText}</div>
        </div>
        <Box className="linearprogresspractice">
          <LinearProgress variant="determinate" value={progress} />
        </Box>
      </div>
    </motion.div>
  );
};

export default PerformAction;
