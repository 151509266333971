import React, { useState } from "react";
import moment from "moment";
import {
  NoData,
  NoPracticesFound,
} from "../screens/StudentData/components/AlertComponents";
import { timeHelper } from "../screens/StudentData/utils/timeHelper";
import "../components/MyWellnessSuccessTab.css";
import PracticeCircle from "./PracticeCircle";
import { categorizedPractices,backgroundColors } from "../screens/StudentData/utils/practicesConfig";

const WellnessPracticeHelpfulnessDisplay = ({
  studentData = [],
  selectedEmotion,
  selectedTimeframe,
  selectedEmotionColor,
  adultActionImages,
}) => {
  const [hoveredItem, setHoveredItem] = useState(null);
  const isMobile = window.innerWidth <= 600;
  const circleSize = isMobile ? '15vh' : '9.5vw';
  const fontSize = isMobile ? '1.12rem' : '1.4rem'

  // const filterStudentDataByTimeframeEmotionHelpfulness = (studentData) => {
  //     const filteredStudentData = [];
  //     const now = moment();

  //     // logger.log(studentData)
  //     if (!studentData) return filteredStudentData;
  //     studentData.forEach(studentDataPoint => {
  //         const studentDate = moment(studentDataPoint.date);
  //         if ((studentDataPoint.emotion === selectedEmotion || selectedEmotion === "all") && studentDate.isAfter(now.subtract(timeHelper(selectedTimeframe)))
  //         && studentDataPoint.helpful === true) {
  //             filteredStudentData.push(studentDataPoint);
  //         }
  //     });

  //     return filteredStudentData;
  // }

  // var filteredStudentData = filterStudentDataByTimeframeEmotionHelpfulness(studentData, selectedTimeframe)

  const practiceHelpfulnessCounts = (studentData || []).reduce((counts, dataPoint) => {
    if (!counts[dataPoint.action]) {
      counts[dataPoint.action] = 0;
    }
    counts[dataPoint.action]++;
    return counts;
  }, {});

  const sortedPractices = Object.entries(practiceHelpfulnessCounts).sort(
    ([, countA], [, countB]) => countB - countA
  );

  var noPracticesMessageVisibility =
    sortedPractices.length < 1 ? true : false;
  var imageSettingFilenameModifier = null;
  if (adultActionImages) {
    imageSettingFilenameModifier = "AdultCircle_BIG_FONT";
  } else {
    imageSettingFilenameModifier = "TeenCircle_BIG_FONT";
  }
  const getBackgroundColor = (practice) => {
    if (practice.toLowerCase().startsWith("other")) {
      return backgroundColors.green;
    }

    for (const [color, practices] of Object.entries(categorizedPractices)) {
      if (practices.includes(practice)) {
        return backgroundColors[color];
      }
    }
    return selectedEmotion;
  };

  return (
    <div
      className="practice-images-grid"
      style={{ backgroundColor: selectedEmotionColor }}
    >
      <div className="practice-images-container-my-wellness">
        {noPracticesMessageVisibility && (
          <div className="no-practices-message">
            <NoPracticesFound />
          </div>
        )}
        {sortedPractices.map(([practice, count]) => {
          const isOther = practice.toLowerCase().startsWith("other");
          const imageSrc = isOther
            ? "../images/actions/Other_BIG_FONT.png"
            : `../images/actions/${practice} ${imageSettingFilenameModifier}.png`;

          return (
            <PracticeCircle
              key={practice}
              imageSrc={imageSrc}
              size={circleSize}
              fontSize={fontSize}
              backgroundColor={getBackgroundColor(practice)}
              text={isOther ? "Other:" : practice}
              countText={`${count} ${count > 1 ? "times" : "time"}`}
              hoverText={practice}
              onHover={() => setHoveredItem(practice)}
              onLeave={() => setHoveredItem(null)}
            />
          );
        })}
      </div>
    </div>
  );
};

export default WellnessPracticeHelpfulnessDisplay;
