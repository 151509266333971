import { initEmotionDictionary } from "./utils/util";
import {
  loadUserDistrict,
  loadAllStudents,
  loadSchoolsInfo,
} from "./utils/dataFetchers";
import { getClassAssociationsForStudents2, getClassesByCleverId, loadAllClassesFromSchool, loadClasses } from "../../realm/graphqlQueries";
import { createStudentMaps } from "./utils/dataProcessors";
import logger from "../../utils/logger";

export async function getSuperadminData(state, realmUser, user, anonamizeData) {
  try {
    const dataStart = performance.now();
    const isSuperAdmin = user.user.user === "superadmin";
    const isCleverUser = user.user.cleverId !== null;

    // Load initial data in parallel
    const userDistrict = await loadUserDistrict(user, realmUser);
    const allStudents = await loadAllStudents(user, userDistrict, realmUser)

    const allStudentIDs = allStudents.map(student => student._id);
    if (allStudentIDs.length === 0) {
      return createEmptyResponse(userDistrict);
    }
    // Get wellness data and other info in parallel
    const collection = realmUser.mongoClient("mongodb-atlas").db("123wellness").collection("wellness");
    const [
      { allWellnesses, rawStudentData },
      schoolsInfo,
      { emailToName: studentEmailToNameMap }
    ] = await Promise.all([
      processAllWellnessData(allStudentIDs, collection),
      loadSchoolsInfo(user, userDistrict, realmUser),
      Promise.resolve(createStudentMaps(allStudents, anonamizeData))
    ]);
    logger.log("allWellnesses")
    logger.log(allWellnesses)

    logger.log("loadSchoolsInfo")
    logger.log(schoolsInfo)

    // TODO SCHOOLSINFO SHOULD ONLY BE FOR THE SCHOOLS THE USER HAS ACCESS TO
    // Get class associations
    let studentsClasses = {};
    if (isCleverUser || user.user.user==="superadmin") {
      studentsClasses = await getClassesByCleverId(
        userDistrict.district,
        schoolsInfo,
        allStudents,
        realmUser,
        user
      );
    } else {
      let classRef;
      let districts = {};
      
      if (user.user.user === "admin") {
        classRef = await loadAllClassesFromSchool(user.user.school, realmUser);
        logger.log(classRef.classes);
        districts[userDistrict.district.name] = {};
        
        classRef.classes.forEach((classData) => {
          if (classData && classData.name) {
            const districtName = userDistrict.district.name;
            const schoolName = user.user.school;
        
            // Ensure the district and school objects exist
            if (!districts[districtName]) {
              districts[districtName] = {};
            }
            if (!districts[districtName][schoolName]) {
              districts[districtName][schoolName] = {};
            }
        
            // Add the class data to the districts structure
            if (!districts[districtName][schoolName][classData.name]) {
              districts[districtName][schoolName][classData.name] = classData.students;
            }
          }
        });
        
        studentsClasses = districts[userDistrict.district.name][user.user.school] || {};
      } else if (user.user.user === "teacher") {
        classRef = await loadClasses(
          user.user.school,
          user.user.class,
          realmUser
        );
        districts[userDistrict.district.name] = {
          [user.user.school]: {},
        };
        if (classRef && classRef.classes) {
          classRef.classes.forEach((classData) => {
            if (classData && classData.name) {
              districts[userDistrict.district.name][user.user.school][classData.name] = classData.students;
            }
          });
          studentsClasses = districts[userDistrict.district.name][user.user.school] || {};
        }
      }
      studentsClasses = districts[userDistrict.district.name];
      logger.log(districts)
      logger.log(classRef)
    }
    
    // logger.log("studentsClasses")
    // logger.log(studentsClasses)

    // Process student data
    const studentData = processStudentData(rawStudentData, allStudents);
    logger.log("studentData")
    logger.log(studentData)

    const requestDuration = performance.now() - dataStart;
    logger.log('Request Duration:', formatDuration(requestDuration));
    logger.log('Data Points Count:', {
      wellnesses: allWellnesses.length,
      students: allStudents.length,
      role: user.user.user
    });

    // Get school name from schoolsInfo if user is admin
    let schoolName = "Choose School"
    if (!isSuperAdmin && Object.keys(studentsClasses).length > 0) {
      schoolName = Object.keys(studentsClasses)[0]
    }
    // Structure districts data based on role
    const districtsData = isSuperAdmin 
      ? { [userDistrict.district.name]: studentsClasses }
      : { [userDistrict.district.name]: studentsClasses || {}  };

      logger.log(districtsData)
    return {
      studentEmailToNameMap,
      districts: districtsData,
      allWellnessPoints: allWellnesses,
      allStudents,
      studentData,
      currentDictionary: initEmotionDictionary(),
      compareDictionary: initEmotionDictionary(),
      loading: false,
      counter: 0,
      chosenDistrict: userDistrict.district.name,
      chosenSchool: schoolName
    };
  } catch (error) {
    logger.error("Error in getSuperadminData:", error);
    throw error;
  }
}

// Helper function to format time duration
const formatDuration = (ms) => {
  if (ms < 1000) return `${Math.round(ms)}ms`;
  return `${(ms / 1000).toFixed(2)}s`;
};

// Process a chunk of student IDs to get their wellness data
async function processWellnessChunk(chunk, collection) {
  const pipeline = [
    {
      $match: { userID: { $in: chunk } }
    },
    {
      $project: {
        userID: 1,
        date: 1,
        emotion: 1,
        intensity: 1,
        practice: 1,
        helpful: 1,
        action: 1,
        studentId: 1,
        _id: 1,
        category: 1,
        subcategory: 1,
        description: 1,
        timeOfDay: 1
      }
    },
    {
      $sort: { date: -1 }
    },
    {
      $group: {
        _id: "$userID",
        records: { $push: "$$ROOT" }
      }
    }
  ];

  const results = await collection.aggregate(pipeline);
  
  const wellnesses = [];
  const studentDataChunk = {};
  
  for (const result of results) {
    const userID = result._id;
    const records = result.records.map(record => formatWellnessRecord(record, userID));
    wellnesses.push(...records);
    if (records.length > 0) {
      studentDataChunk[userID] = records;
    }
  }

  return { wellnesses, studentDataChunk };
}

// Format a single wellness record with default values
function formatWellnessRecord(record, userID) {
  return {
    ...record,
    emotion: record.emotion || '',
    intensity: record.intensity || 0,
    practice: record.practice || '',
    helpful: record.helpful || false,
    action: record.action || '',
    category: record.category || '',
    subcategory: record.subcategory || '',
    description: record.description || '',
    timeOfDay: record.timeOfDay || '',
    studentId: record.studentId || userID,
    date: new Date(record.date)
  };
}

// Process all wellness data in parallel chunks
async function processAllWellnessData(allStudentIDs, collection) {
  const chunkSize = 1000;
  const chunks = [];
  for (let i = 0; i < allStudentIDs.length; i += chunkSize) {
    chunks.push(allStudentIDs.slice(i, i + chunkSize));
  }

  const concurrencyLimit = 4;
  const allWellnesses = [];
  const rawStudentData = {};

  for (let i = 0; i < chunks.length; i += concurrencyLimit) {
    const chunkBatch = chunks.slice(i, i + concurrencyLimit);
    const batchResults = await Promise.all(
      chunkBatch.map(chunk => processWellnessChunk(chunk, collection))
    );
    
    batchResults.forEach(result => {
      allWellnesses.push(...result.wellnesses);
      Object.assign(rawStudentData, result.studentDataChunk);
    });
  }

  return { allWellnesses, rawStudentData };
}

// Convert raw student data to email-based format
function processStudentData(rawStudentData, allStudents) {
  const studentData = {};
  Object.entries(rawStudentData).forEach(([userID, data]) => {
    const email = allStudents.find(s => s._id === userID)?.email;
    if (email) {
      studentData[email] = data;
    }
  });
  return studentData;
}

function createEmptyResponse(userDistrict) {
  return {
    studentEmailToNameMap: {},
    districts: { [userDistrict.district.name]: {} },
    allWellnessPoints: [],
    allStudents: [],
    studentData: {},
    currentDictionary: initEmotionDictionary(),
    compareDictionary: initEmotionDictionary(),
    loading: false,
    counter: 0,
    chosenDistrict: userDistrict.district.name,
  };
}