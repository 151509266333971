import React from "react";
import { useState } from "react";
import logger from "../../utils/logger";
const WellnessBadges = (props) => {
  const [selectedBadge, setSelectedBadge] = useState("none");

  const BadgesDisplayElement = (props) => {
    const badgesList = [
      "Active",
      "Courage",
      "Creative",
      "Determined",
      "Explorer",
      "Kindness",
      "Learner",
      "Positive",
      "Strengths",
      "Thankful",
    ];

    const badgesDescriptionMapping = {
      Active:
        "Earn this badge by choosing an active wellness practice like stretching or moving your body 20 times",
      Creative:
        "Earn this badge for choosing “other” as your wellness practice 20 times and creating wellness in your own way",
      Courage:
        "Earn this badge for having the courage to feel 5 different emotions in a month. After all, we all experience different emotions.",
      Determined:
        "Earn this badge for feeling your emotions 50 times in a month (practicing 50 times in a month)",
      Explorer:
        "Earn this badge for trying 5 different wellness practices in a month",
      Kindness:
        "Earn this badge by choosing a wellness practice that is spreads kindness to others 10 times (Examples: Help others, give someone a compliment)",
      Learner:
        "Earn this badge by practicing a thoughtful wellness practice 15 times \
                                                (Examples: Write in my journal, Use my growth mindset, Remember that difficult times pass and I’m not alone, Remind myself of two good things)",
      Positive:
        "Earn this badge by choosing wellness practices that promote positivity with yourself 20 times(Examples: Smile, Imagine a peaceful place, Imagine positie things in the future, Rememver that difficult times pass and I'm not faArrowUpLong, Remind myself of two good things in my FaLifeRing, Tell myself something nice).",
      Strengths:
        "Earn this badge for choosing the same wellness practice 20 times. This shows that you are learning what wellness practices help make you strong.",
      Thankful:
        "Earn this badge for practicing being grateful as your wellness practice 10 times",
    };

    const toggleDescriptionShow = (badge) => {
      if (selectedBadge != badge) {
        logger.log(badge, badgesDescriptionMapping[badge]);
        setSelectedBadge(badge);
        document.getElementsByClassName(
          "toggleableBadgeDescription"
        )[0].innerHTML = `<strong>${badge}:</strong> &nbsp;${badgesDescriptionMapping[badge]}`;
      } else {
        setSelectedBadge("none");
      }
    };

    return (
      <div className="badgesDisplay">
        {badgesList.map((badge, index) => (
          <div key={index} className="badgeDisplayItem">
            <img
              key={index}
              src={`../images/badges/${
                props.badges && badge in props.badges && props.badges > 0
                  ? "color"
                  : "grey"
              }/${badge}Badge${
                props.badges && badge in props.badges && props.badges > 0
                  ? "Color"
                  : "Grey"
              }.png`}
              alt={badge}
              className="badgeImageWellnessBadges"
              onClick={() => toggleDescriptionShow(badge)}
            />
            <p className="badgeItemLabel">{badge} Badge</p>
          </div>
        ))}
      </div>
    );
  };

  const MaxBadge = (props) => {
    if (props.badges) {
      for (let badge in props.badges) {
        if (props.badges[badge] > 0) {
          let badgeFileName = `${badge.charAt(0).toUpperCase()}BadgeColor.png`;
          return (
            <img
              src={`../images/badges/color/${badgeFileName}`}
              className="myAchievementsSideIcon"
            />
          );
        }
      }
    }
    return (
      <img
        src="../images/badges/color/BlankBadgeTrophyColor.png"
        className="myAchievementsSideIcon"
      ></img>
    );
  };

  return (
    <div className="achievementSectionContainer">
      <MaxBadge badges={props.badges}></MaxBadge>
      <div className="achievementSectionContainerRightSide">
        <h2 className="achievementheaders">BADGES</h2>
        <p
          className={`toggleableBadgeDescription ${
            selectedBadge != "none" ? "selected" : "notSelected"
          }`}
        ></p>
        <p className="achievementExplanationP">
          You can earn different types of badges when you practice 1-2-3
          Wellness:
        </p>
        <BadgesDisplayElement badges={props.badges}></BadgesDisplayElement>
      </div>
    </div>
  );
};

export default WellnessBadges;
