import "./StudentsScreen.css";
import { UserContext } from "../../../realm/user.context";
import ReactDataSheet from "react-datasheet";
import React, { useState, useContext, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import "./toast-overwrites.css";
import {
  fetchClassesWithName,
  insertClass,
  insertProfiles,
  updateClassByID,
  updateProfile,
  addToClassAndProvision,
  updateSchool,
  loadProfile,
} from "../../../realm/graphqlQueries";
import Spacer from "../../../components/Spacer";
import { LoadingOverlay } from "../../../components/LoadingOverlay";
import _ from 'lodash';
import UserGrid from "./UserGrid";
import { clearExistingProfiles } from '../utils/utils';
import logger from "../../../utils/logger";

const CreateClassScreen = ({
  school,
  onMobile,
  students,
  teachers,
  setPerson,
  className,
  setTeachers,
  setStudents,
  allStudents,
  allTeachers,
  refreshData,
  editingClass,
  setClassName,
  creatingClass,
  isCleverSchool,
  setEditingClass,
  setCreatingClass,
  role
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [hoveringSubmit, setHoveringSubmit] = useState(false);
  const [studentGrid, setStudentGrid] = useState(
    []
  );
  const [teacherGrid, setTeacherGrid] = useState(
    []
  );

  const [typingStudents, setTypingStudents] = useState(false);
  const [typingTeachers, setTypingTeachers] = useState(false);

  const [typedStudents, setTypedStudents] = useState("");
  const [typedTeachers, setTypedTeachers] = useState("");

  const { realmUser } = useContext(UserContext);
  const isCleverClass = editingClass && editingClass.cleverId ? true : false;
  const generateEmptyRows = (count) => Array.from({ length: count }, () => ({ email: "" }));

  useEffect(() => {
    setStudentGrid(generateEmptyRows(10));
    if (role === "teacher") {
      setTeacherGrid([{ email: realmUser.profile.email }]);
    } else {
      setTeacherGrid(generateEmptyRows(2));
    }
  }, []);

  // let enteredTeachers = teacherGrid.slice(1).filter((d) => d[1].value !== "");
  // let enteredStudents = studentGrid.slice(1).filter((d) => d[1].value !== "");

  let canSubmit =
    className !== "";
  if (typingStudents) {
    canSubmit = typedStudents !== "";
  }
  if (typingTeachers) {
    canSubmit = typedTeachers !== "";
  }

  if (!school) {
    return <></>;
  }

  if (submitting) {
    return <LoadingOverlay></LoadingOverlay>;
  }

  return (
    <div
      onClick={() => {
        if (editingClass) {
          setStudents([]);
          setTeachers([]);
          setClassName("");
          setEditingClass(undefined);
        } else {
          setCreatingClass(false);
        }
      }}
      className={`create-class-container ${
        creatingClass || editingClass ? 'active' : ''
      }`}
    >
      <div
        // prevents clickout from happening
        onClick={(e) => e.stopPropagation()}
        className={`class-container ${
          creatingClass || editingClass ? 'active editing-or-creating' : ''
        } ${onMobile ? 'on-mobile' : 'desktop'} ${
          isCleverClass ? 'clever' : ''
        }`}
      >
        {isCleverClass && (
          <>
            <p className="edit-class-name-clever" >Edit Class Name</p>
            <Spacer height="1vmin" />
            <input
              type="text"
              value={className}
              onChange={(e) => setClassName(e.target.value)}
              className="class-name-input-clever"
            />
            <Spacer height="1vmin" />
            <div
              onMouseEnter={() => setHoveringSubmit(true)}
              onMouseLeave={() => setHoveringSubmit(false)}
              onClick={async () => {
                if (canSubmit && !submitting) {
                  setSubmitting(true);
                  try {
                    const result = await fetchClassesWithName(
                      school.cleverId ?? school._id,
                      className,
                      realmUser
                    );

                    if (!result) {
                      if (editingClass) {
                        await updateClassByID(
                          editingClass._id,
                          editingClass.name,
                          realmUser,
                          {
                            name: className,
                            students: students.map((s) =>
                              isCleverSchool ? s.cleverId : s.email
                            ),
                            teachers: teachers.map((t) =>
                              isCleverSchool ? t.cleverId : t.email
                            ),
                          }
                        );
                      } else {
                        await insertClass(
                          {
                            name: className,
                            district: school.district,
                            students: students.map((s) =>
                              isCleverSchool ? s.cleverId : s.email
                            ),
                            teachers: teachers.map((t) =>
                              isCleverSchool ? t.cleverId : t.email
                            ),
                            school: isCleverSchool
                              ? school.cleverId
                              : school.name,
                          },
                          realmUser
                        );
                      }
                      await refreshData();
                      setEditingClass(false);
                      setCreatingClass(false);
                    }
                  } catch (e) {
                    logger.error(e);
                  } finally {
                    setClassName("");
                    setSubmitting(false);
                  }
                }
              }}
              className={`submit-button ${canSubmit ? 'can-submit' : ''} ${
                hoveringSubmit && canSubmit ? 'hovering-submit' : ''
              }`}
            >
              Submit
            </div>
          </>
        )}
        {(creatingClass || editingClass) && !isCleverClass && (
          <>
            <p className="edit-class-name">
              {editingClass ? "Edit Class" : "Create New Class"}
            </p>
            {isCleverSchool && (
              <p className="not-synced-warning">
                This class will not be synced with Clever.
              </p>
            )}
            <Spacer height="2vmin" />
            <div className="classname-container">
              <p className="class-name-label"  >
                Class Name
              </p>
              <Spacer height="1vmin" />
              <div style={{ height: "100%", width: "2.5vmax" }} />
              <input
                type="text"
                value={className}
                onChange={(e) => setClassName(e.target.value)}
                className="class-name-input"
              />
            </div>
            <Spacer height="2vmin" />
            <p className="add-user-title">
              Add Teachers
            </p>
            <Spacer height="1vmin" />
            <UserGrid initialData={teacherGrid} maxHeight={120} onDataChange={(updatedData) => setTeacherGrid(updatedData)}/>
            {/* <Spacer height="2vmin" /> */}

            <p className="add-user-title">
              Add Students
            </p>
            <Spacer height="1vmin" />
            <div className="createnewclasswidth">
              <UserGrid initialData={studentGrid} maxHeight={200} onDataChange={(updatedData) => setStudentGrid(updatedData)}/>
            </div>

            <div
              onMouseEnter={() => setHoveringSubmit(true)}
              onMouseLeave={() => setHoveringSubmit(false)}
              onClick={async () => {
                if (canSubmit && !submitting) {
                  setSubmitting(true);
                  try {
                    const result = await fetchClassesWithName(
                      school.cleverId ?? school._id,
                      className,
                      realmUser
                    );

                    if (!result) {
                      const studentData = studentGrid.map((row) => row.email.trim());
                      const teacherData = teacherGrid.map((row) => row.email.trim());
                      if (editingClass) {
                        await updateClassByID(
                          editingClass._id,
                          editingClass.name,
                          realmUser,
                          {
                            name: className,
                            students: studentData,
                            teachers: teacherData,
                          }
                        );
                      } else {
                        const students = Array.from(
                          new Set(
                            studentGrid
                              .map((item) => item.email)
                              .filter((i) => i !== "")
                          )
                        );
                        
                        const teachers = Array.from(
                          new Set(
                            teacherGrid
                              .map((item) => item.email)
                              .filter((i) => i !== "")
                          )
                        );
                        for (const student of students) {
                          await clearExistingProfiles(student, realmUser, "student", school._id);
                        }
              
                        for (const teacher of teachers) {
                          await clearExistingProfiles(teacher, realmUser, "teacher", school._id);
                        }
                        await insertClass(
                          {
                            students,
                            teachers,
                            name: className,
                            district: school.district,
                            school: isCleverSchool
                              ? school.cleverId
                              : school.name,
                          },
                          realmUser
                        );

                        let { classes } = school;
                        classes = Array.from(
                          new Set(classes.concat(className))
                        );
                        await updateSchool(
                          school.name,
                          school.district,
                          realmUser,
                          { classes }
                        );

                        for (const student of students) {
                          const profile = await loadProfile(student, realmUser);
                          if (profile.user !== null) {
                            await updateProfile(student, realmUser, {
                              class: profile.user.class.concat([className]),
                              district: school.district,
                              school: school.name,
                              schools: isCleverSchool
                                ? [school.cleverId]
                                : [school.name],
                              user: "student",
                            });
                          } else {
                            await addToClassAndProvision(
                              student,
                              {
                                students,
                                teachers,
                                name: className,
                                district: school.district,
                                school: school.name,
                                schools: isCleverSchool
                                  ? [school.cleverId]
                                  : [school.name],
                              },
                              undefined,
                              realmUser,
                              false
                            );
                          }
                        }
                        for (const teacher of teachers) {
                          const profile = await loadProfile(teacher, realmUser);
                          if (profile.user === null) {
                            insertProfiles(
                              [
                                {
                                  count: 0,
                                  dataUse: false,
                                  email: teacher,
                                  user: "teacher",
                                  agreedToTerms: false,
                                  class: [className],
                                  school: school.name,
                                  schools: isCleverSchool
                                    ? [school.cleverId]
                                    : [school.name],
                                  wellnesstimer: "30 seconds",
                                  district: school.district,
                                },
                              ],
                              realmUser
                            );
                          } else {
                            await updateProfile(teacher, realmUser, {
                              class: profile.user.class.concat([className]),
                              user: "teacher",
                              district: school.district,
                              school: school.name,
                              schools: isCleverSchool
                                ? [school.cleverId]
                                : [school.name],
                            });
                          }
                        }
                        await updateSchool(
                          school.name,
                          school.district,
                          realmUser,
                          {
                            classes: school.classes.concat([className]),
                          }
                        );
                      }
                      await refreshData();
                      setEditingClass(false);
                      setCreatingClass(false);
                    }
                  } catch (e) {
                    logger.error(e);
                  } finally {
                    setClassName("");
                    setSubmitting(false);
                  }
                }
              }}
              className={`submit-button ${canSubmit ? 'can-submit' : ''} ${
                hoveringSubmit && canSubmit ? 'hovering-submit' : ''
              }`}
            >
              Submit
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CreateClassScreen;