import React, { useState, useEffect } from 'react';
import { ReactTabulator } from 'react-tabulator';
import 'tabulator-tables/dist/css/tabulator.min.css';
import './EmailNameTable.css';
import { toast } from "react-toastify";
import logger from '../../../utils/logger';
const UserGrid = ({ initialData, onSaveChanges, onDataChange, maxHeight, inputType, cleverButtonColor }) => {
  const [tableData, setTableData] = useState(initialData || []);
  const [isTableSelected, setIsTableSelected] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [contextMenuPosition, setContextMenuPosition] = useState(null);

  const defineInputType = (inputType=null, cleverButtonColor=null) => {
    if (inputType == null) {
      if (cleverButtonColor) {
        return <button className="delete-button clever-button-color" onClick={handleDeleteSelected}>Delete Selected</button>
      }
      else {
        return <button className="delete-button" onClick={handleDeleteSelected}>Delete Selected</button>
      }
    } else {
      if (inputType) {
        if (cleverButtonColor) { 
          return <button className="delete-button clever-button-color" onClick={handleDeleteSelected}>Delete Selected</button>
        } else {
          return <button className="delete-button" onClick={handleDeleteSelected}>Delete Selected</button> 
        }
      }else{
          return <div></div>
      }
    }
  };

  useEffect(() => {
    if (initialData) { setTableData([...initialData, ...Array(10).fill({ email: "" })]); }
  }, [initialData]);

  const toggleRowSelection = (email) => {
    setSelectedRows((prev) => {
      if (prev.includes(email)) {
        return prev.filter((row) => row !== email);
      } else {
        return [...prev, email];
      }
    });
  };

  const columns = [
    {
      titleFormatter: () => '<input type="checkbox" id="select-all-emails" />',
      headerClick: (e, column) => {
        const isChecked = e.target.checked;
    
        const validEmails = tableData
          .map((row) => row.email)
          .filter((email) => email);
    
        if (isChecked) {
          setSelectedRows(validEmails);
        } else {
          setSelectedRows([]);
        }
    
        column.getTable().getRows().forEach((row) => {
          const rowData = row.getData();
          if (validEmails.includes(rowData.email)) {
            const rowCheckbox = row.getCell(column).getElement().querySelector('input[type="checkbox"]');
            if (rowCheckbox) rowCheckbox.checked = isChecked;
          }
        });
      },
      formatter: (cell) => {
        const row = cell.getRow().getData();
        const isChecked = selectedRows.includes(row.email);
        return `<input type="checkbox" ${isChecked ? "checked" : ""} />`;
      },
      cellClick: (e, cell) => {
        const row = cell.getRow().getData();
        const rowCheckbox = cell.getElement().querySelector('input[type="checkbox"]');
        if (rowCheckbox === e.target) {
          toggleRowSelection(row.email);
        }
      },
      headerSort: false,
      hozAlign: "center",
      headerHozAlign: "center",
    },
    {
      formatter: "rownum",
      headerSort: false,
      hozAlign: "center",
      headerHozAlign: "center",
      minWidth: 75,
      // variableWidth: true, 
    },    
    {
      title: "Email",
      field: "email",
      editor: "input",
      editable: inputType,
      headerSort: false,
      hozAlign: "center",
      headerHozAlign: "center",
    },
  ];

  const options = {
    layout: "fitDataStretch",
    responsiveLayout: "collapse",
    autoResize:false,
    maxHeight: maxHeight || 250,
    editTriggerEvent: "dblclick",
    resizableColumns: false,
  };
  
  const handleDataChanged = (newData) => {
    const emailSet = new Set();
    const uniqueMails = [];
  
    for (const row of newData) {
      const email = row.email.trim();
      if (email === "") continue;
  
      const emailRegex = /^[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
      if (!emailRegex.test(email)) {
        toast.error(`"${email}" is not a valid email address. Please enter a valid email.`, { closeOnClick: true, autoClose: 25000 });
        continue;
      }

      if (emailSet.has(email)) {
        toast.error(`The email "${email}" is duplicated. Please enter unique emails.`, { closeOnClick: true, autoClose: 25000 });
        continue;
      }

      emailSet.add(email);
      uniqueMails.push({ email });
    }

    while (uniqueMails.length < tableData.length) {
      uniqueMails.push({ email: "" });
    }
  
    setTableData(uniqueMails);
    if (onDataChange) onDataChange(uniqueMails);
    if (onSaveChanges) onSaveChanges(uniqueMails);
  };

  const handleDeleteSelected = () => {
    if (selectedRows.length === 0) {
      toast.warn("No rows selected to delete", { closeOnClick: true, autoClose: 25000 });
      return;
    }

    const remainingData = tableData.filter((row) => !selectedRows.includes(row.email));
    setTableData(remainingData); 
    setSelectedRows([]); 
    onSaveChanges?.(
      remainingData
    );
  };

  const handlePaste = async () => {
    try {
      const clipboardText = await navigator.clipboard.readText();
  
      if (!clipboardText) {
        toast.warn("Clipboard is empty!", { closeOnClick: true, autoClose: 25000 });
        return;
      }
  
      const rows = clipboardText
        .split("\n")
        .filter((email) => email.trim() !== "")
        .map((email) => ({ email: email.trim() }));
  
      if (rows.length === 0) {
        toast.warn("No valid rows to paste!", { closeOnClick: true, autoClose: 25000 });
        return;
      }
  
      const combinedData = [
        ...tableData.filter((row) => row.email.trim() !== ""),
        ...rows,
      ];
  
      const emailSet = new Set();
      const validatedPasteData = [];
  
      for (const row of combinedData) {
        const email = row.email.trim();
        if (email === "") continue;
  
        const emailRegex = /^[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
        if (!emailRegex.test(email)) {
          toast.error(`"${email}" is not a valid email address. Please enter a valid email.`, { closeOnClick: true, autoClose: 25000 });
          continue;
        }
  
        if (emailSet.has(email)) {
          toast.error(`The email "${email}" is duplicated. Please enter unique emails.`, { closeOnClick: true, autoClose: 25000 });
          continue;
        }
  
        emailSet.add(email);
        validatedPasteData.push({ email });
      }
  
      while (validatedPasteData.length < tableData.length) {
        validatedPasteData.push({ email: "" });
      }

      setTableData(validatedPasteData);
      onSaveChanges?.(validatedPasteData);
      onDataChange?.(validatedPasteData);
  
      toast.success("Pasted from clipboard!", { closeOnClick: true, autoClose: 25000 });
    } catch (error) {
      logger.error("Error reading from clipboard:", error);
      toast.error("Failed to paste from clipboard.", { closeOnClick: true, autoClose: 25000 });
    }
  
    setContextMenuPosition(null);
  };
  
  const handleTableFocus = () => setIsTableSelected(true);
  const handleTableBlur = () => setIsTableSelected(false);

  return (
    <div className={"tabulator-container"}
      style={{ position: 'relative' }}
    >
      <ReactTabulator
        data={tableData}
        columns={columns}
        options={options}
        className="custom-tabulator"
        events={{
          dataChanged: handleDataChanged,
        }}
        onFocus={handleTableFocus}
        onBlur={handleTableBlur}
      />
      
      <div className="table-controls">
          <button
            className={`paste-button ${cleverButtonColor ? 'clever-button-color' : ''}`}
            onClick={handlePaste}
          >
            Paste
          </button>
        {selectedRows.length > 0 && (
          defineInputType(inputType, cleverButtonColor)
        )}
      </div>
      
    </div>
  );
};

export default UserGrid;
