import React, { useState} from 'react';
import PropTypes from 'prop-types';
import './PracticeCircle.css';

const PracticeCircle = ({ imageSrc, backgroundColor, text, countText, hoverText, onHover, onLeave, size = "100px", fontSize="1.4rem" }) => {
  const [isHovered, setIsHovered] = useState(false);
  const enlargedPractices = {
    "Help Others": "70%",
    "Think of 3 things that I am grateful for": "63%",
    "Imagine a peaceful place": "70%",
    "Squeeze my fist" : "70%",
    "Give someone a high five": "70%",
    "Write in my journal": "70%",
    "Move my body": "70%",
    "Use my growth mindset": "70%",
    "Stretch": "70%",
    "Create art": "70%",
    "Count to ten taking a deep breath each time": "58%",

  };
      
  const imageSize = Object.keys(enlargedPractices).includes(text) ? enlargedPractices[text] : '60%';

  return (
    <div className='circle-with-text'>
      <div
        className="circle-container"
        style={{ backgroundColor: backgroundColor, height: size, width: size }}
        onMouseEnter={() => {
          setIsHovered(true);
          onHover && onHover();
        }}
        onMouseLeave={() => {
          setIsHovered(false);
          onLeave && onLeave();
        }}
      >
        <img src={imageSrc} alt={text} style={{ width: "auto", height: imageSize }} />
        <div className="circle-text" style={{ fontSize}} >{text}</div>
        {isHovered && hoverText && <div className="hover-practice-text">{hoverText}</div>}
      </div>
      <p className="circle-caption">{countText}</p>
    </div>
  );
};

export default PracticeCircle;
