import React from "react";

const WellnessStreaks = (props) => {
    const possibleDayStreaks = [3, 5, 7, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 125, 150, 
                                175, 200, 250, 300, 365, 500, 730, 1095, 1460, 1825]

    const possibleWeekStreaks = [2, 3, 5, 10, 20, 30, 40, 52, 60, 70, 80, 90, 104, 156, 
                                208, 260, 312, 364, 416, 468, 520]
    
    const DayStreaks = (props) => {
      const { curDayStreak, maxDayStreak } = props;
      const possibleDayStreaks = [3, 5, 7, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 125, 150, 
                                  175, 200, 250, 300, 365, 730, 1095, 1460, 1825];
    
      return (
        <div className="streaksDisplayContainer">
          {possibleDayStreaks.map((streakNum, index) => {
            const isAchieved = (curDayStreak && streakNum <= curDayStreak) || 
                                (maxDayStreak && streakNum == maxDayStreak);
            const imgSrc = isAchieved
              ? `../images/streaks/dayStreaks/Color/${streakNum}DaysColor.png`
              : `../images/streaks/dayStreaks/Grey/${streakNum}DaysGrey.png`;
    
            const isYearFormat = [365, 730, 1095, 1460, 1825].includes(streakNum);
            const isOneYear = [365].includes(streakNum);
            const textColor = isAchieved ? "white" : "#5C5C5C";
    
            return (
              <div key={index} className="streakDisplayItem">
                <img src={imgSrc} alt={`Streak ${streakNum}`} className="streakItemImage" />
                {isYearFormat ? (
                  <>
                    {isOneYear ? (
                      <div className="yearText" style={{ color: textColor }}>YEAR</div>
                    ) : (
                      <div className="yearText" style={{ color: textColor }}>YEARS</div>
                    )}
                    <div className="daysText yearDaysText" style={{ color: textColor }}>DAYS</div>
                  </>
                ) : (
                  <div className="daysText" style={{ color: textColor }}>DAYS</div>
                )}
              </div>
            );
          })}
        </div>
      );
    };
                                                                
    const WeekStreaks = (props) => {
      const { curWeekStreak, maxWeekStreak } = props;
      const possibleWeekStreaks = [2, 3, 5, 10, 20, 30, 40, 52, 60, 70, 80, 90, 104, 156, 
                                  208, 260, 312, 364, 416, 468, 520];
    
      return (
        <div className="streaksDisplayContainer">
          {possibleWeekStreaks.map((streakNum, index) => {
            const isAchieved = (curWeekStreak && streakNum <= curWeekStreak) || 
                               (maxWeekStreak && streakNum === maxWeekStreak);
            const imgSrc = isAchieved
              ? `../images/streaks/weekStreaks/Color/${streakNum}WeeksColor.png`
              : `../images/streaks/weekStreaks/Grey/${streakNum}WeeksGrey.png`;
    
            const isYearFormat = [52, 104, 156, 208, 260, 312, 364, 416, 468, 520].includes(streakNum);
            const isTenYear = streakNum === 520;
            const isOneYear = streakNum === 52;
            const textColor = isAchieved ? "white" : "#5C5C5C";
    
            return (
              <div key={index} className="streakDisplayItem">
                <img src={imgSrc} alt={`Streak ${streakNum}`} className="streakItemImage" />
                {isYearFormat ? (
                  <>
                    {isOneYear ? (
                      <div className="yearTextWeekStreak" style={{ color: textColor }}>YEAR</div>
                    ) : (
                      isTenYear ? (
                        <div className="tenYearTextWeekStreak" style={{ color: textColor }}>YEARS</div>
                      ) : (
                        <div className="yearTextWeekStreak" style={{ color: textColor }}>YEARS</div>
                      )
                    )}
                    <div className="weeksText yearWeeksText" style={{ color: textColor }}>WEEKS</div>
                  </>
                ) : (
                  <div className="weeksText" style={{ color: textColor }}>WEEKS</div>
                )}
              </div>
            );
          })}
        </div>
      );
    };    

    const MaxStreak = (props) => {
        if (props.maxWeekStreak){
            return(<img id="myAchievementsSideIconStreak" src={`../images/streaks/weekStreaks/Color/${props.maxWeekStreak}WeeksColor.png`}/>)
        } else if (props.maxDayStreak){
            return(<img id="myAchievementsSideIconStreak" src={`../images/streaks/dayStreaks/Color/${props.maxDayStreak}DaysColor.png`}/>)
        }
        return(<img id="myAchievementsSideIconStreak" src={`../images/streaks/genericStreaks/BlankStreakBluecolor.png`}/>)
    }

    return(
        <div className="achievementSectionContainer">
            <MaxStreak props={props}></MaxStreak>
            <div className="achievementSectionContainerRightSide">
                <h2 className="achievementheaders" >STREAKS</h2>
                <div>
                    <h4 className="achievementheadersubtext"  >Day Streaks:</h4>
                    <p className="achievementExplanationP">Earn day streaks by practicing wellness multiple days in a row.</p>
                    <DayStreaks curDayStreak={props.curDayStreak}></DayStreaks>
                </div>
                <div>
                    <h4 className="achievementheadersubtextweek"  >Week Streaks:</h4>
                    <p className="achievementExplanationP">Earn week streaks when you practice wellness multiple weeks in a row.</p>
                    <WeekStreaks></WeekStreaks>
                </div>
            </div>
        </div>
    )
}

export default WellnessStreaks