import logger from "../../../utils/logger";

export function processSpecificClass(state, className, processDataPoint) {
    let addedStudents = new Set();
    
    if (state.districts[state.chosenDistrict] && 
        state.districts[state.chosenDistrict][state.chosenSchool] && 
        state.districts[state.chosenDistrict][state.chosenSchool][className]) {
      
      Object.values(state.districts[state.chosenDistrict][state.chosenSchool][className]).forEach((student) => {
        if (state.studentData[student] && !addedStudents.has(student)) {
          addedStudents.add(student);
          // logger.log(student)
          state.studentData[student].forEach(dataPoint => processDataPoint(dataPoint, student));
        }
      });
    } else {
      logger.log(`Class ${className} not found in the current district and school.`);
    }
  }
  
  export function processSpecificStudent(state, studentEmail, processDataPoint) {
    let processedData = false;
  
    if (state.districts[state.chosenDistrict] && 
        state.districts[state.chosenDistrict][state.chosenSchool]) {
      
      const classes = state.districts[state.chosenDistrict][state.chosenSchool];
      
      for (const className in classes) {
        if (classes[className].includes(studentEmail)) {
          if (state.studentData[studentEmail]) {
            state.studentData[studentEmail].forEach(dataPoint => processDataPoint(dataPoint, studentEmail));
            processedData = true;
            break;
          }
        }
      }
    }
    if (!processedData) {
      logger.log(`Student data not found for email: ${studentEmail} in the current district and school.`);
    }
  }
  
  