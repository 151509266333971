import moment from "moment";

export function timeHelper(time) {
  const timeDurations = {
    "10 minutes":0.167,
    "1 hour": 1,
    "2 hours": 2,
    "24 hours": 24,
    "day": 24,
    week: 7 * 24,
    month: 30 * 24,
    "3 months": 90 * 24,
    "6 months": 182 * 24,
    year: 365 * 24,
    "2 years": 365 * 24 * 2,

  };
  return moment.duration(timeDurations[time] || 0, "hours");
}