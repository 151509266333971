import React, { useState, useMemo } from "react";
import moment from "moment";
import { NoData } from "../screens/StudentData/components/AlertComponents";
import PracticeCircle from "./PracticeCircle";
import { categorizedPractices,backgroundColors } from "../screens/StudentData/utils/practicesConfig";

const HelpfulnessItemsDislay = ({ filteredNextStepsData, topActions, adultActionImages, selectedEmotion }) => {
  const [hoveredItem, setHoveredItem] = useState(null);
  const isMobile = window.innerWidth <= 600;
  const circleSize = isMobile ? '11vh' : '15vh';
  const fontSize = isMobile ? '0.9rem' : '1.2rem'

  const practicesArray = useMemo(() => {
    if (topActions && Object.keys(topActions).length > 0) {
      return Object.entries(topActions);
    } else if (filteredNextStepsData && filteredNextStepsData.length > 0) {
      const practiceHelpfulnessCounts = {};

      filteredNextStepsData.forEach((dataPoint) => {
        if (dataPoint.helpful === true) {
          practiceHelpfulnessCounts[dataPoint.action] = (practiceHelpfulnessCounts[dataPoint.action] || 0) + 1;
        }
      });

      return Object.entries(practiceHelpfulnessCounts).sort(([, a], [, b]) => b - a);
    }
    return [];
  }, [filteredNextStepsData, topActions]);

  const practicesDisplayLength = Math.min(practicesArray.length, 3);

  if (practicesArray.length === 0) {
    return <NoData></NoData>;
  }

  const imageSettingFilenameModifier = adultActionImages
    ? 'AdultCircle_BIG_FONT'
    : 'TeenCircle_BIG_FONT';
  
  const getBackgroundColor = (practice) => {
    if (practice.toLowerCase().startsWith("other")) {
      return backgroundColors.green;
    }

    for (const [color, practices] of Object.entries(categorizedPractices)) {
      if (practices.includes(practice)) {
        return backgroundColors[color];
      }
    }
    return selectedEmotion;
  };

  return (
    <div
      className="practice-images-container-next-steps"
      style={{ backgroundColor: selectedEmotion }}
    >
      {practicesArray.slice(0, practicesDisplayLength).map(([practice, count]) => {
        const isOther = practice.toLowerCase().startsWith("other");
        const imageSrc = isOther
          ? "../images/actions/Other_BIG_FONT.png"
          : `../images/actions/${practice} ${imageSettingFilenameModifier}.png`;

        return (
          <PracticeCircle
            key={practice}
            imageSrc={imageSrc}
            size={circleSize}
            fontSize={fontSize}
            backgroundColor={getBackgroundColor(practice)}
            text={isOther ? "Other:" : practice}
            countText={`${count} ${count > 1 ? "times" : "time"}`}
            hoverText={practice}
            onHover={() => setHoveredItem(practice)}
            onLeave={() => setHoveredItem(null)}
          />
        );
      })}
    </div>
  );
};

export default HelpfulnessItemsDislay;