import _ from "lodash";
import "./StudentsScreen.css";
import DeleteIcon from "@mui/icons-material/Delete";
import { UserContext } from "../../../realm/user.context";
import ReactDataSheet from "react-datasheet";
import React, { useRef, useState, useEffect, useContext } from "react";
import SyncIcon from "@mui/icons-material/Sync";
import "react-toastify/dist/ReactToastify.css";
import "./toast-overwrites.css";
import {
  deleteClass,
  addToClassAndProvision,
  removeUserFromClass,
  removeClassFromSchool,
} from "../../../realm/graphqlQueries";
import Spacer from "../../../components/Spacer";
import { toast } from "react-toastify";
import { LoadingOverlay } from "../../../components/LoadingOverlay";
import UserGrid from "./UserGrid";

const ClassPreview = ({
  onMobile,
  classData,
  setPerson,
  allStudents,
  allTeachers,
  setTeachers,
  setStudents,
  refreshData,
  setClassName,
  setEditingClass,
  saveChanges,
}) => {
  const [studentsInClass, setStudentsInClass] = useState();
  const [teachersInClass, setTeachersInClass] = useState();
  const [studentGrid, setStudentGrid] = useState([]);
  const [teacherGrid, setTeacherGrid] = useState([]);
  const [studentsExpanded, setStudentsExpanded] = useState(() => {
    const cookieValue = document.cookie
      .split("; ")
      .find((row) => row.startsWith(`class_${classData._id}=`))
      ?.split("=")[1];
    return cookieValue === "true";
  });
  const updateCookie = (value) => {
    document.cookie = `class_${classData._id}=${value}; path=/; max-age=86400`; // 1-day expiration
  };

  useEffect(() => {
    updateCookie(studentsExpanded);
  }, [studentsExpanded]);

  const [deleting, setDeleting] = useState(false);

  const { realmUser } = useContext(UserContext);

  const usingClever = useRef(classData.cleverId ? true : false);

  const [saving, setSaving] = useState(false);
  const [originalStudents, setOriginalStudents] = useState([]);
  const [originalTeachers, setOriginalTeachers] = useState([]);
  const [teacherInfo, setTeacherInfo] = useState([]);
  const [studentInfo, setStudentInfo] = useState([]);

  const generateEmptyRows = (count) => Array.from({ length: count }, () => ({ email: "" }));

  useEffect(() => {
    setStudentGrid(generateEmptyRows(10));
    setTeacherGrid(generateEmptyRows(2));
  }, []);

  useEffect(() => {
    const inClass = allStudents.filter((s) =>
      classData.students.includes(usingClever.current ? s.cleverId : s.email)
    );
  
    const teachers = allTeachers.filter((t) =>
      classData.teachers.includes(usingClever.current ? t.cleverId : t.email)
    );
  
    const studentData = inClass.map((student) => ({ email: student.email }));
    const teacherData = teachers.map((teacher) => ({ email: teacher.email }));
  
    const finalStudentGrid = studentData.length === 0
      ? generateEmptyRows(10)
      : [...studentData, ...generateEmptyRows(10)];

    const finalTeacherGrid = teacherData.length === 0
      ? generateEmptyRows(2)
      : [...teacherData, ...generateEmptyRows(2)];
    
    setStudentInfo(studentData);
    setTeacherInfo(teacherData);
    setStudentGrid(finalStudentGrid);
    setTeacherGrid(finalTeacherGrid);
    setStudentsInClass(inClass);
    setOriginalStudents([...inClass]);
    setOriginalTeachers([...teachers]);
    setTeachersInClass(teachers);
  }, []);

  if (!studentsInClass || !teachersInClass) {
    return <></>;
  }

  return (
    <div
      onMouseEnter={() => {
        if (!localStorage.getItem("receivedClassPreview")) {
          toast.info(
            "This is a class preview. Notice your teacher(s) in the area below.",
            { closeOnClick: true, autoClose: 25000 }
          );
          toast.info(
            "If you make changes, click the refresh icon to save them.",
            { closeOnClick: true, autoClose: 25000 }
          );
          toast.info("If you'd like to delete a class, click the trash icon.", {
            closeOnClick: true,
            autoClose: 25000,
          });

          localStorage.setItem("receivedClassPreview", true);
        }
      }}
      className="SSV2ClassPreview mod"
      style={{
        backgroundColor: usingClever.current ? "#1464FF" : "#4cc68d",
      }}
    >
      <div className="edit-class-container" >
        {saving && <LoadingOverlay></LoadingOverlay>}
        <div style={{ flex: 1 }} />
        <p
          className="SSV2CPTitle"
          style={{
            textDecoration: "underline",
            textDecorationColor: "#FFF",
            textDecorationThickness: "2.5px",
          }}
        >
          {classData.name}
        </p>
        <div style={{ flex: 1 }} />
        {!usingClever.current && (
          <div
            className="SettingsWrapper"
            style={{ marginRight: "1vmin" }}
            onClick={async () => {
              // TODO sync teachers?
              const syncedStudents = studentGrid
                .map((row) => row.email.trim())
                .filter((email) => email !== "");
              
              const originalEmails = studentsInClass.map((s) => s.email);

              const toAdd = syncedStudents.filter(
                (s) => !originalEmails.includes(s)
              );
              const toRemove = originalEmails.filter(
                (s) => !syncedStudents.includes(s)
              );

              for (const email of toAdd) {
                const foundUser = allStudents.filter((u) => u.email === email);
                await addToClassAndProvision(
                  email,
                  classData,
                  foundUser.length === 0 ? undefined : foundUser[0],
                  realmUser
                );
              }
              for (const email of toRemove) {
                const foundUser = allStudents.filter((u) => u.email === email);
                await removeUserFromClass(
                  email,
                  classData,
                  foundUser,
                  realmUser
                );
              }

              await refreshData();
            }}
          >
            <SyncIcon
              style={{
                color: "#fff",
                fontSize: "3vmin",
              }}
            />
          </div>
        )}
        {!usingClever.current && (
          <div
            className="SettingsWrapper"
            onClick={async () => {
              setDeleting(true);
            }}
          >
            <DeleteIcon
              className="delete-icon"
              style={{ color: deleting ? "red" : "" }}
            />
          </div>
        )}
      </div>
      <Spacer height="2vmin" />

      {usingClever.current && !studentsExpanded && (
        <>
          <p className="user-title">
            {(() => {
              switch (teachersInClass.length) {
                case 0:
                  return "No Teachers";
                case 1:
                  return `Teacher: ${
                    teachersInClass[0].displayName ?? teachersInClass[0].email
                  }`;
                default:
                  return `Teachers: ${teachersInClass
                    .map((t) => t.displayName ?? t.email)
                    .join(", ")}`;
              }
            })()}
          </p>
          <p className="user-title"> Students ({studentInfo.length}) </p>
          <Spacer height="2vmin" />
          <p
            onClick={() => setStudentsExpanded(true)}
            style={{
              margin: 0,
              cursor: "pointer",
              fontSize: "3vmin",
              fontWeight: "bold",
              alignSelf: "center",
              padding: "1vmin",
              borderRadius: "5px",
              backgroundColor: "#FFF",
              color: usingClever.current ? "#1464FF" : "#4cc68d",
            }}
          >
            View Students
          </p>
        </>
      )}
      {usingClever.current && studentsExpanded && (
        <>
          <p
            className="SSV2CPTitle"
            style={{
              textAlign: "center",
              fontWeight: "normal",
              alignSelf: "flex-start",
            }}
          >
            {(() => {
              switch (teachersInClass.length) {
                case 0:
                  return "No Teachers";
                case 1:
                  return `Teacher: ${
                    teachersInClass[0].displayName ?? teachersInClass[0].email
                  }`;
                default:
                  return `Teachers: ${teachersInClass
                    .map((t) => t.displayName ?? t.email)
                    .join(", ")}`;
              }
            })()}
          </p>
          <Spacer height="2vmin" />
          <p
            className="SSV2CPTitle"
            style={{
              textAlign: "left",
              fontWeight: "normal",
              alignSelf: "flex-start",
            }}
          >
            Students ({studentInfo.length})
          </p>
          <UserGrid
            initialData={studentGrid}
            onSaveChanges={(updatedData) => saveChanges("student", updatedData, originalStudents, setOriginalStudents, classData.name, true)}
            cleverButtonColor={true}
          />
          <Spacer height="2vmin" />
          <p
            onClick={() => setStudentsExpanded(false)}
            style={{
              margin: 0,
              cursor: "pointer",
              fontSize: "3vmin",
              fontWeight: "bold",
              alignSelf: "center",
              padding: "1vmin",
              borderRadius: "5px",
              backgroundColor: "#FFF",
              color: usingClever.current ? "#1464FF" : "#4cc68d",
            }}
          >
            Hide Students
          </p>
        </>
      )}
      {/* TODO: Fix this it causes double renders for non-clever school */}
      {!usingClever.current &&
        (!studentsExpanded ? (
        <>
          <div className="user-count">
            <p> Teachers: {teacherInfo.length} </p>
            <p> Students: {studentInfo.length} </p>
          </div>
          <Spacer height="1vmin" />
          <p
            onClick={() => setStudentsExpanded(true)}
            style={{
              margin: 0,
              cursor: "pointer",
              fontSize: "3vmin",
              fontWeight: "bold",
              alignSelf: "center",
              padding: "1vmin",
              borderRadius: "5px",
              backgroundColor: "#FFF",
              color: usingClever.current ? "#1464FF" : "#4cc68d",
            }}
          >
            Edit Class
          </p>
          </>
        ) : (
          <>
            <p
              className="SSV2CPTitle"
              style={{
                textAlign: "left",
                fontWeight: "normal",
                alignSelf: "flex-start",
              }}
            >
              Teachers ({teacherInfo.length})
            </p>
            <Spacer height="1vmin" />
            <div className="SSV2BackdropTeacher">
            <UserGrid
              initialData={teacherGrid}
              maxHeight={120}
              onSaveChanges={async (updatedData) => {
                setTeacherGrid([...updatedData]);
                await saveChanges(
                  "teacher",
                  updatedData,
                  originalTeachers,
                  setOriginalTeachers,
                  classData.name,
                  false,
                  true
                );
              }}
            />
            </div>
            <Spacer height="1vmin" />
            <p
              className="SSV2CPTitle"
              style={{
                textAlign: "left",
                fontWeight: "normal",
                alignSelf: "flex-start",
              }}
            >
              Students ({studentInfo.length})
            </p>
            <Spacer height="1vmin" />
            <div className="SSV2Backdrop">
            <UserGrid
              initialData={studentGrid}
              onSaveChanges={async (updatedData) => {
                setStudentGrid([...updatedData]);
                await saveChanges(
                  "student",
                  updatedData,
                  originalStudents,
                  setOriginalStudents,
                  classData.name,
                  false
                );
              }}
            />
            </div>
            <Spacer height="1vmin" />
            <p
              onClick={() => setStudentsExpanded(false)}
              style={{
                cursor: "pointer",
                marginTop: "2vmin",
                marginBottom: 0,
                color: "#FFF",
                fontWeight: "bold",
                fontSize: "3vmin",
                alignSelf: "center",
              }}
            >
              Hide Class
            </p>
          </>
        ))}

      <div
        onClick={() => setDeleting(false)}
        style={{
          top: 0,
          left: 0,
          display: "flex",
          position: "fixed",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
          zIndex: deleting ? 2 : -1,
          width: deleting ? "100vw" : "0vw",
          height: deleting ? "100vh" : "0vh",
          // transition: "width 0.5s, height 0.5s",
          backgroundColor: deleting ? "rgba(0, 0, 0, 0.5)" : "rgba(0, 0, 0, 0)",
        }}
      >
        {deleting && (
          <div
            onClick={(e) => e.stopPropagation()}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "2.5vmin",
              borderRadius: "5px",
              backgroundColor: "#FFF",
              // transition: "width 0.5s, height 0.5s",
              width: deleting ? "75vmin" : "0vmin",
              marginLeft: onMobile ? "0" : "20vmin",
              // height: deleting ? "75vmin" : "0vmin",
            }}
          >
            <p
              style={{
                margin: 0,
                fontSize: "3.5vmin",
                fontWeight: "bold",
                textAlign: "center",
                // transition: "color 0.5s",
                zIndex: deleting ? 2 : -1,
                color: deleting ? "#4cc68d" : "#FFF",
              }}
            >
              Are you sure you want to delete '{classData.name}'?
            </p>
            <Spacer height="2.5vmin" />
            <p
              style={{
                margin: 0,
                fontSize: "3vmin",
                // fontWeight: "bold",
                textAlign: "center",
                // transition: "color 0.5s",
                zIndex: deleting ? 2 : -1,
                color: deleting ? "#4cc68d" : "#FFF",
              }}
            >
              This cannot be undone.
            </p>
            <Spacer height="5vmin" />
            <p
              onClick={async () => {
                try {
                  setSaving(true);
                  await deleteClass({ _id: classData._id }, realmUser);
                  await removeClassFromSchool(
                    classData.school,
                    classData.district,
                    realmUser,
                    classData.name
                  );
                } finally {
                  await refreshData();
                  setDeleting(false);
                  setSaving(false);
                }
              }}
              style={{
                cursor: "pointer",
                alignSelf: "center",
                textAlign: "center",
                fontSize: "3vmin",
                width: "20vw",
                margin: 0,
                backgroundColor: "#4cc68d",
                padding: "2vmin",
                borderRadius: "5px",
                color: "#FFF",
              }}
            >
              Yes, I'm Sure.
            </p>
            <Spacer height="2.5vmin" />
            <p
              onClick={() => setDeleting(false)}
              style={{
                cursor: "pointer",
                alignSelf: "center",
                textAlign: "center",
                width: "20vw",
                fontSize: "3vmin",
                margin: 0,
                backgroundColor: "#4cc68d",
                padding: "2vmin",
                borderRadius: "5px",
                color: "#FFF",
              }}
            >
              No, take me back.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ClassPreview;