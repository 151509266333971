import { initEmotionDictionary } from "./util";
import moment from "moment";

export function getAllStudents(classRef) {
  const studentList = classRef.classes.flatMap((cls) => cls.students);
  return [...new Set(studentList)];
}

export function createStudentMaps(students, anonamizeData) {
  // if anonamizeData just make each student student1, student2 etc...
  let counter = 1;
  return students.reduce(
    (maps, student) => {
      maps.idToEmail[student.cleverId] = student.email;

      if(anonamizeData){
        maps.emailToName[student.email] = `Student ${counter}`;
      }else{
        maps.emailToName[student.email] = student.displayName ?? student.email;
      }
      counter+=1
      return maps;
    },
    { idToEmail: {}, emailToName: {} }
  );
}

export function createStudentIDMap(allStudents) {
  return allStudents.reduce((map, student) => {
    map[student._id] = student.email;
    return map;
  }, {});
}

export function constructStudentData(studentRef, wellnessData) {
  const studentData = {};
  // logger.log(studentRef);
  if (studentRef.users) {
    studentRef = studentRef.users;
  }
  studentRef.forEach((student) => {
    const relWellness = wellnessData.wellnesses.filter(
      (x) => x.userID === student._id
    );
    studentData[student.email] = relWellness || [];
  });
  return studentData;
}

// export function constructSuperAdminStudentData(wellnesses, allStudentIDs) {
//   return wellnesses.reduce((studentData, datapoint) => {
//     const studentEmail = allStudentIDs[datapoint.userID];
//     if (!studentData[studentEmail]) studentData[studentEmail] = [];
//     studentData[studentEmail].push(datapoint);
//     return studentData;
//   }, {});
// }

export function convertDistrictStudentIdsToEmails(
  districts,
  studentIdToEmailMap
) {
  const convertedDistricts = {};
  for (const [districtName, schools] of Object.entries(districts)) {
    convertedDistricts[districtName] = {};
    for (const [schoolName, classes] of Object.entries(schools)) {
      convertedDistricts[districtName][schoolName] = {};
      for (const [className, studentIds] of Object.entries(classes)) {
        convertedDistricts[districtName][schoolName][className] =
          studentIds.map(
            (id) => studentIdToEmailMap[id] || id // fallback to ID if email not found
          );
      }
    }
  }
  return convertedDistricts;
}

export function createWellnessMap(allWellnessDataBulk) {
  const wellnessMap = {};
  let recordCounter = 0;
  allWellnessDataBulk.forEach((record) => {
    const userID = record.userID;
    if (!wellnessMap[userID]) {
      wellnessMap[userID] = [];
    }
    wellnessMap[userID].push(record);
    recordCounter++;
  });
  return { wellnessMap, recordCounter };
}

export function processData(
  districtsRaw,
  allSchools,
  allStudents,
  wellnessMapData,
  state,
  wellnessUser = false,
) {
  const currentDate = moment().subtract(state.filterAmountCurrent);
  const compareDate = moment().subtract(
    state.filterAmountCompare + state.filterAmountCurrent
  );
  const districts = {};
  const studentData = {};
  const allWellnessPoints = [];
  const currentDictionary = initEmotionDictionary();
  const compareDictionary = initEmotionDictionary();
  const { wellnessMap, recordCounter } = wellnessMapData;
  
  let studentCounter = 1;
  const anonymousStudentMap = {};

  districtsRaw.forEach((districtItem) => {
    const schools = allSchools.filter((s) =>
      [districtItem.name, districtItem.cleverId].includes(s.district)
    );

    const districtData = {};
    schools.forEach((school) => {
      districtData[school.name] = {};
      districtData[school.name]["class"] = [];
      const studentsInSchool = allStudents.filter(
        (student) =>
          student.school === school.name ||
          student.schools?.includes(school.name) ||
          student.schools?.includes(school.cleverId)
      );

      studentsInSchool.forEach((student) => {
        if (student.email) {
          let studentIdentifier;
          if (wellnessUser) {
            if (!anonymousStudentMap[student.email]) {
              anonymousStudentMap[student.email] = `Student ${studentCounter}`;
              studentCounter++;
            }
            studentIdentifier = anonymousStudentMap[student.email];
          } else {
            studentIdentifier = student.email;
          }

          districtData[school.name]["class"].push(studentIdentifier);
          studentData[studentIdentifier] = wellnessMap[student._id] || [];
          studentData[studentIdentifier].forEach((datapoint) => {
            // Anonymize the datapoint if it's a wellness user
            const anonymizedDatapoint = wellnessUser ? 
              { ...datapoint, studentId: studentIdentifier } : 
              datapoint;

            allWellnessPoints.push(anonymizedDatapoint);
            updateEmotionDictionaries(
              anonymizedDatapoint,
              currentDate,
              compareDate,
              currentDictionary,
              compareDictionary
            );
          });
        }
      });
    });

    districts[districtItem.name] = districtData;
  });

  return {
    districts,
    studentData,
    allWellnessPoints,
    currentDictionary,
    compareDictionary,
    recordCounter,
    anonymousStudentMap: wellnessUser ? anonymousStudentMap : null,
  };
}

function updateEmotionDictionaries(
  datapoint,
  currentDate,
  compareDate,
  currentDictionary,
  compareDictionary
) {
  const datapointTimestamp = new Date(datapoint.date).valueOf();
  if (datapointTimestamp >= currentDate.valueOf()) {
    currentDictionary[datapoint.emotion]++;
  } else if (datapointTimestamp >= compareDate.valueOf()) {
    compareDictionary[datapoint.emotion]++;
  }
}
