import { useContext } from "react";
import { UserContext } from "../../realm/user.context";
import { StudentDataContext } from "../../context/studentData.context";
import "./StudentData.css";
import BarGraph from "../../components/BarGraph";
import FilterRow from "./FilterRow";
import NavigationBar from "./NavigationBar";
import CongratulationsBanner, { shouldRenderBanner } from "./CongratulationsBanner";
import StudentDataWellnessSuccessTab from "./components/StudentDataWellnessSuccessTab/StudentDataWellnessSuccessTab";
import EmotionsLog from "./components/EmotionsLog";
import MyNextSteps from "../../components/MyNextSteps";
import BigFiveWellnessDataWrapper from "../../components/BigFiveWellnessData";
import BigFiveInfoDisplay from "../../components/BigFiveInfoDisplay";
import CustomTooltip from "./components/CustomTooltip";
import ScreenshotButtonComponent from "../../components/ScreenshotButtonComponent";
import { LoadingOverlayExtended } from "../../components/LoadingOverlayExtended";
import { useAnonymousData } from "./hooks/useAnonymousData";

const StudentData = (props) => {
  const { realmUser } = useContext(UserContext);
  const contextData = useContext(StudentDataContext);
  
  const anonymousData = useAnonymousData(realmUser, props.user, props.anonamizeData);

  // Use either context or anonymous data based on anonamizeData prop
  const { state, updateState, isUpdating } = props.anonamizeData 
    ? anonymousData
    : contextData;

  const BarGraphComponent = (props) => {
    const CustomTooltipWrapper = (props) => {
      return <CustomTooltip {...props} state={state} />;
    };
    return (
      <div>
        <h2 className="emotions-summary-title">Student Graphs</h2>{" "}
        <div
          style={{
            width: "90%",
            height: "50vh",
            margin: "auto",
            marginBottom: "4vh",
          }}
        >
          <BarGraph
            state={state}
            barGraphData={state.counts}
            CustomTooltip={CustomTooltipWrapper}
          />
        </div>
      </div>
    );
  };
  const WellnessSuccess = (props) => {
    return (
      <div>
        <h2 className="emotions-summary-title">What Helps</h2>{" "}
        <StudentDataWellnessSuccessTab
          state={state}
          userRole={props.user.user.user}
          updateState={updateState}
          adultActionImages={props.user.user.adultActionImages}
        />
      </div>
    );
  };

  const EmotionsLogComponent = (props) => {
    return (
      <div>
        <h2 className="emotions-summary-title">Tiered Support</h2>{" "}
        <EmotionsLog
          state={state}
          userRole={props.user.user.user}
          updateState={updateState}
          adultActionImages={props.user.user.adultActionImages}
        />
      </div>
    );
  };

  const MyNextStepsComponent = (props) => {
    return (
      <div>
        <h2 className="emotions-summary-title">Next Steps</h2>{" "}
        <MyNextSteps
          impactfulActions={state.threeImpactfulActions}
          state={state}
          userRole={props.user.user.user}
          adultActionImages={props.user.user.adultActionImages}
        />
      </div>
    );
  };

  const BigFiveWellnessComponent = (props) => {
    return (
      <div>
        <h2 className="emotions-summary-title">Student Wellness History</h2>{" "}
        <BigFiveInfoDisplay />
        <BigFiveWellnessDataWrapper
        state={state} 
        updateState={updateState}
        userRole={props.user.user.user}
        adultActionImages={props.user.user.adultActionImages}
        />
      </div>
    );
  };

  const components = [
    BarGraphComponent,
    WellnessSuccess,
    BigFiveWellnessComponent,
    EmotionsLogComponent,
    MyNextStepsComponent,
  ];

  const handleNavClick = (index) => {
    updateState({ activeComponent: index });
  };

  const handleNext = () => {
    updateState({
      activeComponent: (state.activeComponent + 1) % components.length,
    });
  };

  const handleBack = () => {
    updateState({
      activeComponent:
        (state.activeComponent - 1 + components.length) % components.length,
    });
  };

  const ActiveComponent = components[state.activeComponent];

  return (
    <div className="outerbackground">
      <div className="columndatatrends">
        <NavigationBar
          navItems={[
            "Student Graphs",
            "What Helps",
            "History",
            "Tiered Support",
            "Next Steps",
          ]}
          activeComponent={state.activeComponent}
          onNavClick={handleNavClick}
        />
        
        <div className="active-component-container">
          <CongratulationsBanner state={state} userRole={props.user.user.user} />

          <h2 className="centered-title">
            Who do you want to learn about today?
          </h2>

          <FilterRow
            state={state}
            userRole={props.user.user.user}
            updateState={updateState}
            activeComponent={state.activeComponent}
          />
          <ScreenshotButtonComponent 
            studentDataScreen={true} 
            congratulationsBar={shouldRenderBanner(undefined, state, props.user.user.user)}
          />
          <div style={{ position: "relative" }}>
            {(state.loading || isUpdating) && (
              <LoadingOverlayExtended 
                containerStyle={{
                  position: "absolute",
                  borderRadius: "8px",
                }}
              />
            )}
            <ActiveComponent {...props} state={state} updateState={updateState} />
          </div>
        </div>
        <div className="navigation-buttons">
          <button onClick={handleBack}>BACK</button>
          <button onClick={handleNext}>NEXT</button>
        </div>
      </div>
    </div>
  );
};

export default StudentData;
