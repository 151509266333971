const DateframeDropdownSelect = ({ value, onChange, onChangeFunction, className = "filterprofile" }) => {

    const timeframeToDayConversionDict = {"day":1, "week":7, "month":30, "3 months":90, "6 months":182, "year":365};

    const handleChange = (e) => {
        if (onChangeFunction) {
            onChangeFunction(e);
        } else if (onChange) {
            onChange(e.target.value);
        }
    };

    return (<select className={className} value={value} onChange={handleChange}>
        <option className="filtertext" value={"day"}>Today (24 Hours)</option>
        <option className="filtertext" value={"week"}>This Week (7 days)</option>
        <option className="filtertext" value={"month"}>This Month (30 days)</option>
        <option className="filtertext" value={"3 months"}>Last 3 Months</option>
        <option className="filtertext" value={"6 months"}>Last 6 Months</option>
        <option className="filtertext" value={"year"}>Last Year</option>
    </select>)
}

export default DateframeDropdownSelect