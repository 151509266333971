import React, { createContext, useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { UserContext } from '../realm/user.context';
import { getWellnessDataByUserID, updateWellnessHelpfulMutation } from '../realm/graphqlQueries';
import { timeHelper } from '../screens/StudentData/utils/timeHelper';
import logger from '../utils/logger';

export const MyStoryContext = createContext();

export const MyStoryProvider = ({ children, user }) => {
  const { realmUser } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [wellnessList, setWellnessList] = useState([]);
  const [filteredNextStepsData, setFilteredNextStepsData] = useState([]);
  const [bigFiveFilteredData, setBigFiveFilteredData] = useState([]);
  const [selectedTimes, setSelectedTimes] = useState({
    "All Day": true,
    "Early Morning": false,
    Morning: false,
    Afternoon: false,
    Evening: false,
  });

  const [helpfulFilter, setHelpfulFilter] = useState("All");
  const [selectedSubOptions, setSelectedSubOptions] = useState({});
  const [state, setState] = useState({
    favourites: false,
    sad: true,
    tired: true,
    lonely: true,
    happy: true,
    grateful: true,
    calm: true,
    stressed: true,
    scared: true,
    angry: true,
  });

  const [allData, setAllData] = useState(null);
  const [filterName, setFilterName] = useState("week");
  const [filterAmount, setFilterAmount] = useState(moment.duration(7, "days"));
  const [wellnessPopupOpen, setWellnessPopupOpen] = useState(false);
  const [whatHelpsData, setWhatHelpsData] = useState({});
  const [selectedEmotion, setSelectedEmotion] = useState("all");
  const [selectedEmotionColor, setSelectedEmotionColor] = useState("#C8FADD");
  const [selectedTimeframe, setSelectedTimeframe] = useState("week");
  const [feeling, setFeeling] = useState("select");
  const [studentData, setStudentData] = useState([]);
  const [barGraphData, setBarGraphData] = useState([
    { name: "sad", count: 0 },
    { name: "tired", count: 0 },
    { name: "lonely", count: 0 },
    { name: "happy", count: 0 },
    { name: "grateful", count: 0 },
    { name: "calm", count: 0 },
    { name: "stressed", count: 0 },
    { name: "scared", count: 0 },
    { name: "angry", count: 0 },
  ]);
  const [tally, setTally] = useState(0);
  const [currentTabNumber, setCurrentTabNumber] = useState(0);
  const [districts, setDistricts] = useState({});
  const [chosenDistrict, setChosenDistrict] = useState("All Districts");
  const [chosenSchool, setChosenSchool] = useState("All Schools");
  const [chosenClass, setChosenClass] = useState("All Classes");
  const [chosenStudent, setChosenStudent] = useState("All Students");
  const [chosenEmotion, setChosenEmotion] = useState("all emotions");
  const [filterAmountCurrent, setFilterAmountCurrent] = useState(moment.duration(30, "days"));
  const [filterAmountCompare, setFilterAmountCompare] = useState(moment.duration());
  const [studentEmailToNameMap, setStudentEmailToNameMap] = useState({});

  const timeframeToDayConversionDict = {
    day: 1,
    week: 7,
    month: 30,
    "3 months": 90,
    "6 months": 182,
    year: 365,
  };

  const fetchAllData = async () => {
    if (!user?.user) return;
    logger.log("Fetching all data");
    
    setTally(0);
    setLoading(true);
    try {
      const resp = await getWellnessDataByUserID(user.user._id, realmUser);
      setAllData(resp);
      setStudentData(resp);
      changeBarGraphFilter(filterName);
      setLoading(false);
    } catch (error) {
      logger.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const changeBarGraphFilter = (time) => {
    if (!studentData.length) return;
    
    let filter = moment.duration(timeframeToDayConversionDict[time], "days");
    let emotionDict = {
      sad: 0,
      tired: 0,
      lonely: 0,
      happy: 0,
      grateful: 0,
      calm: 0,
      stressed: 0,
      scared: 0,
      angry: 0,
    };

    studentData.forEach((datapoint) => {
      if (
        new Date(datapoint.date).getTime() >= moment().subtract(filter).valueOf()
      ) {
        emotionDict[datapoint.emotion]++;
      }
    });

    const newBarGraphData = Object.entries(emotionDict).map(([name, count]) => ({
      name,
      count,
    }));

    setBarGraphData(newBarGraphData);
    setTally(calculateTotal(emotionDict));
  };

  const calculateTotal = (dict) => {
    return Object.values(dict).reduce((acc, value) => acc + value, 0);
  };

  const gatherWellnessPractices = (feeling, time) => {
    setFilterName(time);
    let filter = moment.duration(timeframeToDayConversionDict[time], "days");
    
    let emotionDict = {
      sad: {},
      tired: {},
      lonely: {},
      happy: {},
      grateful: {},
      calm: {},
      stressed: {},
      scared: {},
      angry: {},
    };

    studentData.forEach((datapoint) => {
      if (new Date(datapoint.date).getTime() >= moment().subtract(filter).valueOf()) {
        let feeling = datapoint.emotion;
        let helped = datapoint.helpful;
        let action = datapoint.action;

        if (helped) {
          if (!(action in emotionDict[feeling])) {
            emotionDict[feeling][action] = 0;
          }
          emotionDict[feeling][action]++;
        }
      }
    });

    setFeeling(feeling);
    if (feeling === "select") {
      setWellnessList([]);
    } else {
      let feelingList = Object.keys(emotionDict[feeling]);
      if (feelingList.length > 3) {
        let objectList = Object.keys(emotionDict[feeling]).sort((a, b) => 
          emotionDict[feeling][b] - emotionDict[feeling][a]
        );
        setWellnessList(objectList.slice(0, 3));
      } else {
        setWellnessList(feelingList);
      }
    }

    setBigFiveFilteredData(studentData.filter(
      (data) => new Date(data.date).getTime() >= moment().subtract(filter).valueOf()
    ));
  };

  const helpful = async (data) => {
    try {
      await updateWellnessHelpfulMutation(data._id, !data.helpful, realmUser);
      const updatedData = studentData.map((item) =>
        item._id === data._id ? { ...item, helpful: !data.helpful } : item
      );
      setStudentData(updatedData);
      setAllData(updatedData);
    } catch (error) {
      logger.error("Error updating helpful status:", error);
    }
  };

  useEffect(() => {
    fetchAllData();
  }, [user, realmUser]);

  useEffect(() => {
    if (!allData) return;
    try {
      const whatHelps = (allData || [])
        .filter(
          (data) =>
            (data.emotion === selectedEmotion || selectedEmotion === "all") &&
            moment(data.date).isAfter(moment().subtract(timeHelper(selectedTimeframe))) &&
            data.helpful === true
        )
        .map((item) => ({
          ...item,
          hourfield: new Date(item.date).getUTCHours(),
        })) || [];
      setWhatHelpsData(whatHelps);

      // Initialize bigFiveFilteredData with all student data
      setBigFiveFilteredData(allData || []);
    } catch (error) {
      logger.error("Error processing whatHelpsData:", error);
      setWhatHelpsData([]);
      setBigFiveFilteredData([]);
    }
  }, [selectedEmotion, selectedEmotionColor, selectedTimeframe, allData]);

  useEffect(() => {
    changeBarGraphFilter(filterName);
  }, [wellnessList, filterName, studentData]);

  const value = {
    loading,
    wellnessList,
    filteredNextStepsData,
    bigFiveFilteredData,
    setBigFiveFilteredData,
    selectedTimes,
    setSelectedTimes,
    helpfulFilter,
    setHelpfulFilter,
    selectedSubOptions,
    setSelectedSubOptions,
    state,
    setState,
    allData,
    filterName,
    setFilterName,
    filterAmount,
    setFilterAmount,
    wellnessPopupOpen,
    setWellnessPopupOpen,
    whatHelpsData,
    selectedEmotion,
    setSelectedEmotion,
    selectedEmotionColor,
    setSelectedEmotionColor,
    selectedTimeframe,
    setSelectedTimeframe,
    feeling,
    setFeeling,
    studentData,
    barGraphData,
    tally,
    currentTabNumber,
    setCurrentTabNumber,
    fetchAllData,
    changeBarGraphFilter,
    gatherWellnessPractices,
    helpful,
    districts,
    chosenDistrict,
    chosenSchool,
    chosenClass,
    chosenStudent,
    chosenEmotion,
    filterAmountCurrent,
    filterAmountCompare,
    studentEmailToNameMap,
  };

  return (
    <MyStoryContext.Provider value={value}>
      {children}
    </MyStoryContext.Provider>
  );
}; 