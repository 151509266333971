import React, { useEffect, useState, useContext } from "react";
import { FaRegHeart, FaHeart } from "react-icons/fa";
import moment from "moment";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./MyStory.css";
import "../ProfileScreen/ProfileCalendar.css";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Menu from "@mui/material/Menu";
import { Popover } from "react-tiny-popover";
import { UserContext } from "../../realm/user.context";
import { MyStoryContext } from "../../context/myStory.context";
import { useNavigate } from "react-router-dom";
import DateframeDropdownSelect from "../../components/DateframeDropdownSelect";
import MyWellnessSuccessTab from "../../components/MyWellnessSuccessTab";
import MyNextSteps from "../../components/MyNextSteps";
import NavigationBar from "../StudentData/NavigationBar";
import CongratulationsBanner from "../StudentData/CongratulationsBanner";
import BarGraph from "../../components/BarGraph";
import BigFiveWellnessDataWrapper from "../../components/BigFiveWellnessData";
import AchievementPage from "../../components/AchievementPage/AchievementPage";
import ScreenshotButtonComponent from "../../components/ScreenshotButtonComponent";
import { LoadingOverlayExtended } from "../../components/LoadingOverlayExtended";
import { timeHelper } from "../StudentData/utils/timeHelper";
import { useLocation } from "react-router-dom";

const MyStory = (props) => {
  const navigate = useNavigate();
  const { realmUser } = useContext(UserContext);
  const {
    loading,
    wellnessList,
    filteredNextStepsData,
    bigFiveFilteredData,
    selectedTimes,
    setSelectedTimes,
    helpfulFilter,
    setHelpfulFilter,
    selectedSubOptions,
    setSelectedSubOptions,
    state,
    setState,
    allData,
    filterName,
    setFilterName,
    filterAmount,
    setFilterAmount,
    wellnessPopupOpen,
    setWellnessPopupOpen,
    whatHelpsData,
    selectedEmotion,
    setSelectedEmotion,
    selectedEmotionColor,
    setSelectedEmotionColor,
    selectedTimeframe,
    setSelectedTimeframe,
    feeling,
    setFeeling,
    studentData,
    barGraphData,
    tally,
    currentTabNumber,
    setCurrentTabNumber,
    gatherWellnessPractices,
    helpful,
  } = useContext(MyStoryContext);

  const localizer = momentLocalizer(moment);

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  const Cal = () => (
    <Calendar
      localizer={localizer}
      events={[]}
      startAccessor="start"
      endAccessor="end"
      style={{ height: 500 }}
    />
  );

  const BarGraphComponent = (props) => {
    return (
      <div>
        <h2 className="emotions-summary-title">My Emotions</h2>
        <div
          style={{
            width: "90%",
            height: "50vh",
            margin: "auto",
            marginBottom: "4vh",
          }}
        >
          <BarGraph
            barGraphData={barGraphData}
            CustomTooltip={CustomTooltip}
          />
        </div>
      </div>
    );
  };

  const MyWellnessSuccessComponent = (props) => {
    return (
      <div>
        <h2 className="emotions-summary-title">What Helps</h2>
        <MyWellnessSuccessTab
          whatHelpsData={whatHelpsData || []}
          studentData={whatHelpsData || []}
          selectedEmotion={selectedEmotion}
          setSelectedEmotion={setSelectedEmotion}
          selectedEmotionColor={selectedEmotionColor}
          setSelectedEmotionColor={setSelectedEmotionColor}
          selectedTimeframe={selectedTimeframe}
          setSelectedTimeframe={setSelectedTimeframe}
          adultActionImages={props.user?.user?.adultActionImages}
        />
      </div>
    );
  };

  const MyNextStepsComponent = (props) => {
    return (
      <div>
        <h2 className="emotions-summary-title">Next Steps</h2>
        <MyNextSteps
          filteredNextStepsData={studentData.filter(
            (data) => new Date(data.date).getTime() >= moment().subtract(timeHelper(filterName)).valueOf()
          )}
          impactfulActions={{ action: wellnessList, emotion: {} }}
          state={{ feeling, filterName }}
          userRole={props.user?.user?.user}
          adultActionImages={props.user?.user?.adultActionImages}
          filterName={filterName}
        />
      </div>
    );
  };

  const BigFiveWellnessDataComponent = (props) => {
    return (
      <div>
        <h2 className="emotions-summary-title">My Wellness History</h2>
        <BigFiveWellnessDataWrapper
          state={{
            bigFiveFilteredData: bigFiveFilteredData.filter(
              (data) => new Date(data.date).getTime() >= moment().subtract(timeHelper(filterName)).valueOf()
            ),
            selectedTimes,
            helpfulFilter,
            selectedSubOptions,
            districts: {},
            chosenDistrict: "All Districts",
            chosenSchool: "All Schools",
            chosenClass: "All Classes",
            chosenStudent: "All Students",
            chosenEmotion: "all emotions",
            filterAmountCurrent: moment.duration(timeHelper(filterName)),
            filterAmountCompare: moment.duration(),
            studentEmailToNameMap: {},
            studentData: studentData || [],
            wellnessPopupOpen,
            adultActionImages: props.user?.user?.adultActionImages,
          }}
          updateState={(updates) => {
            if (updates.selectedTimes) setSelectedTimes(updates.selectedTimes);
            if (updates.helpfulFilter) setHelpfulFilter(updates.helpfulFilter);
            if (updates.selectedSubOptions) setSelectedSubOptions(updates.selectedSubOptions);
            if (updates.wellnessPopupOpen !== undefined) setWellnessPopupOpen(updates.wellnessPopupOpen);
          }}
          userRole={props.user?.user?.user}
        />
      </div>
    );
  };

  const AchievementPageComponent = (props) => {
    return (
      <div>
        <h2 className="emotions-summary-title">My Achievements</h2>
        <AchievementPage user={props.user?.user} />
      </div>
    );
  };

  const components = [
    BarGraphComponent,
    MyWellnessSuccessComponent,
    BigFiveWellnessDataComponent,
    MyNextStepsComponent,
    AchievementPageComponent,
  ];

  const handleNavClick = (index) => {
    setCurrentTabNumber(index);
  };

  const handleNext = () => {
    setCurrentTabNumber((currentTabNumber + 1) % components.length);
  };

  const handleBack = () => {
    setCurrentTabNumber(
      (currentTabNumber - 1 + components.length) % components.length
    );
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`${payload[0].payload.name} : ${numberWithCommas(
            payload[0].value
          )}`}</p>
        </div>
      );
    }
    return null;
  };

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const ActiveComponent = components[currentTabNumber];

  const location = useLocation();
  const achievementTab = 4;

  useEffect(() => {
    if (location.state?.activeTab === 'achievement') {
      setCurrentTabNumber(achievementTab);
    }
  }, [location.state]);

  useEffect(() => {
    if (currentTabNumber === achievementTab) {
      window.scrollTo(0, 0);
    }
  }, [currentTabNumber]);

  return (
    <div className="outerbackground">
      <div className="columndatatrends">
        <NavigationBar
          navItems={[
            "My Emotions",
            "What Helps",
            "History",
            "Next Steps",
            "Achievements",
          ]}
          activeComponent={currentTabNumber}
          onNavClick={handleNavClick}
        />
        
        <div className="active-component-container">
          <CongratulationsBanner count={tally} time={filterName} userRole={props.user?.user?.user} />
{/*           
          <h2 className="centered-title">
            My Story
          </h2> */}
          {currentTabNumber !== 1 && (
            <DateframeDropdownSelect
              value={filterName}
              onChange={(value) => gatherWellnessPractices(feeling, value)}
            />
          )}
          <ScreenshotButtonComponent 
            studentDataScreen={true}
          />
          <div style={{ position: "relative" }}>
            {loading && (
              <LoadingOverlayExtended 
                containerStyle={{
                  position: "absolute",
                  borderRadius: "8px",
                }}
              />
            )}
            <ActiveComponent {...props} />
          </div>
        </div>
        <div className="navigation-buttons">
          <button onClick={handleBack}>BACK</button>
          <button onClick={handleNext}>NEXT</button>
        </div>
      </div>
    </div>
  );
};

export default MyStory;
