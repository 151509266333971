import React from "react";

const WellnessCoins = (props) => {
    return(
        <div className="achievementSectionContainer">
            <img src="../images/coin front.png" className="myAchievementsSideIcon"></img>
            <div className="achievementSectionContainerRightSide">
                <h2 className="achievementheaders">WELLNESS COINS</h2>
                <h4 className="achievementheadersubtext">You've earned {props.coins ? props.coins : 0} Wellness {props.coins === 1 ? 'Coin' : 'Coins'}</h4>
                <p className="achievementExplanationP">Each time you practice 1-2-3 Wellness™, you will earn a wellness coin! When you build your number of coins, you are also building your wellness!</p>
            </div>
        </div>
    )
}

export default WellnessCoins