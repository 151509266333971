import { ProcessDataChange } from "./ProcessDataChange";
import { get123WellnessData } from "./get123WellnessData";
import { getSuperadminData } from "./getSuperAdminData";
import logger from "../../utils/logger";

export const fetchData = async (state, realmUser, user, anonamizeData) => {
  try {

    switch (user.user.user) {
      case "123wellness":
        // TODO: WE NEED TO PULL 123Wellness data in the same format as other users
        // For this to be possible we neeed the database to be in a uniform format for 
        // know the data does not match what the schools see. Can use
        // the view district button to view the district
        // Note Non clever 123Wellness data pulling also not working rescue union randomly double
        // counting and adding users for empty named school
        return await get123WellnessData(state, realmUser);
      case "superadmin":
        return await getSuperadminData(state, realmUser, user, anonamizeData);
      case "admin":
        return await getSuperadminData(state, realmUser, user);
      case "teacher":
        return await getSuperadminData(state, realmUser, user);
      default:
        return {
          districts: {},
          allWellnessPoints: [],
          allStudents: [],
          studentData: {},
          currentDictionary: initEmotionDictionary(),
          compareDictionary: initEmotionDictionary(),
          loading: false,
          counter: 0,
        };
    }
  } catch (error) {
    logger.error("Error fetching data:", error);
    throw error;
  }
};

function initEmotionDictionary() {
  return {
    sad: 0,
    tired: 0,
    lonely: 0,
    happy: 0,
    grateful: 0,
    calm: 0,
    stressed: 0,
    scared: 0,
    angry: 0,
  };
}
