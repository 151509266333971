import moment from "moment";
import { createDataPointProcessor } from "../utils/dataPointProcessor";
import { getWellnessDataForDistrict } from "../utils/dataFetchers";
import {
  initializeDictionaries,
  initializeEmotionDict,
} from "../utils/dictionaries";
import { updateEmotions } from "./emotionHandler";
import { transform, transformActions, transformFilteredData } from "../utils/transformers";
import { processSpecificSchool } from "../utils/processSchools";
import { processSpecificClass } from "../utils/processClasses";

export function handleClass(state, className) {
  const { currentDict, compareDict } = initializeDictionaries();
  const emotionDict = initializeEmotionDict();
  let impactfulActions = {action:{}, emotion:{}};
  let newState = {};
  let filterDataPoints = [];  
  let studentEmotionData = {};
  const bigFiveFilteredData = [];
  let tooltipCounts = initializeEmotionDict();
  const currentDate = moment().subtract(state.filterAmountCurrent);
  const compareDate = moment().subtract(
    state.filterAmountCurrent + state.filterAmountCompare
  );
  const processDataPoint = createDataPointProcessor(
    currentDate,
    compareDate,
    state.chosenEmotion,
    currentDict,
    compareDict,
    impactfulActions,
    filterDataPoints,
    studentEmotionData,
    bigFiveFilteredData,
    state.selectedTimes,
    state.selectedSubOptions,
    state.helpfulFilter,
    tooltipCounts,
  );

  if (className === "All Classes") {
    processSpecificSchool(state, state.chosenSchool, processDataPoint);
  } else {
    processSpecificClass(state, className, processDataPoint);
  }

  // logger.log(impactfulActions)

  newState.chosenClass = className;
  newState.chosenStudent = "All Students";
  newState.filteredDataPoints = filterDataPoints;
  newState.bigFiveFilteredData = bigFiveFilteredData.sort((a, b) => new Date(b.date) - new Date(a.date))
  newState.tooltipCounts = tooltipCounts;
  updateEmotions(
    state.feeling,
    state.filterAmountCurrent,
    state.chosenDistrict,
    state,
    emotionDict,
    newState
  );

  transform(currentDict, compareDict, newState);
  transformActions(impactfulActions, newState);
  transformFilteredData(studentEmotionData,state.chosenPercentage,newState)
  return newState;
}
