import { Navigate, useLocation } from "react-router-dom";
import SigninScreen from "../screens/SigninScreen/SigninScreen";

function Redirect({cleverAuthCode}) {
  const location = useLocation();

  if (location.pathname.startsWith("/app.123wellness/")) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return <SigninScreen cleverAuthCode={cleverAuthCode} />;
}

export default Redirect;
