import "./App.css";
import "../src/assets/css/authpages.css"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SigninScreen from "./screens/SigninScreen/SigninScreen";
import React, { useContext, useState, useEffect, useRef } from "react";
import { authentication, firestore } from "./firebase";
import AnimatedRoutes from "./components/AnimatedRoutes";
import { doc, getDoc, onSnapshot, query, setDoc } from "firebase/firestore";
import DisclaimerScreen from "./screens/DisclaimerScreen/DisclaimerScreen";
import ReactPlayer from "react-player";
import { Context } from "./components/Store";
import NoAccountScreen from "./screens/NoAccountScreen/NoAccountScreen";
import AccountProvisioningError from "./screens/AccountProvisioningError/AccountProvisioningError";
import { Signout } from "./firebase";
import { UserContext } from "./realm/user.context";
import { loadProfile } from "./realm/graphqlQueries";
import Redirect from "./components/Redirect";
import { StudentDataProvider } from "./context/studentData.context";
import { MyStoryProvider } from "./context/myStory.context";
import logger from "./utils/logger";

// import 'firebaseui/dist/firebaseui.css'

/* 
  This function handles authentication during the oauth 
  process to authenticate with clever/mongoDB. It does this
  by taking a 'code' value from the query string in the URL.
  If anything besides 'code' is included in the query, this 
  function will do nothing.
*/
const handleCleverCode = async (
  realmUser,
  loginToRealmWithClever,
  logOutRealmUser,
  setUserType,
  setUserObject
) => {
  // refresh access token in case user is already signed in
  let realmResp;
  let cleverCode = "";
  const urlQuery = window.location.href.split("?");
  if (urlQuery.length === 2) {
    const splitCode = urlQuery[1].split("code=");
    if (splitCode.length === 2) {
      cleverCode = splitCode[1];
    }
  }

  // this is confusing, but we do this because we want to allow the
  // user to log in if there is nobody signed in yet OR they have
  // a fresh clever code in the URI (i.e logged in from portal)
  // since they can log in from the portal while authed to a different
  // account, we must suppor this functionality
  if (cleverCode !== "" || realmUser === null || realmUser === undefined) {
    // if there is no clever code, return and log out the user
    if (cleverCode === "") {
      return;
    }

    // Try to get code for query string
    const user = await loginToRealmWithClever(cleverCode);
    if (user) {
      realmResp = await loadProfile(user.profile.name, user);
    } else {
      realmResp = { user: null };
    }
  } else {
    // If a realm user already exists, load their information
    realmResp = await loadProfile(realmUser.profile.name, realmUser);
  }

  // handle realm response
  if (realmResp) {
    if (realmResp === "InvalidSession") {
      Signout();
      logOutRealmUser();
      return "null";
    }
    if (realmResp.user === null) {
      // In this case, the user account is not provisioned. This means that
      // we must begin this step and inform the user.
      logger.log("1");
      return "accountnotprovisioned";
    } else {
      setUserType(realmResp.user.user);
      setUserObject(realmResp);
      if (realmResp.user.agreedToTerms === true) {
        logger.log("2");
        return "loggedin";
      } else {
        logger.log("3");
        return "needstoagreetoterms";
      }
    }
  } else {
    alert(
      "No Corresponding Realm account found, please log out and try logging in again"
    );
  }
};

function App() {
  const [isUserSignedIn, setIsUserSignedIn] = useState("");
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [userType, setUserType] = useState("");
  const [userObject, setUserObject] = useState({});
  const cleverAuthCode = useRef("");
  // We are consuming our user-management context to
  // get & set the user details here
  const {
    realmUser,
    logInRealmUser,
    logOutRealmUser,
    getValidAccessToken,
    loginToRealmWithClever,
  } = useContext(UserContext);
  // const location = useLocation();
  // three states loggedin, loggedout, noaccount
  useEffect(() => {
    const unsubscribe = authentication.onAuthStateChanged((user) => {
      // logger.log("!! auth state change !!");
      if (user) {
        createUserData(user).then((signedin) => {
          //logger.log("this is signed in: ", signedin)
          if (signedin === "loggedin") {
            setAgreedToTerms(true);
          } else {
            setAgreedToTerms(false);
          }
          setIsUserSignedIn(signedin);
        });
      } else {
        getValidAccessToken().then((rU) => {
          handleCleverCode(
            rU,
            loginToRealmWithClever,
            logOutRealmUser,
            setUserType,
            setUserObject
          ).then((signedIn) => {
            logger.log(signedIn);
            if (signedIn) {
              setIsUserSignedIn(signedIn);
            } else {
              setIsUserSignedIn("loggedout");
            }
            if (signedIn === "loggedin") {
              setAgreedToTerms(true);
            }
          });
        });
      }
    });
    return unsubscribe;
  }, []);

  useEffect(() => {
    // this sets the isUserSignedIn variable to "loggedout" if a clever-authed
    // user signs out.
    if (realmUser === null && isUserSignedIn === "loggedin") {
      setIsUserSignedIn("loggedout");
    }
  }, [realmUser, isUserSignedIn, setIsUserSignedIn]);

  //This seems to duplicate the above code lines 33-35
  /*
  if (authentication.currentUser) {
    const fbrequest = "students/" + authentication.currentUser.email;
    const terms = onSnapshot(doc(firestore, fbrequest), (doc) => {
      setAgreedToTerms(doc.data().agreedToTerms);
    });
  }*/
  // returns terms and logged in state
  async function createUserData(user) {
    //TODO why is this being called so often?
    const idToken = await user.getIdToken(/* forceRefresh */ true);
    const authToken = await getValidAccessToken();
    let realmResp = null;

    if (realmUser === null) {
      const authedUser = await logInRealmUser(idToken);
      realmResp = await loadProfile(
        authentication.currentUser.email,
        authedUser
      );
    } else {
      realmResp = await loadProfile(
        authentication.currentUser.email,
        realmUser
      );
    }
    if (realmResp != null) {
      //logger.log(realmResp)
      if (realmResp === "InvalidSession") {
        logger.log("Refresh Required, logging out user");
        Signout();
        logOutRealmUser();
        return "null";
      }
      if (realmResp.user === null) {
        // logger.log("1");
        return "noaccount";
      } else {
        setUserType(realmResp.user.user);
        setUserObject(realmResp);
        if (realmResp.user.agreedToTerms === true) {
          // logger.log("2");
          return "loggedin";
        } else {
          // logger.log("3");
          return "needstoagreetoterms";
        }
      }
    } else {
      alert(
        "No Corresponding Realm account found, please log out and try logging in again"
      );
    }
    /*
    const fbrequest = "students/" + authentication.currentUser.email;
    const ref = doc(firestore, fbrequest);
    const q = await getDoc(ref);
    if (!q.exists()) {
      logger.log("1")
    } else if (q.data().agreedToTerms === true) {
      logger.log("2")
      setUserType(q.data().user)
      return "loggedin";
      // true regular flow
    } else {
      logger.log("3")
      return "needstoagreetoterms";
      // false agreetoterms
    }
    */
  }

  if (isUserSignedIn === "accountnotprovisioned") {
    return (
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={<AccountProvisioningError />} />
          </Routes>
        </div>
      </Router>
    );
  } else if (isUserSignedIn === "loggedin") {
    return (
      <Router>
        <StudentDataProvider user={userObject}>
          <MyStoryProvider user={userObject}>
            <Routes>
              <Route path="/*" element={<AnimatedRoutes user={userObject} setUser={setUserObject} />} />
            </Routes>
          </MyStoryProvider>
        </StudentDataProvider>
      </Router>
    );
  } else if (isUserSignedIn === "loggedout") {
    return (
      <Router>
        <div className="App">
          <Routes>
            <Route
              path="*"
              element={<Redirect cleverAuthCode={cleverAuthCode.current}/>}
            />
          </Routes>
        </div>
      </Router>
    );
  } else if (isUserSignedIn === "noaccount") {
    return (
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={<NoAccountScreen />} />
          </Routes>
        </div>
      </Router>
    );
  } else if (isUserSignedIn === "needstoagreetoterms") {
    return (
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={<DisclaimerScreen user={userObject} />} />
          </Routes>
        </div>
      </Router>
    );
  }
}

export default App;
