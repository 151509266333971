import { useState, useEffect } from "react";
import moment from "moment";
import { fetchData } from "../FetchData";
import logger from "../../../utils/logger";
const initialState = {
  chosenSchool: "Choose School",
  chosenDistrict: "Choose District",
  chosenClass: "Choose Class",
  chosenStudent: "Choose Student",
  searchData: "",
  districts: {},
  chosenTime: "month",
  chosenTimeCompare: "",
  allWellnessPoints: [],
  counter: 0,
  studentData: {},
  threeImpactfulActions: {},
  filterAmountCurrent: moment.duration(30, "days"),
  filterAmountCompare: moment.duration(),
  feeling: "select",
  wellnessList: [],
  loading: true,
  counts: [
    { name: "sad", current: 0, compare: 0 },
    { name: "tired", current: 0, compare: 0 },
    { name: "lonely", current: 0, compare: 0 },
    { name: "happy", current: 0, compare: 0 },
    { name: "grateful", current: 0, compare: 0 },
    { name: "calm", current: 0, compare: 0 },
    { name: "stressed", current: 0, compare: 0 },
    { name: "scared", current: 0, compare: 0 },
    { name: "angry", current: 0, compare: 0 },
  ],
  allStudents: [],
  activeComponent: 0,
  studentEmailToNameMap: {},
  filteredDataPoints: [],
  chosenEmotion: "all emotions",
  chosenColor: "#C8FADD",
  studentEmotionData: {},
  chosenPercentage: "",
  bigFiveFilteredData: [],
  selectedTimes: {
    "All Day": true,
    "Early Morning": false,
    Morning: false,
    Afternoon: false,
    Evening: false,
  },
  selectedSubOptions: {},
  helpfulFilter: "All",
  wellnessPopupOpen: false,
};

export const useAnonymousData = (realmUser, user, isAnonymous) => {
  const [localState, setLocalState] = useState(initialState);
  const [isLocalLoading, setIsLocalLoading] = useState(false);

  useEffect(() => {
    if (isAnonymous) {
      const loadAnonymousData = async () => {
        setIsLocalLoading(true);
        try {
          const data = await fetchData(localState, realmUser, user, isAnonymous);
          setLocalState(prev => ({ ...prev, ...data }));
        } catch (error) {
          logger.error("Error fetching anonymous data:", error);
        }
        setIsLocalLoading(false);
      };
      loadAnonymousData();
    }
  }, [isAnonymous, user, realmUser]);

  const updateState = (updates) => setLocalState(prev => ({ ...prev, ...updates }));

  return {
    state: localState,
    updateState,
    isUpdating: isLocalLoading
  };
}; 