import "./Actions.css";
import BlueSidebar from "../../components/BlueSidebar";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import Store, { Context } from "../../components/Store";
import { useContext, useState } from "react";
import { Context2 } from "../../components/Audio";
import ReactPlayer from "react-player/lazy";
import "../../components/PracticeCircle.css";


import { UserContext } from "../../realm/user.context";
import { updateProfile, insertWellness, updateEmotionCount } from "../../realm/graphqlQueries";

const initialState = {
  action: "",
  emotion: "",
  date: "",
  helpful: false,
};

const Actions = (props) => {
  const { realmUser } = useContext(UserContext);
  const navigate = useNavigate();
  const [playing, setPlaying]= useContext(Context2);
  const [wellnessDataPoint, setWellnessDataPoint] = useContext(Context);
  const audio_url_prefix = props.user.user.voice?props.user.user.voice.replace(" ","_"):"english_female"
  async function createDoc(realmUser, wellnessDataPoint) {
    // Adding the wellness document
    wellnessDataPoint.date = new Date(wellnessDataPoint.date)
    const response = await insertWellness(wellnessDataPoint, props.user.user, realmUser);
    props.lSetWellnessId(response.insertOneWellness._id)
  }

  async function saveToDatabaseAndNav(action) {
    const temp = wellnessDataPoint;
    temp.action = action;
    temp.date = Date.now();
    setWellnessDataPoint(temp);
    createDoc(realmUser, wellnessDataPoint);
    navigate('/performaction')
  }

  var imageSettingFilenameModifier = null
  if (props.user.user.adultActionImages){
      imageSettingFilenameModifier = 'AdultCircle_BIG_FONT'
  } else {
      imageSettingFilenameModifier = 'TeenCircle_BIG_FONT'
  }
  const isMobile = window.innerWidth <= 600;

  const ActionCircle = ({ practice, color, saveToDatabaseAndNav }) => {
    const [hovered, setHovered] = useState(false);
    const enlargedPractices = {
      "Help Others": "63%",
      "Imagine a peaceful place": "63%",
      "Imagine positive things in the future" : "54%",
      "Squeeze my fist" : "63%",
      "Give someone a high five": "63%",
      "Write in my journal": "63%",
      "Move my body": "60%",
      "Smile big": "60%",
      "Use my growth mindset": "65%",
      "Give someone a compliment": "63%",
      "Stretch": "60%",
      "Create art": "63%",
      "Be mindful. Be where my feet are": "59%",
      "Count to ten taking a deep breath each time": "52%",

    };
        
    const imageSize = Object.keys(enlargedPractices).includes(practice) ? enlargedPractices[practice] : '55%';
      
    return (
      <div
        className={`${color}-circle-container`}
        onClick={() => saveToDatabaseAndNav(practice)}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <img
          className="actions"
          src={`../images/actions/${practice} ${imageSettingFilenameModifier}.png`}
          alt={practice}
          style={{ width: "auto", height: imageSize }}
        />
        {isMobile && hovered && (
          <div className="tooltip">{practice}</div>
        )}
        <div className="keep-building-text">{practice}</div>
      </div>
    );
  };  
  
  const GreenActions = () => {
    const [otherText, setOtherText] = useState('');
    const [visible, setVisible] = useState(false);
    const [hovered, setHovered] = useState(false);
  
    const handleChange = (event) => setOtherText(event.target.value);
  
    const greenPractices = [
      'Move my body',
      'Smile big',
      'Stretch',
      'Be mindful. Be where my feet are',
      'Help Others',
      'Think of 3 things that I am grateful for',
      'Give someone a compliment',
      'Give someone a high five',
      'Write in my journal',
      'Create art',
    ];
  
    return (
      <div className="greenrow">
        {greenPractices.map((practice) => (
          <ActionCircle
            key={practice}
            practice={practice}
            color="green"
            saveToDatabaseAndNav={saveToDatabaseAndNav}
          />
        ))}
        {visible ? (
          <div className="imgTextBoxRow">
            <div className="green-circle-container"
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
            >
              <img
                onClick={() => setVisible(false)}
                className="actions"
                style={{ width: "auto", height: "60%" }}
                src="../images/actions/Other_BIG_FONT.png"
                alt="Other"
                loading="lazy"
              />
              {isMobile && hovered && (
              <div className="tooltip">Other</div>
              )}
              <div className="keep-building-text">Other</div>
            </div>
            <input
              type="text"
              placeholder="Type your wellness practice"
              value={otherText}
              onChange={handleChange}
              className="othertextbox"
            />
            <button
              onClick={() => saveToDatabaseAndNav(`Other: ${otherText}`)}
              className="submitaction"
              style={{ marginBottom: '18vh' }}
            >
              Submit
            </button>
          </div>
        ) : (
            <div className="green-circle-container"
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
            >
            <img
              onClick={() => setVisible(true)}
              className="actions"
              src="../images/actions/Other_BIG_FONT.png"
              style={{ width: "auto", height: "60%" }}
              alt="Other"
              loading="lazy"
            />
            {isMobile && hovered && (
            <div className="tooltip">Other</div>
            )}
            <div className="keep-building-text">Other</div>
          </div>
        )}
      </div>
    );
  };
  
  const BlueActions = () => {
    const bluePractices = [
      'Imagine positive things in the future',
      'Laugh at something funny',
      'Remember that difficult times pass',
      'Remind myself of two good things in my life',
      'Tell myself something nice',
      'Get some sun in my eyes',
    ];
  
    return (
      <div className="bluerow">
        {bluePractices.map((practice) => (
          <ActionCircle
            key={practice}
            practice={practice}
            color="blue"
            saveToDatabaseAndNav={saveToDatabaseAndNav}
          />
        ))}
      </div>
    );
  };
  
  const PurpleActions = () => {
    const purplePractices = [
      'Count to ten taking a deep breath each time',
      'Imagine a peaceful place',
      'Listen to music or read',
      'Politely move away from a distraction',
      'Squeeze my fist',
      'Use my growth mindset',
    ];
  
    return (
      <div className="purplerow">
        {purplePractices.map((practice) => (
          <ActionCircle
            key={practice}
            practice={practice}
            color="purple"
            saveToDatabaseAndNav={saveToDatabaseAndNav}
          />
        ))}
      </div>
    );
  };
  
  function getColor() {
    const temp = wellnessDataPoint;
    const emotion = temp.emotion;
    if (emotion === "happy" || emotion === "grateful" || emotion === "calm")
      // return "#A5E2C6";
      return "green";
    else if (
      emotion === "stressed" ||
      emotion === "scared" ||
      emotion === "angry"
    )
      return "purple";
    // return "#DEC8F1";
    else if (emotion === "sad" || emotion === "tired" || emotion === "lonely")
      // return "#ADCDEA";
      return "blue";
  }

  return (
    <motion.div
      className="outeractions"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {/* <BlueSidebar></BlueSidebar> */}
      <div className='reactplayer'>
        <ReactPlayer playing={playing} url={"audio/"+audio_url_prefix+"/actions.wav"} />
      </div>
      <div className="classandactions">
      <div className="seeandheartextrow">
          <img
            className="greennumber"
            src="../images/green3.png"
            alt="loading..."
            loading="lazy"
          />
          <h1 className="howdoifeel">&nbsp;Is there anything I NEED?</h1>
        </div>
        {getColor() == "green" && <GreenActions></GreenActions>}
        {getColor() == "purple" && (
          <div className="emotionscolumn">
            <PurpleActions />
            <GreenActions />
          </div>
        )}
        {getColor() == "blue" && (
          <div className="emotionscolumn">
            <BlueActions />
            <GreenActions />
          </div>
        )}
      </div>
    </motion.div>
  );
};

export default Actions;
