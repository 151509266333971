import moment from "moment";
import { createDataPointProcessor } from "../utils/dataPointProcessor";
import {
  initializeDictionaries,
  initializeEmotionDict,
} from "../utils/dictionaries";
import { updateEmotions } from "./emotionHandler";
import { transform, transformActions, transformFilteredData } from "../utils/transformers";
import {
  processAllDistricts,
  processAllSchoolsInDistrict,
  processSpecificSchool,
} from "../utils/processSchools";
import { timeHelper } from "../utils/timeHelper";
import {
  processSpecificClass,
  processSpecificStudent,
} from "../utils/processClasses";

export function handleTime(state, time, isCompare) {
  const { currentDict, compareDict } = initializeDictionaries();
  const emotionDict = initializeEmotionDict();
  let impactfulActions = {action:{}, emotion:{}};
  let newState = {};
  let filterDataPoints = [];
  let studentEmotionData = {};
  const bigFiveFilteredData = [];
  let tooltipCounts = initializeEmotionDict();

  const filtertime = timeHelper(time);
  let currentDate = moment().subtract(
    isCompare ? state.filterAmountCurrent : filtertime
  );
  let compareDate = moment().subtract(
    isCompare
      ? state.filterAmountCurrent + filtertime
      : filtertime + state.filterAmountCompare
  );

  const processDataPoint = createDataPointProcessor(
    currentDate,
    compareDate,
    state.chosenEmotion,
    currentDict,
    compareDict,
    impactfulActions,
    filterDataPoints,
    studentEmotionData,
    bigFiveFilteredData,
    state.selectedTimes,
    state.selectedSubOptions,
    state.helpfulFilter,
    tooltipCounts
  );
  // logger.log(tooltipCounts)

  if (state.chosenDistrict === "All Districts") {
    processAllDistricts(state,processDataPoint)
  } else if (state.chosenSchool === "All Schools") {
    processAllSchoolsInDistrict(state, processDataPoint);
  } else if (state.chosenClass === "All Classes") {
    processSpecificSchool(state, state.chosenSchool, processDataPoint);
  } else if (state.chosenStudent === "All Students") {
    processSpecificClass(state, state.chosenClass, processDataPoint);
  } else {
    processSpecificStudent(state, state.chosenStudent, processDataPoint);
  }

  newState.filteredDataPoints = filterDataPoints;
  newState.bigFiveFilteredData = bigFiveFilteredData.sort((a, b) => new Date(b.date) - new Date(a.date))
  newState.tooltipCounts = tooltipCounts;
  updateTimeState(newState, time, filtertime, isCompare);

  updateEmotions(
    state.feeling,
    state.filterAmountCurrent,
    state.district,
    state,
    emotionDict,
    newState
  );

  transform(currentDict, compareDict, newState);
  transformActions(impactfulActions, newState);
  transformFilteredData(studentEmotionData,state.chosenPercentage,newState)
  return newState;
}

function updateTimeState(newState, time, filtertime, isCompare) {
  if (isCompare) {
    newState.filterAmountCompare = filtertime;
    newState.chosenTimeCompare = time;
  } else {
    newState.filterAmountCurrent = filtertime;
    newState.chosenTime = time;
  }
}
