import _ from "lodash";
import "./StudentsScreen.css";
import { UserContext } from "../../../realm/user.context";
import PersonIcon from "@mui/icons-material/Person";
import ReactDataSheet from "react-datasheet";
import React, { useState, useEffect, useContext, useRef } from "react";
import "react-toastify/dist/ReactToastify.css";
import "./toast-overwrites.css";
import {
  fetchAllSchoolsInDistrict,
  fetchSchoolsInfoByCleverId,
  fetchSchoolsInfoByName,
  insertProfiles,
  updateProfile,
  loadDistricts,
  loadSchools2,
  updateDistrictV2,
  loadProfile,
} from "../../../realm/graphqlQueries";
import Spacer from "../../../components/Spacer";
import { deleteUser } from "firebase/auth";
import { ToastContainer, toast } from "react-toastify";
import { LoadingOverlay } from "../../../components/LoadingOverlay";
import CustomEditor from "../../../components/CustomEditor";
import StudentData from "../../StudentData/StudentData";
import AddDistrictButton from "./AddDistrictButton";
import DistrictButton from "./DistrictButton";
import SchoolButton from "./SchoolButton";
import ClassesShowcase from "./ClassesShowcase";
import AddSchoolButton from "./AddSchoolButton";
import UserGrid from "./UserGrid";
import { runPaginatedQuery } from "../../../realm/runPaginatedQuery";
import { clearExistingProfiles } from '../utils/utils';

const StudentsScreenV2 = ({ user, goBack, disableMargin }) => {
  const [onMobile, setOnMobile] = useState();
  const [districtData, setDistrictData] = useState();
  const [selectedDistrict, setSelectedDistrict] = useState();
  const [viewedDistrict, setViewedDistrict] = useState();
  const [viewingCleverDistrict, setViewingCleverDistrict] = useState();
  const [schoolData, setSchoolData] = useState();
  const [selectedSchool, setSelectedSchool] = useState();
  const { realmUser } = useContext(UserContext);
  const schoolNames = user.user.cleverId
    ? user.user.schools
    : user.user.schools ?? [user.user.school];

  const [settingAdmins, updateSettingAdmins] = useState(false);
  const [superAdminGrid, setSuperAdminGrid] = useState([]);
  const [originalSuperadmins, setOriginalSuperadmins] = useState([]);
  const [superAdminInfo, setSuperAdminInfo] = useState([]);

  useEffect(() => {
    if (!selectedDistrict) {
      setSuperAdminGrid([]);
      setOriginalSuperadmins([]);
      if (user.user.user === "superadmin") {
        const fetchDistrict = async () => {
          let allUsers;
          allUsers = await loadDistricts(realmUser, user.user.district);
          const currentDistrict = allUsers.districts.find(
            district => district.name === user.user.district
          );
          if (currentDistrict) {
            setSelectedDistrict(currentDistrict);
            const superadminData = currentDistrict.superadmins.map((email) => ({
              email,
            }));
            const finalSuperAdminGrid = superadminData.length === 0
              ? Array(6).fill({ email: "" })
              : [...superadminData];
            for (let i = 0; i < 10; i++) {
              finalSuperAdminGrid.push({ email: "" });
            }
            setSuperAdminInfo(superadminData)
            setSuperAdminGrid(finalSuperAdminGrid);
            setOriginalSuperadmins([...currentDistrict.superadmins]);
          }
        };
    
        fetchDistrict();
      }
    } else {
      const superadminData = selectedDistrict.superadmins.map((email) => ({
        email,
      }));
  
      const finalSuperAdminGrid = superadminData.length === 0
        ? Array(6).fill({ email: "" })
        : [...superadminData];
      for (let i = 0; i < 10; i++) {
        finalSuperAdminGrid.push({ email: "" });
      }
      setSuperAdminInfo(superadminData)
      setSuperAdminGrid(finalSuperAdminGrid);
      setOriginalSuperadmins([...selectedDistrict.superadmins]);
    }
  }, [selectedDistrict]);

  async function addUser(attributes) {
    let nAttributes = attributes;
    const profile = await loadProfile(attributes.email, realmUser);
    if (profile.user === null) {
      insertProfiles([attributes], realmUser);
      return;
    } else if (
      attributes.classes !== undefined &&
      attributes.classes !== null
    ) {
      //Classes are added to a list, other attributes simply overwrite
      let nClasses = [
        ...new Set(profile.user.class.concat(attributes.classes)),
      ];
      nAttributes.classes = nClasses;
    }
    updateProfile(attributes.email, realmUser, attributes);
  }

  async function gridToPeople(updatedEmails) {
    const oldSuperadmins = originalSuperadmins;
    const newSuperadmins = updatedEmails.filter(
      (email) => !oldSuperadmins.includes(email)
    );
    const superadminsToRemove = oldSuperadmins.filter(
      (email) => !updatedEmails.includes(email)
    );

    // Add new superadmins
    for (const email of newSuperadmins) {
      let userDoc = {
        email: email,
        user: "superadmin",
        district: selectedDistrict.name,
        count: 0,
        dataUse: false,
        class: [],
        school: "",
        wellnesstimer: "30 seconds",
      };
      await addUser(userDoc);
    }

    // Change removed superadmins to students
    for (const email of superadminsToRemove) {
      await updateProfile(email, realmUser, {
        district: "",
        school: "",
        user: "student",
      });
    }

    return updatedEmails;
  }

  const refresh = async () => {
    let result;
    if (user.user.user === "superadmin") {
      result = await fetchAllSchoolsInDistrict(user.user.district, realmUser);
    } else if (user.user.user === "123wellness") {
      setDistrictData((await loadDistricts(realmUser)).districts);
      // const schoolData = await loadSchools2({}, realmUser);
      const schoolData = await runPaginatedQuery(realmUser, "schools");
      result = schoolData;
    } else if (user.user.cleverId) {
      result = await fetchSchoolsInfoByCleverId(schoolNames, realmUser);
    } else if (user.user.user === "teacher") { 
      result = await fetchSchoolsInfoByName(
        user.user.district,
        [user.user.school],
        realmUser
      );
    } else {
      result = await fetchSchoolsInfoByName(
        user.user.district,
        schoolNames,
        realmUser
      );
    }

    setSchoolData(result);
    if (
      ((user.user.user !== "123wellness" && !selectedSchool) ||
        (user.user.user === "123wellness" && selectedDistrict && !selectedSchool)) &&
      !localStorage.getItem("visitedSchoolsScreen")
    ) {
      toast.info("Each of the buttons below represents a school.", {
        closeOnClick: true, autoClose: 25000,
      });
  
      const secondMessage =
        user.user.user === "teacher" || user.user.user === "admin"
          ? "Click on one to view class info."
          : "Click on one to view class info, or click the Create School button to make a custom school!";
  
      toast.info(secondMessage, { closeOnClick: true, autoClose: 25000 });
  
      localStorage.setItem("visitedSchoolsScreen", true);
    }
    if (!localStorage.getItem("pasteInfo")) {
      toast.info("Click the paste button to paste emails that you copied from your clipboard!", {
        closeOnClick: true, autoClose: 25000
      });
      localStorage.setItem("pasteInfo", true);
    }
  };

  useEffect(() => {
    if (selectedSchool && selectedDistrict) {
      const keys = [
        selectedSchool._id,
        selectedSchool.name,
        selectedSchool.cleverId,
      ];
      if (!keys.some((k) => selectedDistrict.schools.includes(k))) {
        setSelectedSchool(undefined);
      }
    }
    refresh();
  }, [selectedDistrict]);

  useEffect(() => {
    const resize = () => setOnMobile(window.innerWidth <= 600);
    window.addEventListener("resize", resize);
    resize();
    return () => window.removeEventListener("resize", resize);
  }, []);

  if (viewedDistrict) {
    const modifiedUser = JSON.parse(JSON.stringify(user));
    modifiedUser.user.user = "superadmin";
    modifiedUser.user.district = viewedDistrict;
    if (viewingCleverDistrict)
      modifiedUser.user.cleverId = "Needs Any Non Null Value";
    else modifiedUser.user.cleverId = null;
    return (
      <div>
        <StudentData user={modifiedUser} anonamizeData={true}></StudentData>
        <button
          className="closeButton"
          onClick={() => {
            setViewedDistrict(null);
            setViewingCleverDistrict(null);
          }}
        >
          Exit
        </button>
      </div>
    );
  }

  const handleSaveChanges = async (updatedData) => {
    const newEmails = updatedData
      .map((row) => row.email.trim())
      .filter((email) => email !== "");
  
    const newSuperadmins = newEmails.filter(
      (email) => !originalSuperadmins.includes(email)
    );
  
    const superadminsToRemove = originalSuperadmins.filter(
      (email) => !newEmails.includes(email)
    );
    
    for (const email of newSuperadmins) {
      // 1. Clear Existing Profiles for Email
      await clearExistingProfiles(email, realmUser, "superadmin", null);

      // 2. Update User Profile
      let userDoc = {
        email: email,
        user: "superadmin",
        district: selectedDistrict.name,
        count: 0,
        dataUse: false,
        class: [],
        school: "",
        wellnesstimer: "30 seconds",
        schools: []
      };
      await addUser(userDoc);
    }
  
    for (const email of superadminsToRemove) {
      await updateProfile(email, realmUser, {
        district: "",
        school: "",
        user: "student",
        class: [],
        schools: []
      });
    }
  
    const response = await updateDistrictV2(selectedDistrict._id, realmUser, {
      superadmins: newEmails,
    });
  
    if (response && response.updateOneDistrict) {
      setOriginalSuperadmins(newEmails);
      setSuperAdminGrid(updatedData);
      setSuperAdminInfo(newEmails);
      toast.success("Changes saved successfully!", { closeOnClick: true, autoClose: 25000 });
    }
  };
  
  return (
    <div>
      {(!schoolData || settingAdmins) && <LoadingOverlay></LoadingOverlay>}
      <div
        className="studentscol"
        style={disableMargin ? { marginLeft: 0 } : {}}
      >
        {goBack && (
          <p onClick={goBack} id="GBB" className="go-back-btn">Go Back</p>
        )}

        {user.user.user === "123wellness" && (
          <>
            <p className="SSV2Title">Select a district below</p>
            {!selectedDistrict && (
              <AddDistrictButton
                refresh={refresh}
                onMobile={onMobile}
                realmUser={realmUser}
              />
            )}
            <div className="SSV2ButtonContainer">
              {districtData &&
                (selectedDistrict ? [selectedDistrict] : districtData).map(
                  (district, i) => (
                    <DistrictButton
                      key={i}
                      data={district}
                      onMobile={onMobile}
                      selectedDistrict={selectedDistrict}
                      setSelectedDistrict={setSelectedDistrict}
                      setViewedDistrict={setViewedDistrict}
                      setViewingCleverDistrict={setViewingCleverDistrict}
                      user={user}
                    />
                  )
                )}
            </div>
            </>
        )}
        {selectedDistrict && !selectedDistrict.cleverId && (
          <>
            <div>
              <div className="superadmin-container" >
                <p className="SSV2CPTitle">Superadmins at {selectedDistrict.name} ({superAdminInfo.length})</p>
                <h3 className="admin-subtext">Please add the emails of any user you would like to have access  <br /> to data from the entire school district - superintendant, etc.</h3>
                <div className="SSV2Backdrop">
                  <UserGrid initialData={superAdminGrid}
                    onSaveChanges={(updatedData) => {
                      setSuperAdminGrid(updatedData);
                      handleSaveChanges(updatedData);
                    }}
                    inputType={user.user.user === "123wellness" || user.user.user === "superadmin" ? true : false}
                  />
                </div>
              </div>
            </div>
          </>
        )}

        {((user.user.user !== "123wellness" && !selectedSchool) ||
          (user.user.user === "123wellness" &&
            selectedDistrict &&
            !selectedSchool)) && (
          <p className="SSV2Title">Select a school below to manage classes.</p>
        )}

        <ToastContainer
          toastStyle={{ backgroundColor: "#4cc68d", color: "#FFF" }}
        />

        {/* holds school buttons */}
        <div className="SSV2ButtonContainer">
          {/* {s.district} */}
          {(user.user.user !== "123wellness" ||
            (user.user.user === "123wellness" && selectedDistrict)) &&
            schoolData &&
            (selectedSchool
              ? // if school is selected, always show just that school
                schoolData.filter(
                  (s) =>
                    s.name === selectedSchool && s.district === selectedDistrict
                )
              : user.user.user === "123wellness"
              ? // if not, we need to filter schools in the current district
                // for 123 wellness viewers
                schoolData.filter((s) =>
                  [
                    selectedDistrict._id,
                    selectedDistrict.name,
                    selectedDistrict.cleverId,
                    selectedDistrict.district,
                  ].includes(s.district)
                )
              : schoolData
            ).map((school, i) => (
              <SchoolButton
                key={i}
                onMobile={onMobile}
                schoolData={school}
                selectedSchool={selectedSchool}
                setSelectedSchool={setSelectedSchool}
              />
            ))}
        </div>
        {((user.user.user === "123wellness" && selectedDistrict) ||
          user.user.user !== "123wellness") &&
        selectedSchool ? (
          <ClassesShowcase
            refresh={refresh}
            onMobile={onMobile}
            schoolData={schoolData}
            setSchoolData={setSchoolData}
            district={
              user.user.user === "123wellness"
                ? selectedDistrict.cleverId ?? selectedDistrict.name
                : user.user.district
            }
            cleverUser={
              user.user.user === "123wellness"
                ? !!selectedDistrict.cleverId
                : !!user.user.cleverId
            }
            userClasses={user.user.class}
            selectedSchool={selectedSchool}
            setSelectedSchool={setSelectedSchool}
            isAdmin={
              user.user.user === "admin" ||
              user.user.user === "superadmin" ||
              user.user.user === "123wellness"
            }
            user={user.user.user}
          />
        ) : user.user.user !== "superadmin" &&
          user.user.user !== "123wellness" ? (
          <></>
        ) : user.user.user === "123wellness" && selectedDistrict ? (
          <AddSchoolButton
            refresh={refresh}
            onMobile={onMobile}
            district={selectedDistrict.cleverId ?? selectedDistrict.name}
            user={user.user.user}
            highlightedColor={"#4cc68d"}
          />
        ) : user.user.user === "superadmin" ? (
          <AddSchoolButton
            refresh={refresh}
            onMobile={onMobile}
            district={user.user.district}
            highlightedColor={"#4cc68d"}
          />
        ) : (
          <></>
        )}
        <Spacer height="25vh" />
      </div>
    </div>
  );
};

export default StudentsScreenV2;
